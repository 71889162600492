import React from 'react';

import ApplicationForm from './ApplicationForm';
import { CustomBackgroundColor } from '../../lib/backgroundColor';
import { Colors, Links } from '../../lib/constants';

import fiNanoCarton from '../../assets/images/fi-nano-carton.png';

import styles from './Apply.module.scss';

export default function Apply() {
  return (
    <div className={styles.main}>
      <CustomBackgroundColor color={Colors.lightGrayBackground} />
      <div className={styles.form}>
        <ApplicationForm
          onSuccess={() => {
            window.location.href = Links.shop;
          }}
        />
      </div>
      <div className={styles.image}>
        <img src={fiNanoCarton} alt="A carton of Fi nanos" />
      </div>
    </div>
  );
}
