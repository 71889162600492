import classNames from 'classnames';
import { ErrorMessage, Field, Formik } from 'formik';
import { useCallback, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/Button/Button';
import { login, signup } from '../../../lib/authentication';
import { saneEmail } from '../../../lib/utils';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import styles from './EmailStepV2.module.scss';
import getCustomerIO from '../../../lib/customerIO';

const validationSchema = yup.object({
  email: yup
    .string()
    .email(`Please enter a valid email address`)
    .test('sane_email', `.con is not valid. Please check the spelling of your email address`, saneEmail)
    .required(`Please enter your email address`),
});

interface LoginFormProps {
  hasAccount: boolean;
  onSuccess: (email: string) => Promise<void>;
}

interface FormValues {
  email: string;
  password: string;
}

function LoginForm({ hasAccount, onSuccess }: LoginFormProps) {
  const history = useHistory();
  const { registerState } = useContext(RegisterChipContextV2);
  const initialFormValues: FormValues = {
    email: registerState.primaryEmail || '',
    password: '',
  };

  return (
    <div>
      <Formik<FormValues>
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            if (hasAccount) {
              await login(values.email, values.password);
            } else {
              await signup(values.email, values.password, values.email, null);
            }

            await onSuccess(values.email);
          } catch (err) {
            setStatus({ errorMessage: (err as any).message });
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialFormValues}
      >
        {({ handleSubmit, isSubmitting, status, submitForm }) => (
          <form onSubmit={handleSubmit} className="form">
            <div className="form-section">
              <div className="form-group">
                <div className="form-field">
                  <Field type="text" name="email" placeholder="Email address" />
                  <ErrorMessage name="email" component="div" className="form-field__error" />
                </div>
              </div>
              <div className="form-group">
                <div className="form-field">
                  <Field type="password" name="password" placeholder="Password" />
                  <ErrorMessage name="password" component="div" className="form-field__error" />
                </div>
              </div>
              <div className="form-group form-group--action form-group--action__wide">
                <div className="form-field">
                  <Button
                    primary
                    type="submit"
                    label={hasAccount ? 'Log in' : 'Sign up'}
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                  />
                  <Button
                    primary
                    type="button"
                    label="Go back"
                    onClick={(e) => {
                      e.preventDefault();
                      history.replace('/register/chip');
                    }}
                  />
                </div>
                {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default function EmailStep() {
  const { registerChipAddEmail, registerState, onStepFinished, setPrimaryEmail } = useContext(RegisterChipContextV2);
  const [hasAccount, setHasAccount] = useState(registerState.primaryEmail ? true : false);

  const onEmailSubmit = useCallback(
    async (email: string) => {
      if (!registerState.chipId) {
        throw new Error('Chip ID not found');
      }

      // Register the chip to this email address
      await registerChipAddEmail(registerState.chipId);
      setPrimaryEmail(email);
      getCustomerIO()?.identify(email, { nano: true });
      getCustomerIO()?.track('Nano Registration: Signup/Login Step Completed', { email });
      onStepFinished('email');
    },
    [onStepFinished, registerChipAddEmail, registerState.chipId, setPrimaryEmail],
  );

  let title = 'Set up an account';
  let subtitle =
    'Registering your microchip greatly increases the safety of your dog. Let’s start by creating an account or logging in.';
  if (registerState.chipDetails?.isAssigned) {
    title = 'Request transfer';
    subtitle =
      'Looks like someone already registered this chip. To transfer ownership, start by creating an account or logging in.';
  } else if (hasAccount) {
    title = 'Log in to your account';
  }

  return (
    <div className={classNames('email-step', styles.container)}>
      <div className={styles.mainContent}>
        <div className={styles.progress}>
          <div className={styles.progressText}>Step 2: Account</div>
          <div className={styles.progressFillBoxes}>
            <div className={styles.progressFillBox1} />
            <div className={styles.progressFillBox2} />
            <div className={styles.progressFillBox3} />
            <div className={styles.progressFillBox4} />
            <div className={styles.progressFillBox5} />
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <LoginForm onSuccess={onEmailSubmit} hasAccount={hasAccount} />
        <div className={styles.accountPrompts}>
          {!hasAccount && (
            <>
              <div className={styles.accountPrompt}>
                Already have an account? <button onClick={() => setHasAccount(true)}>Log in</button>
              </div>
            </>
          )}
          {hasAccount && (
            <>
              <Link to="/passwordReset" className={styles.link}>
                Forgot your password?
              </Link>
              <div className={styles.accountPrompt}>
                Don't have an account? <button onClick={() => setHasAccount(false)}>Sign up</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
