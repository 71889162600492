export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Binary: { input: any; output: any };
  DateTime: { input: any; output: any };
  HexColorCode: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AcceptInviteInput = {
  inviteCode: Scalars['String']['input'];
};

export type AcceptUserInviteResult = {
  __typename?: 'AcceptUserInviteResult';
  household?: Maybe<Household>;
  householdRole?: Maybe<UserHouseholdRole>;
  /** The updated current user. */
  user: User;
};

export type ActivitiesForRangeResult = {
  __typename?: 'ActivitiesForRangeResult';
  activities: Array<Activity>;
};

export type Activity = {
  address?: Maybe<Scalars['String']['output']>;
  areaName?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  dbId: Scalars['Int']['output'];
  debugMetadata: Scalars['JSON']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use isLikable */
  isClappable: Scalars['Boolean']['output'];
  isLikable: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type ActivityFeed = {
  __typename?: 'ActivityFeed';
  activities: Array<Activity>;
  pageInfo: PageInfo;
};

export type ActivityGoal = {
  __typename?: 'ActivityGoal';
  targetScore: Scalars['Int']['output'];
};

export type ActivityIntensity = {
  __typename?: 'ActivityIntensity';
  date: Scalars['DateTime']['output'];
  intensity: Scalars['Int']['output'];
  type: ActivityType;
};

/**
 * A single activity report from a device
 * !! IMPORTANT: This should never be exposed to or used by non-admin clients,
 * since it has a monotonically increasing ID (not a UUID).
 */
export type ActivityReport = {
  __typename?: 'ActivityReport';
  attributes?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  duration: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  steps: Scalars['Int']['output'];
};

export type ActivityReportInput = {
  date: Scalars['DateTime']['input'];
  duration: Scalars['Float']['input'];
  params?: InputMaybe<Scalars['JSON']['input']>;
  steps: Scalars['Int']['input'];
};

/** Period of time over which Rest, Activity, and Strain feeds return data. */
export enum ActivityRestStrainPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

/** Indicates if data is available for given period, or reason why not. */
export enum ActivityRestStrainPeriodDataAvailability {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE_DATE_IS_IN_FUTURE = 'UNAVAILABLE_DATE_IS_IN_FUTURE',
  UNAVAILABLE_NO_DATA = 'UNAVAILABLE_NO_DATA',
}

export type ActivitySummary = {
  __typename?: 'ActivitySummary';
  dailySteps: Array<DailyStepSummary>;
  end: Scalars['DateTime']['output'];
  hourlySteps: Array<TimeBoundStepCount>;
  period: ActivitySummaryPeriod;
  start: Scalars['DateTime']['output'];
  stepGoal: Scalars['Int']['output'];
  totalDistance: Scalars['Float']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type ActivitySummaryFeed = {
  __typename?: 'ActivitySummaryFeed';
  activitySummaries: Array<ActivitySummary>;
  hasNext: Scalars['Boolean']['output'];
  nextCursor: Scalars['String']['output'];
};

export enum ActivitySummaryPeriod {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export enum ActivityType {
  MISSING_REPORTS = 'MISSING_REPORTS',
  PLAY = 'PLAY',
  REST = 'REST',
  TRAVEL = 'TRAVEL',
  WALK = 'WALK',
}

export type AddOrderLineItemsInput = {
  cart: AdminCartInput;
  id: Scalars['ID']['input'];
};

export type AddPetDocumentInput = {
  fileName: Scalars['String']['input'];
  fileSizeBytes: Scalars['Int']['input'];
  fileType: Scalars['String']['input'];
  petId: Scalars['ID']['input'];
  recordKey: Scalars['String']['input'];
};

export type AddPetPhotoInput = {
  petId: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
  updates?: InputMaybe<PhotoUpdatesInput>;
};

export type AddPhotoCommentResult = {
  __typename?: 'AddPhotoCommentResult';
  photo: Photo;
};

export type AddResellerModulesInput = {
  moduleIds: Array<Scalars['String']['input']>;
  orderSource: OrderSource;
  shipDate: Scalars['DateTime']['input'];
};

export type AddSupplementSubscriptionInput = {
  skuToAdd: Scalars['String']['input'];
};

export type AddSupplementSubscriptionResult = {
  __typename?: 'AddSupplementSubscriptionResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type AddTextPostCommentResult = {
  __typename?: 'AddTextPostCommentResult';
  post: TextPost;
};

export type AddUserEmailInput = {
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type AddUserToHouseholdInput = {
  householdId: Scalars['ID']['input'];
  role: UserHouseholdRole;
  userId: Scalars['ID']['input'];
};

export type AddUserToHouseholdResult = {
  __typename?: 'AddUserToHouseholdResult';
  household: Household;
};

export type AddVideoPostCommentResult = {
  __typename?: 'AddVideoPostCommentResult';
  post: VideoPost;
};

export type AddWifiCredentialInput = {
  credentials: WifiCredentialInput;
  householdId: Scalars['ID']['input'];
  position: PositionInput;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type Admin = {
  __typename?: 'Admin';
  adminList: AdminList;
  /**
   * This is the same as the normal packMemberFeed but it doesn't filter pets
   * socially or require an acting pet.
   */
  adminPackMemberFeed: PackMemberFeed;
  allChargingBases: Array<ChargingBase>;
  /** Get all devices */
  allDevices: Array<Device>;
  allFeatureFlags: Array<FeatureFlag>;
  allPets: Array<Pet>;
  allPromotions: Array<Promotion>;
  allUserGroups: Array<UserGroup>;
  /** Get all users */
  allUsers: Array<User>;
  baseEventHistory: Array<AuditLogEvent>;
  batteryHistory: Array<BatteryHistoryAggregationBucket>;
  batteryHistoryConnectionStateChanges: ConnectionStateChangesResult;
  batteryHistoryDeviceActivity: Array<DeviceActivity>;
  batteryHistoryDeviceErrors: Array<DeviceError>;
  batteryHistoryOverviewDeviceEvents: Array<BatteryHistoryOverviewDeviceEvent>;
  batteryHistoryStatDeltaEvents: Array<StatDeltaDeviceEvent>;
  chargingBaseBatches: Array<DeviceBatchInfo>;
  chipEventHistory: Array<AuditLogEvent>;
  chipResellerEventHistory: Array<AuditLogEvent>;
  commentEventHistory: Array<AuditLogEvent>;
  couponRedirect: Scalars['String']['output'];
  deviceStats: Array<DeviceStat>;
  /** Get multiple devices by module IDs */
  devices: Array<Device>;
  /** List of all ecommerce experiments that are configured */
  ecommerceExperiments: Array<EcommerceExperiment>;
  esIndices?: Maybe<Scalars['JSON']['output']>;
  firmwareStatistics: FirmwareStatistics;
  firmwareVersions: Array<FirmwareVersion>;
  /** Returns a signed URL that can be used to force a user into a GrowthBook experiment variant */
  growthBookExperimentForcedVariantUrl: Scalars['String']['output'];
  hardwareRevisionDetails?: Maybe<Scalars['JSON']['output']>;
  householdEventHistory: Array<AuditLogEvent>;
  inPersonEvent: InPersonEvent;
  inPersonEvents: Array<InPersonEvent>;
  moduleBatches: Array<DeviceBatchInfo>;
  moduleEventHistory: Array<AuditLogEvent>;
  /** Get all AuditLogEvent by object type */
  petEventHistory: Array<AuditLogEvent>;
  placeEventHistory: Array<AuditLogEvent>;
  platforms: Array<Platform>;
  productInventoryLevels: ProductInventoryLevels;
  promotion: Promotion;
  recentlyPurchasedItems: RecentlyPurchasedItemsResponse;
  referralRewardHistory: Array<AuditLogEvent>;
  searchChipResellers: Array<ChipReseller>;
  searchChips: Array<ResellerChip>;
  searchDevices: Array<Device>;
  searchPets: Array<Pet>;
  searchUsers: Array<AdminBaseUserProfile>;
  signUrl: Scalars['String']['output'];
  statistics: Statistics;
  subscriptionEventHistory: Array<AuditLogEvent>;
  /** This endpoint is a noop. It is used to test GraphQL DateTime validation. */
  testDateTimeParsing?: Maybe<Scalars['DateTime']['output']>;
  totalFirmwareVersions?: Maybe<Scalars['Int']['output']>;
  userEventHistory: Array<AuditLogEvent>;
};

export type AdminadminListArgs = {
  name: Scalars['String']['input'];
};

export type AdminadminPackMemberFeedArgs = {
  adjacentPetId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  packAgeFilter?: InputMaybe<PackAgeFilter>;
  packId: Scalars['ID']['input'];
  pagingInstruction?: InputMaybe<PagingInstruction>;
  validityToken?: InputMaybe<Scalars['String']['input']>;
};

export type AdminallChargingBasesArgs = {
  assignedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<AdminAllChargingBasesInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  updateChannel?: InputMaybe<Scalars['String']['input']>;
};

export type AdminallDevicesArgs = {
  assignedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminallPetsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminallUserGroupsArgs = {
  excludePseudo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminallUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminbaseEventHistoryArgs = {
  baseId: Scalars['ID']['input'];
};

export type AdminbatteryHistoryArgs = {
  dateRange?: InputMaybe<DateRangeInput>;
  interval: Scalars['String']['input'];
  moduleId: Scalars['String']['input'];
};

export type AdminbatteryHistoryConnectionStateChangesArgs = {
  dateRange: DateRangeInput;
  moduleId: Scalars['String']['input'];
};

export type AdminbatteryHistoryDeviceActivityArgs = {
  dateRange: DateRangeInput;
  moduleId: Scalars['String']['input'];
};

export type AdminbatteryHistoryDeviceErrorsArgs = {
  dateRange?: InputMaybe<DateRangeInput>;
  moduleId: Scalars['String']['input'];
};

export type AdminbatteryHistoryOverviewDeviceEventsArgs = {
  moduleId: Scalars['String']['input'];
};

export type AdminbatteryHistoryStatDeltaEventsArgs = {
  dateRange: DateRangeInput;
  moduleId: Scalars['String']['input'];
  statNames: Array<Scalars['String']['input']>;
};

export type AdminchipEventHistoryArgs = {
  chipId: Scalars['ID']['input'];
};

export type AdminchipResellerEventHistoryArgs = {
  chipResellerId: Scalars['ID']['input'];
};

export type AdmincommentEventHistoryArgs = {
  commentId: Scalars['ID']['input'];
};

export type AdmincouponRedirectArgs = {
  input: CouponRedirectInput;
};

export type AdmindevicesArgs = {
  moduleIds: Array<Scalars['String']['input']>;
};

export type AdminfirmwareStatisticsArgs = {
  platformId: Scalars['String']['input'];
};

export type AdminfirmwareVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AdmingrowthBookExperimentForcedVariantUrlArgs = {
  experimentId: Scalars['String']['input'];
  variantId: Scalars['String']['input'];
};

export type AdminhouseholdEventHistoryArgs = {
  householdId: Scalars['ID']['input'];
};

export type AdmininPersonEventArgs = {
  id: Scalars['ID']['input'];
};

export type AdmininPersonEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<InPersonEventQuery>;
};

export type AdminmoduleEventHistoryArgs = {
  moduleId: Scalars['String']['input'];
};

export type AdminpetEventHistoryArgs = {
  petId: Scalars['ID']['input'];
};

export type AdminplaceEventHistoryArgs = {
  userId: Scalars['ID']['input'];
};

export type AdminpromotionArgs = {
  id: Scalars['Int']['input'];
};

export type AdminrecentlyPurchasedItemsArgs = {
  householdId: Scalars['ID']['input'];
};

export type AdminreferralRewardHistoryArgs = {
  userId: Scalars['ID']['input'];
};

export type AdminsearchChipResellersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ChipResellerQueryInput>;
};

export type AdminsearchChipsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ChipQueryInput>;
};

export type AdminsearchDevicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<DeviceQueryInput>;
};

export type AdminsearchPetsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminsearchUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminsignUrlArgs = {
  url: Scalars['String']['input'];
};

export type AdminsubscriptionEventHistoryArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type AdmintestDateTimeParsingArgs = {
  dt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AdminuserEventHistoryArgs = {
  userId: Scalars['ID']['input'];
};

export type AdminAddItemToListResult = {
  __typename?: 'AdminAddItemToListResult';
  /** The newly-created item that was added to the AdminList. */
  item: AdminListItem;
  list: AdminList;
};

export type AdminAllChargingBasesInput = {
  assignedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  modulusRange?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlineSince?: InputMaybe<Scalars['DateTime']['input']>;
  updateChannel?: InputMaybe<Scalars['String']['input']>;
};

export type AdminBaseUserProfile = {
  billingAccount?: Maybe<BillingAccount>;
  chipReseller?: Maybe<ChipReseller>;
  dbId: Scalars['Int']['output'];
  deactivated: Scalars['Boolean']['output'];
  ecommerceExperimentEnrollments: Array<EcommerceExperimentEnrollment>;
  ecommerceOrders: Array<Order>;
  email: Scalars['String']['output'];
  emails: Array<UserEmail>;
  firstName: Scalars['String']['output'];
  groups: Array<UserGroup>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  referrals: Referrals;
  timezone: Scalars['String']['output'];
  userHouseholds: Array<UserHousehold>;
};

export type AdminBaseUserProfilegroupsArgs = {
  excludePseudo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminBulkAssignUsersToGroupInput = {
  groupName: Scalars['String']['input'];
  uploadedFileKey: Scalars['String']['input'];
};

export type AdminCartInput = {
  cartItems: Array<AdminCartItemInput>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCartItemInput = {
  cartItemId: Scalars['String']['input'];
  lineItem: CartLineItemInput;
  quantity: Scalars['Int']['input'];
  subscriptionLineItem?: InputMaybe<CartLineItemInput>;
};

export type AdminClaimNanoWebIdsInput = {
  number: Scalars['Int']['input'];
};

export type AdminCreateBreedInput = {
  /** Weight in kilograms */
  averageWeightMax?: InputMaybe<Scalars['Float']['input']>;
  /** Weight in kilograms */
  averageWeightMin?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  exerciseRating: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  originIds: Array<Scalars['ID']['input']>;
  temperamentIds: Array<Scalars['ID']['input']>;
};

export type AdminCreateOrderInput = {
  billingAddress?: InputMaybe<AddressInput>;
  billingFirstName: Scalars['String']['input'];
  billingLastName: Scalars['String']['input'];
  billingToken: Scalars['String']['input'];
  cart: AdminCartInput;
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  shippingAddress: AddressInput;
  shippingOption?: InputMaybe<ShippingOption>;
};

export type AdminCreatePurchaseInput = {
  lineItems: Array<AdminLineItemInput>;
  userId: Scalars['ID']['input'];
};

export type AdminGPSTestConclusiveResult = {
  __typename?: 'AdminGPSTestConclusiveResult';
  result: GPSTestResult;
};

/** Indicates there was an internal error determining the result of the test */
export type AdminGPSTestErrorResult = {
  __typename?: 'AdminGPSTestErrorResult';
  errorDescription: Scalars['String']['output'];
};

export type AdminGPSTestResult = AdminGPSTestConclusiveResult | AdminGPSTestErrorResult | AdminGPSTestTimeoutResult;

export type AdminGPSTestTimeoutResult = {
  __typename?: 'AdminGPSTestTimeoutResult';
  timedOut?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminGetZoomVideoSdkTokenInput = {
  userId: Scalars['ID']['input'];
};

export enum AdminLevel {
  CITY = 'CITY',
  STATE = 'STATE',
}

export type AdminLineItemInput = {
  id: Scalars['ID']['input'];
  priceOverrideInCents?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export type AdminList = {
  __typename?: 'AdminList';
  id: Scalars['ID']['output'];
  /** The items in descending order of creation date. */
  items: Array<AdminListItem>;
  name: Scalars['String']['output'];
};

export type AdminListItem = {
  __typename?: 'AdminListItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['JSON']['output'];
};

export type AdminMobileAppBanner = MobileAppBannerInterface & {
  __typename?: 'AdminMobileAppBanner';
  backgroundColorHex: Scalars['HexColorCode']['output'];
  contentTargeting: AdminMobileAppContentTargeting;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  destinationUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  leadingImage?: Maybe<MobileAppBannerImage>;
  title: Scalars['String']['output'];
};

export type AdminMobileAppBottomSheet = MobileAppBottomSheetInterface & {
  __typename?: 'AdminMobileAppBottomSheet';
  buttons: Array<MobileAppBottomSheetButton>;
  contentTargeting: AdminMobileAppContentTargeting;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  formattedDescription: FormatString;
  formattedTitle: FormatString;
  id: Scalars['ID']['output'];
  image?: Maybe<MobileAppBottomSheetImage>;
  internalName: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AdminMobileAppContent =
  | AdminMobileAppBanner
  | AdminMobileAppBottomSheet
  | AdminMobileAppDiscoverItem
  | AdminMobileAppSplashScreen;

export type AdminMobileAppContentTargeting = {
  __typename?: 'AdminMobileAppContentTargeting';
  dismissalDurationSeconds?: Maybe<Scalars['Int']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  isActive: Scalars['Boolean']['output'];
  priority: Scalars['Int']['output'];
  requiresTrigger: Scalars['Boolean']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * JSON converted `json-rules-engine` rules.
   * No targeting rules will result in content being visible to any user/pet
   * See https://github.com/CacheControl/json-rules-engine/blob/master/docs/rules.md#persisting
   */
  targetingRules?: Maybe<Scalars['JSON']['output']>;
};

export type AdminMobileAppDiscoverItem = MobileAppDiscoverItemInterface & {
  __typename?: 'AdminMobileAppDiscoverItem';
  contentTargeting: AdminMobileAppContentTargeting;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  destinationUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Image;
  internalName: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AdminMobileAppSplashScreen = MobileAppSplashScreenInterface & {
  __typename?: 'AdminMobileAppSplashScreen';
  contentTargeting: AdminMobileAppContentTargeting;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  pages: Array<MobileAppSplashScreenPage>;
};

export enum AdminPreSignedUploadPurpose {
  BULK_GROUP_ASSIGNMENT = 'BULK_GROUP_ASSIGNMENT',
  IAC_SHIPMENTS = 'IAC_SHIPMENTS',
}

export type AdminPreSignedUploadUrlInput = {
  contentType: Scalars['String']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
  purpose: AdminPreSignedUploadPurpose;
};

export type AdminPreviewOrderInput = {
  cart: AdminCartInput;
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  shippingAddress: AddressInput;
  shippingOption?: InputMaybe<ShippingOption>;
};

export type AdminQueryNanoBatchInput = {
  batchId: Scalars['String']['input'];
};

export type AdminRecentGPSTest = {
  __typename?: 'AdminRecentGPSTest';
  date: Scalars['DateTime']['output'];
  duration: Scalars['Int']['output'];
  /** A location report associated with the test result, if there is one. */
  locationReport?: Maybe<LocationReport>;
  result: AdminGPSTestResult;
  user: User;
};

export type AdminRecentGPSTests = {
  __typename?: 'AdminRecentGPSTests';
  tests: Array<AdminRecentGPSTest>;
};

export type AdminSearchDogParksInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<PositionInput>;
  radiusInMeters?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<POIType>;
};

export type AdminSendFreeNanoBoxesInput = {
  quantity: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type AdminSendScreenSharingNotificationInput = {
  userId: Scalars['ID']['input'];
};

export type AdminTimeline = {
  __typename?: 'AdminTimeline';
  query: TimelineQueryResult;
};

export type AdminTimelinequeryArgs = {
  input: TimelineQueryInput;
};

export type AdminUpdateChipResellerByBoxInput = {
  boxId: Scalars['String']['input'];
  resellerId: Scalars['ID']['input'];
};

export type AdminUpdateChipResellerBySerialNumberInput = {
  resellerId: Scalars['ID']['input'];
  serialNumbers: Array<Scalars['String']['input']>;
};

export type AdminUpdateInventoryConfigurationInput = {
  allowAutoMarkOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  allowFulfillment?: InputMaybe<Scalars['Boolean']['input']>;
  inStock?: InputMaybe<Scalars['Boolean']['input']>;
  justInTimeKittingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  manualInventoryAdjustment?: InputMaybe<Scalars['Int']['input']>;
  outOfStockThreshold?: InputMaybe<Scalars['Int']['input']>;
  sku: Scalars['String']['input'];
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminUploadImageResult = {
  __typename?: 'AdminUploadImageResult';
  image: Image;
};

export type AdminUpsertFulfillmentWarehouseInput = {
  /** is the warehouse active and can be used for fulfillment */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** the code the 3PL uses to identify the warehouse */
  externalCode: Scalars['String']['input'];
  /** code for the fulfillment provider (3PL) */
  fulfillmentProviderCode: FulfillmentProviderCode;
  /** metadata for the warehouse */
  metadata?: InputMaybe<FulfillmentWarehouseMetadataInput>;
  /** our internal name for this warehouse */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AdministrativeArea = {
  __typename?: 'AdministrativeArea';
  id: Scalars['ID']['output'];
  level: AdminLevel;
  name: Scalars['String']['output'];
};

export enum AgeRange {
  ADULT = 'ADULT',
  PUPPY = 'PUPPY',
  SENIOR = 'SENIOR',
}

export type AnimatedShareableUrls = {
  __typename?: 'AnimatedShareableUrls';
  squareUrl?: Maybe<Scalars['String']['output']>;
  storyUrl?: Maybe<Scalars['String']['output']>;
};

export enum AnimationFormat {
  GIF = 'GIF',
  H264 = 'H264',
  H265 = 'H265',
  MP4 = 'MP4',
  WEBM = 'WEBM',
}

export type AppIntegration = {
  __typename?: 'AppIntegration';
  appId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  exampleCaption?: Maybe<Scalars['String']['output']>;
  exampleImage?: Maybe<Image>;
  icon?: Maybe<Image>;
  title: Scalars['String']['output'];
  userConnected: Scalars['Boolean']['output'];
};

export enum AppIntegrationId {
  alltrails = 'alltrails',
  amazon_alexa = 'amazon_alexa',
  bark = 'bark',
  bringfido = 'bringfido',
  citizen = 'citizen',
  dyrt = 'dyrt',
  fitbit = 'fitbit',
  gaia = 'gaia',
  google_home = 'google_home',
  hiking_project = 'hiking_project',
  irobot_home = 'irobot_home',
  life360 = 'life360',
  mapmyfitness = 'mapmyfitness',
  nextdoor = 'nextdoor',
  nike_run_club = 'nike_run_club',
  nike_training_club = 'nike_training_club',
  oura = 'oura',
  pawshake = 'pawshake',
  peakfinder = 'peakfinder',
  peloton = 'peloton',
  puppr = 'puppr',
  ramblr = 'ramblr',
  ring = 'ring',
  rover = 'rover',
  strava = 'strava',
  wag = 'wag',
  whoop = 'whoop',
}

export type AppUpdatePrompt = {
  __typename?: 'AppUpdatePrompt';
  forced: Scalars['Boolean']['output'];
};

export enum ApplicationState {
  BACKGROUND = 'BACKGROUND',
  INACTIVE = 'INACTIVE',
}

export type ApplyMultidogDiscountResult = {
  __typename?: 'ApplyMultidogDiscountResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Represents a distance that has been obfuscated so as not to reveal
 * too precise of a distance/location. The `miles` field is truncated,
 * and below a certain distance it is capped and the `isLessThanThisDistance`
 * boolean is flagged true.
 */
export type ApproximateDistance = {
  __typename?: 'ApproximateDistance';
  isLessThanThisDistance: Scalars['Boolean']['output'];
  miles: Scalars['Float']['output'];
};

export type Article = {
  __typename?: 'Article';
  excerpt?: Maybe<Scalars['String']['output']>;
  featureImageUrl?: Maybe<Scalars['String']['output']>;
  html: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  /** Whether the current user has liked this article. */
  liked: Scalars['Boolean']['output'];
  primaryAuthor: ArticleAuthor;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ArticleAuthor = {
  __typename?: 'ArticleAuthor';
  name: Scalars['String']['output'];
};

export type AspectRatio = {
  __typename?: 'AspectRatio';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type AspectRatioInput = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type AssignNewOwnerToChipInput = {
  chipId: Scalars['ID']['input'];
  contactInfo: ChipContactInfoInput;
};

export type AssignNewPetToChipInput = {
  chipId: Scalars['ID']['input'];
  petInfo: ChipNewPetInput;
};

/**
 * A chip that the current user owns. It could either be that a pet in their household is assigned to their chip
 * or their email is listed as primary email in the chip contact info.
 */
export type AssignedChip = BaseChip & {
  __typename?: 'AssignedChip';
  id: Scalars['ID']['output'];
  latestContactHistoryEntry?: Maybe<ChipContactHistoryEntry>;
  /** @deprecated Use `latestContactHistoryEntry.contactInfo` instead */
  latestContactInfo?: Maybe<ChipContactInfo>;
  pet?: Maybe<StrangerPet>;
  serialNumber: Scalars['String']['output'];
  shortId: Scalars['String']['output'];
  /** An outstanding transfer request that the current owner of the chip can resolve/reject */
  transfer?: Maybe<ChipTransfer>;
};

export type AssociateChipWithPetInput = {
  chipId: Scalars['ID']['input'];
  petId: Scalars['ID']['input'];
};

export type AuditLogEvent = {
  __typename?: 'AuditLogEvent';
  action: Scalars['String']['output'];
  body: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  initiatedBy?: Maybe<AuditLogEventUser>;
  initiatedByEmail?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

/** Narrower user interface for only what audit log events need */
export type AuditLogEventUser = {
  __typename?: 'AuditLogEventUser';
  dbId?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type AvailableS3DowngradeSubscriptionOptionsInput = {
  moduleId: Scalars['ID']['input'];
};

export type AvailableS3DowngradeSubscriptionOptionsResult = {
  __typename?: 'AvailableS3DowngradeSubscriptionOptionsResult';
  subscriptionOptions: Array<SubscriptionOption>;
};

export type AvatarAbbreviation = {
  __typename?: 'AvatarAbbreviation';
  abbreviation: Scalars['String']['output'];
};

export type AvatarImage = {
  __typename?: 'AvatarImage';
  image: Image;
};

export type BBoxInput = {
  northEasternPosition: PositionInput;
  southWesternPosition: PositionInput;
};

export type BaseChip = {
  id: Scalars['ID']['output'];
  serialNumber: Scalars['String']['output'];
  shortId: Scalars['String']['output'];
};

export type BasePack = {
  actingPetCanJoin: Scalars['Boolean']['output'];
  actingPetCanLeave: Scalars['Boolean']['output'];
  actingPetCannotJoinReason?: Maybe<Scalars['String']['output']>;
  actingPetDidManuallyJoin: Scalars['Boolean']['output'];
  actingPetIsMember: Scalars['Boolean']['output'];
  actingPetMembershipReason: Scalars['String']['output'];
  ageFiltersWithEnoughRankedPets: Array<PackAgeFilter>;
  avatar: PackAvatar;
  category: PackCategory;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * A 6-digit hex color code, including # sign, and letters lowercase (eg.
   * `#ff00b1`) used to highlight this pack in the app.
   */
  highlightColorHex?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  petRanking: PackRanking;
  totalRankedPets: Scalars['Int']['output'];
  /**
   * A 64-bit integer representing the total steps for all pets in this pack for
   * the latest ranking period returned as a String.
   */
  totalRankedSteps: Scalars['String']['output'];
};

export type BasePackpetRankingArgs = {
  packAgeFilter?: InputMaybe<PackAgeFilter>;
  petId: Scalars['ID']['input'];
};

export type BasePet = {
  additionalInfo?: Maybe<Scalars['String']['output']>;
  breed?: Maybe<Breed>;
  dayOfBirth?: Maybe<Scalars['Int']['output']>;
  discoverPostFeed: PetDiscoverFeed;
  fiHandle?: Maybe<Scalars['String']['output']>;
  followers: StrangerPetFeed;
  following: StrangerPetFeed;
  gender: Gender;
  /** @deprecated Use homeLocation instead */
  homeCityState?: Maybe<Scalars['String']['output']>;
  homeLocation?: Maybe<PetHomeLocation>;
  id: Scalars['ID']['output'];
  instagramHandle?: Maybe<Scalars['String']['output']>;
  isPurebred: Scalars['Boolean']['output'];
  monthOfBirth?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  photos: PhotoFeed;
  species?: Maybe<Species>;
  /** Information about step streaks */
  stepActivityInfo: StepActivityInfo;
  /** Total number of likes for this pet, across walks, photos, etc. */
  totalLikes: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
  yearOfBirth: Scalars['Int']['output'];
};

export type BasePetdiscoverPostFeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type BasePetfollowersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type BasePetfollowingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type BatteryHistoryAggregationBucket = {
  __typename?: 'BatteryHistoryAggregationBucket';
  date: Scalars['DateTime']['output'];
  minBattery?: Maybe<Scalars['Float']['output']>;
};

export type BatteryHistoryOverviewDeviceEvent =
  | ConnectionStateChangeDeviceEvent
  | FirmwareVersionChangeDeviceEvent
  | PowerStateChangeDeviceEvent
  | RebootDeviceEvent;

export enum BehaviorSummaryDuration {
  DAYS_30 = 'DAYS_30',
  HOURS_24 = 'HOURS_24',
}

export enum BehaviorType {
  BARKING = 'BARKING',
  CLEANING_SELF = 'CLEANING_SELF',
  DRINKING = 'DRINKING',
  EATING = 'EATING',
  HOWLING = 'HOWLING',
  NO_LABEL = 'NO_LABEL',
  SCRATCHING = 'SCRATCHING',
}

export type BehaviorWindow = {
  __typename?: 'BehaviorWindow';
  numEvents: Scalars['Int']['output'];
  timestampUtc: Scalars['DateTime']['output'];
  totalDurationSeconds: Scalars['Int']['output'];
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  accountBalanceInCents: Scalars['Int']['output'];
  address?: Maybe<Address>;
  billingInfo?: Maybe<BillingInfo>;
  code: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<BillingSubscription>;
};

export type BillingAccountsubscriptionsArgs = {
  filter?: InputMaybe<BillingSubscriptionFilter>;
};

export type BillingAccountInput = {
  address?: InputMaybe<AddressInput>;
  billingInfo?: InputMaybe<BillingInfoInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type BillingAccountMini = {
  __typename?: 'BillingAccountMini';
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export enum BillingCadence {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export type BillingInfo = {
  __typename?: 'BillingInfo';
  address: Address;
  /** @deprecated Multiple payment types are now availble so use paymentInfo instead */
  cardInfo: ObfuscatedCardInfo;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  paymentInfo: PaymentInfo;
};

export type BillingInfoInput = {
  token: Scalars['String']['input'];
};

export type BillingSubscription = {
  __typename?: 'BillingSubscription';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  address?: Maybe<ShippingAddress>;
  billingEmail?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  currentTermEndsAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** Maps to Recurly Subscription UUID */
  id: Scalars['ID']['output'];
  isPaused?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  nextRenewalPreview?: Maybe<SubscriptionRenewalPreview>;
  nextSupplementShipmentDetails?: Maybe<NextSupplementShipmentDetails>;
  pausedAt?: Maybe<Scalars['DateTime']['output']>;
  pendingChanges?: Maybe<SubscriptionPendingChange>;
  quantity: Scalars['Int']['output'];
  state: BillingSubscriptionState;
  /** Details about the plan itself */
  subscriptionOption: SubscriptionOption;
  supplementShipmentDates?: Maybe<SupplementShipmentDates>;
  unitAmountInCents: Scalars['Int']['output'];
};

export type BillingSubscriptionFilter = {
  category?: InputMaybe<BillingSubscriptionFilterCategory>;
  state?: InputMaybe<BillingSubscriptionFilterState>;
};

/** The filter categories include all ProductCategory values */
export enum BillingSubscriptionFilterCategory {
  BAND = 'BAND',
  CHARGING_BASE = 'CHARGING_BASE',
  COLLAR_KIT = 'COLLAR_KIT',
  ENDLINK = 'ENDLINK',
  GIFT_CARD = 'GIFT_CARD',
  MICROCHIP = 'MICROCHIP',
  MODULE = 'MODULE',
  S2_UPGRADE = 'S2_UPGRADE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUPPLEMENT = 'SUPPLEMENT',
}

/** The filter states include all the BillingSubscriptionState plus additional meta states */
export enum BillingSubscriptionFilterState {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  FUTURE = 'FUTURE',
  IN_TRIAL = 'IN_TRIAL',
  LIVE = 'LIVE',
  PAST_DUE = 'PAST_DUE',
  PAUSED = 'PAUSED',
}

export enum BillingSubscriptionState {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  FUTURE = 'FUTURE',
  PAUSED = 'PAUSED',
}

/** Content related to the display of a pet's birthday card */
export type BirthdayCard = {
  __typename?: 'BirthdayCard';
  petPicture?: Maybe<Image>;
  poem?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type BooleanFeatureFlag = FeatureFlag & {
  __typename?: 'BooleanFeatureFlag';
  accessedAt?: Maybe<Scalars['DateTime']['output']>;
  booleanValue: BooleanSetting;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dynamicSettingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BooleanSetting = {
  platformOverrides: Array<BooleanSettingPlatformOverride>;
};

export type BooleanSettingForceOffPlatformOverride = BooleanSettingPlatformOverride & {
  __typename?: 'BooleanSettingForceOffPlatformOverride';
  forceOff: Scalars['Boolean']['output'];
  platform: HandsetPlatform;
};

export type BooleanSettingForceOnPlatformOverride = BooleanSettingPlatformOverride & {
  __typename?: 'BooleanSettingForceOnPlatformOverride';
  forceOn: Scalars['Boolean']['output'];
  platform: HandsetPlatform;
};

export type BooleanSettingMinVersionPlatformOverride = BooleanSettingPlatformOverride & {
  __typename?: 'BooleanSettingMinVersionPlatformOverride';
  minVersion: Scalars['String']['output'];
  platform: HandsetPlatform;
};

export type BooleanSettingPlatformOverride = {
  platform: HandsetPlatform;
};

export type BooleanSettingPlatformOverrideInput = {
  /** Note: only one of `forceOn`, `forceOff`, or `minVersion` should be set. */
  forceOff?: InputMaybe<Scalars['Boolean']['input']>;
  /** Note: only one of `forceOn`, `forceOff`, or `minVersion` should be set. */
  forceOn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Note: only one of `forceOn`, `forceOff`, or `minVersion` should be set. */
  minVersion?: InputMaybe<Scalars['String']['input']>;
  platform: HandsetPlatform;
};

export enum BooleanSettingType {
  FALSE = 'FALSE',
  GROUPS = 'GROUPS',
  PERCENTAGE_OF_USERS = 'PERCENTAGE_OF_USERS',
  RANDOM_PERCENTAGE = 'RANDOM_PERCENTAGE',
  TRUE = 'TRUE',
  USER_IDS = 'USER_IDS',
}

export type Breed = {
  __typename?: 'Breed';
  averageWeightMax?: Maybe<Scalars['Float']['output']>;
  averageWeightMin?: Maybe<Scalars['Float']['output']>;
  dbId: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  exerciseRating: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  origins: Array<Origin>;
  popularityScore: Scalars['Int']['output'];
  temperaments: Array<Temperament>;
};

export type BrowsePacksResponse = {
  __typename?: 'BrowsePacksResponse';
  packFeed: PackFeed;
};

export type CancelOrderLineItemsInput = {
  cancelYearlySubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  lineItemIds: Array<Scalars['ID']['input']>;
  placeOrderOnHold?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CancelSupplementSubscriptionInput = {
  recurlySubscriptionId: Scalars['ID']['input'];
};

export type CancelSupplementSubscriptionResult = {
  __typename?: 'CancelSupplementSubscriptionResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Card = {
  date: Scalars['DateTime']['output'];
  icon?: Maybe<Image>;
  id: Scalars['ID']['output'];
  message: FormatString;
  title?: Maybe<Scalars['String']['output']>;
};

export type CardFeed = {
  __typename?: 'CardFeed';
  cards: Array<Card>;
  pageInfo: PageInfo;
};

export type CartLineItemInput = {
  lineItemId: Scalars['ID']['input'];
  sku: Scalars['String']['input'];
};

export type CartPurchaseResult = {
  __typename?: 'CartPurchaseResult';
  chargeInvoice?: Maybe<PurchaseResultInvoice>;
  giftCardInvoices: Array<PurchaseResultInvoice>;
  isReturningCustomer: Scalars['Boolean']['output'];
};

export type ChargingBase = {
  __typename?: 'ChargingBase';
  baseId: Scalars['String']['output'];
  batch?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credentialUpdate?: Maybe<CredentialUpdate>;
  dbId: Scalars['Int']['output'];
  fccId?: Maybe<Scalars['String']['output']>;
  hardwareRevision?: Maybe<Scalars['String']['output']>;
  /** May be false if the system has reason to believe the base's position is no longer accurate */
  hasAccuratePosition: Scalars['Boolean']['output'];
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  info: Scalars['JSON']['output'];
  infoLastUpdated: Scalars['DateTime']['output'];
  lastReportedFirmwareVersion?: Maybe<Scalars['String']['output']>;
  macAddress?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  networkName?: Maybe<Scalars['String']['output']>;
  online: Scalars['Boolean']['output'];
  /** Indicative of how often the base is online (doesn't relate to connection to modules etc) */
  onlineQuality: DiagnosticStatus;
  percentOnlineLastNDays: Scalars['Int']['output'];
  platform: Platform;
  position?: Maybe<Position>;
  returnEligibility?: Maybe<RMAPolicyResult>;
  updateChannel?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  warrantyStatus?: Maybe<RMAPolicyResult>;
};

export type ChargingBasecredentialUpdateArgs = {
  currentHash?: InputMaybe<Scalars['String']['input']>;
};

export type ChargingBasepercentOnlineLastNDaysArgs = {
  nDays: Scalars['Int']['input'];
};

export type ChargingBasereturnEligibilityArgs = {
  drilldownCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
};

export type ChargingBasewarrantyStatusArgs = {
  drilldownCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
};

export type ChargingBaseAssignable = {
  __typename?: 'ChargingBaseAssignable';
  assignable: Scalars['Boolean']['output'];
};

export enum CheckFiHandleValidAndAvailableResponse {
  INVALID = 'INVALID',
  IS_TAKEN = 'IS_TAKEN',
  SUCCESS = 'SUCCESS',
}

export type CheckInNewRMAItemInput = {
  condition: RMAItemCondition;
  itemInput: RMAItemInput;
};

export type CheckInRMAInput = {
  id: Scalars['ID']['input'];
  itemUpdates: Array<CheckInRMAItemInput>;
  newItems: Array<CheckInNewRMAItemInput>;
  reasonUpdate?: InputMaybe<CheckInReasonUpdate>;
};

export type CheckInRMAItemInput = {
  condition: RMAItemCondition;
  hardwareDefectCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CheckInReasonUpdate = {
  drilldownCode: Scalars['String']['input'];
  processType: RMAProcessType;
  reasonCode: Scalars['String']['input'];
};

export type CheckInUnknownReturnInput = {
  customerEmail?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  drilldownCode: Scalars['String']['input'];
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceSource?: InputMaybe<InvoiceSource>;
  items: Array<CheckInNewRMAItemInput>;
  purchaseProvenanceCode?: InputMaybe<Scalars['String']['input']>;
  reasonCode: Scalars['String']['input'];
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum ChipCondition {
  DAMAGED = 'DAMAGED',
  DECEASED_PET = 'DECEASED_PET',
  MISSING = 'MISSING',
  WORKING = 'WORKING',
}

export type ChipContactHistoryEntry = ChipContactInfoResponse & {
  __typename?: 'ChipContactHistoryEntry';
  contactInfo: ChipContactInfo;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  dbId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  latest: Scalars['Boolean']['output'];
  rejected: Scalars['Boolean']['output'];
  source: ChipContactHistoryEntrySource;
};

export enum ChipContactHistoryEntrySource {
  FI_NANO = 'FI_NANO',
  SHELTERLUV_BACKFILL = 'SHELTERLUV_BACKFILL',
  SHELTERLUV_INCREMENTAL = 'SHELTERLUV_INCREMENTAL',
}

export type ChipContactInfo = {
  __typename?: 'ChipContactInfo';
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  primaryEmail: Scalars['String']['output'];
  primaryPhone?: Maybe<Scalars['String']['output']>;
  secondaryEmail?: Maybe<Scalars['String']['output']>;
  secondaryFirstName?: Maybe<Scalars['String']['output']>;
  secondaryLastName?: Maybe<Scalars['String']['output']>;
  secondaryPhone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type ChipContactInfoInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  estimatedMonthlyChipRegistrations?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  primaryEmail: Scalars['String']['input'];
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
  secondaryEmail?: InputMaybe<Scalars['String']['input']>;
  secondaryFirstName?: InputMaybe<Scalars['String']['input']>;
  secondaryLastName?: InputMaybe<Scalars['String']['input']>;
  secondaryPhone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type ChipContactInfoResponse = {
  contactInfo: ChipContactInfo;
  dbId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type ChipImportInput = {
  chips: Array<ChipInput>;
  updateOnDuplicate?: InputMaybe<ChipImportUpdateOnDuplicate>;
};

export enum ChipImportUpdateOnDuplicate {
  SERIAL_NUMBER = 'SERIAL_NUMBER',
  SHORT_ID = 'SHORT_ID',
}

export type ChipInput = {
  boxId: Scalars['String']['input'];
  cartonId: Scalars['String']['input'];
  expiresAt: Scalars['DateTime']['input'];
  manufacturedAt: Scalars['DateTime']['input'];
  serialNumber: Scalars['String']['input'];
  shippedAt: Scalars['DateTime']['input'];
  shortId: Scalars['String']['input'];
};

export type ChipNewPetInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  breedId?: InputMaybe<Scalars['ID']['input']>;
  dayOfBirth?: InputMaybe<Scalars['Int']['input']>;
  gender: Gender;
  isPurebred?: InputMaybe<Scalars['Boolean']['input']>;
  monthOfBirth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  species?: InputMaybe<Species>;
  weight: Scalars['Float']['input'];
  yearOfBirth: Scalars['Int']['input'];
};

export type ChipQueryInput = {
  assignedToPet?: InputMaybe<Scalars['Boolean']['input']>;
  assignedToReseller?: InputMaybe<Scalars['Boolean']['input']>;
  boxId?: InputMaybe<Scalars['String']['input']>;
  cartonId?: InputMaybe<Scalars['String']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  serialNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  shortId?: InputMaybe<Scalars['String']['input']>;
};

/** This step currently does not allow using an email email different from the current logged in user's email address */
export type ChipRegistrationAddEmailInput = {
  chipId: Scalars['ID']['input'];
};

export type ChipRegistrationAddExistingPetInput = {
  chipId: Scalars['ID']['input'];
  petId: Scalars['ID']['input'];
};

export type ChipRegistrationAddNewPetInput = {
  chipId: Scalars['ID']['input'];
  petInfo: ChipNewPetInput;
};

export type ChipRegistrationUpdateContactInput = {
  chipId: Scalars['ID']['input'];
  contactInfo: ChipContactInfoInput;
};

export type ChipReseller = {
  __typename?: 'ChipReseller';
  chips: ResellerChipFeed;
  contactInfo: ChipContactInfo;
  dbId: Scalars['Int']['output'];
  household: Household;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  petInterest?: Maybe<ResellerPetInterest>;
  preferences: ChipResellerPreferences;
  searchChips: ResellerChipFeed;
  status: ResellerStatus;
  type: ResellerType;
};

export type ChipResellerchipsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statusType?: InputMaybe<ChipStatusType>;
};

export type ChipResellersearchChipsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ChipQueryInput>;
};

export type ChipResellerPreferences = {
  __typename?: 'ChipResellerPreferences';
  shelterluv?: Maybe<ShelterluvConfiguration>;
};

export type ChipResellerQueryInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ResellerStatus>;
  type?: InputMaybe<ResellerType>;
};

export enum ChipStatusType {
  /** Chip that is registered to the reseller. TODO: rename this? */
  ACTIVE = 'ACTIVE',
  /** Chip that is pending transfer to a new owner. */
  REVIEW = 'REVIEW',
  /** Chip that is not registered. */
  SHIPPED = 'SHIPPED',
}

export type ChipTransfer = ChipContactInfoResponse & {
  __typename?: 'ChipTransfer';
  /**
   * Whether the current user can resolve this transfer (i.e. it is to them,
   * or they are a reseller and the current owner of the chip.)
   */
  canResolve: Scalars['Boolean']['output'];
  chip: StrangerChip;
  contactInfo: ChipContactInfo;
  dbId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  origin: ChipTransferOrigin;
  pet?: Maybe<StrangerPet>;
  status: ChipTransferStatus;
};

export enum ChipTransferOrigin {
  CURRENT_OWNER = 'CURRENT_OWNER',
  NEW_OWNER = 'NEW_OWNER',
}

export type ChipTransferQueryInput = {
  hasPet?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ChipTransferStatus>;
};

export enum ChipTransferStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type Circle = {
  __typename?: 'Circle';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  radius: Scalars['Float']['output'];
};

export type ClaimFreeMonthInput = {
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type ClaimFreeMonthResult = {
  __typename?: 'ClaimFreeMonthResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ClaimPauseMonthInput = {
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type ClaimPauseMonthResult = {
  __typename?: 'ClaimPauseMonthResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ClientLocationAccuracy {
  DEFAULT = 'DEFAULT',
  HIGH = 'HIGH',
}

export type ClientUrls = {
  __typename?: 'ClientUrls';
  helpCenterBaseConnectivityImprovement: Scalars['String']['output'];
  helpCenterBaseTroubleshooting: Scalars['String']['output'];
  helpCenterBatteryImprovement: Scalars['String']['output'];
  helpCenterLteGpsConnectivityInfo: Scalars['String']['output'];
};

export enum CollarLostDogModeState {
  /** LDM is not enabled on the backend. */
  LDM_DISABLED = 'LDM_DISABLED',
  /** LDM is on from the backend perspective, and the collar has sent a report in LDM. */
  LDM_ENABLED = 'LDM_ENABLED',
  /** Collar has been informed of LDM state change, but has not yet sent a report in LDM. */
  LDM_REPORT_PENDING = 'LDM_REPORT_PENDING',
  /** LDM has been toggled on in App or Admin. */
  LDM_REQUESTED = 'LDM_REQUESTED',
}

export type CollarStatus = OfflineCollarStatus | OnlineCollarStatus;

export type Comment = {
  __typename?: 'Comment';
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Admin-only */
  dbId: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  /** If the current user has liked this comment. */
  liked: Scalars['Boolean']['output'];
  strangerPet: StrangerPet;
};

export type CommentFeed = {
  __typename?: 'CommentFeed';
  items: Array<Comment>;
  totalCount: Scalars['Int']['output'];
};

export type CommentFeeditemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CommentModerationFeed = ModerationFeed & {
  __typename?: 'CommentModerationFeed';
  items: Array<ModerationCommentItem>;
  totalCount: Scalars['Int']['output'];
};

export type ConcreteDailyRestSummary = DailyRestSummary & {
  __typename?: 'ConcreteDailyRestSummary';
  date: Scalars['DateTime']['output'];
  sleepAmounts: Array<SleepAmount>;
};

/** A detailed breakdown of a pet's sleep period including interruptions. */
export type ConcreteOvernightRestSummary = OvernightRestSummary & {
  __typename?: 'ConcreteOvernightRestSummary';
  /**
   * The date on which sleep commenced (e.g. the pet slept on this night.)
   * This is a DateTime at midnight in the client's timezone that should be used for its date components only.
   */
  date: Scalars['DateTime']['output'];
  interruptions: Array<SleepInterruptionSpan>;
  sleepEnd: Scalars['DateTime']['output'];
  sleepSeconds: Scalars['Int']['output'];
  sleepStart: Scalars['DateTime']['output'];
};

export type ConcreteRestSummaryData = {
  __typename?: 'ConcreteRestSummaryData';
  hourlySleepBars: Array<HourlySleepBar>;
  /** E.g. duration pet was napping, duration pet was sleeping, for this day/week/month. */
  sleepAmounts: Array<SleepAmount>;
};

export type ConnectedToBase = ConnectionState & {
  __typename?: 'ConnectedToBase';
  chargingBase: ChargingBase;
  date: Scalars['DateTime']['output'];
};

export type ConnectedToCellular = ConnectionState & {
  __typename?: 'ConnectedToCellular';
  date: Scalars['DateTime']['output'];
  /**
   * DEPRECATED! This only represents cell strength at an instant in time
   * and is practically useless.
   */
  signalStrengthPercent?: Maybe<Scalars['Int']['output']>;
};

export type ConnectedToUser = ConnectionState & {
  __typename?: 'ConnectedToUser';
  date: Scalars['DateTime']['output'];
  user: User;
};

export type ConnectedToWifi = ConnectionState & {
  __typename?: 'ConnectedToWifi';
  date: Scalars['DateTime']['output'];
  ssid: Scalars['String']['output'];
};

export type ConnectedWifiInput = {
  bssid?: InputMaybe<Scalars['String']['input']>;
  ssid?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectionState = {
  date: Scalars['DateTime']['output'];
};

export type ConnectionStateChangeDeviceEvent = DeviceEventBase & {
  __typename?: 'ConnectionStateChangeDeviceEvent';
  date: Scalars['DateTime']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  newConnectionStateType: ConnectionStateType;
  oldConnectionStateType: ConnectionStateType;
  platform: Scalars['String']['output'];
  prevDate: Scalars['DateTime']['output'];
  prevDuration: Scalars['Float']['output'];
};

export type ConnectionStateChangesResult = {
  __typename?: 'ConnectionStateChangesResult';
  maxResultsReached: Scalars['Boolean']['output'];
  results: Array<ConnectionStateChangeDeviceEvent>;
};

export enum ConnectionStateType {
  BASE = 'BASE',
  CELLULAR = 'CELLULAR',
  MOBILE = 'MOBILE',
  UNKNOWN = 'UNKNOWN',
  WIFI = 'WIFI',
}

export type ConsumerStoreCartInput = {
  cartItems: Array<StoreCartItemInput>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  redeemedGiftCardCode?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  shippingCode?: InputMaybe<Scalars['String']['input']>;
};

export type ContactInfo = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContactInfoWithMeta = {
  __typename?: 'ContactInfoWithMeta';
  associatedUser?: Maybe<StrangerUser>;
  contactInfo: UserContactInfo;
  /** Copy for sending an invitation to this user via SMS. */
  inviteText?: Maybe<Scalars['String']['output']>;
  /** How many Fi users have this contact. */
  knownFiUsers?: Maybe<Scalars['Int']['output']>;
};

export type ContactsForUserInput = {
  /** By default, we exclude contacts that have an account. Setting this option to true would return all contacts. */
  includeContactsWithAccount?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**
   * By default, contacts with no phone number (just email) are included.
   * Setting this to true will only return contacts with phone numbers.
   * Default is true.
   */
  onlyContactsWithPhoneNumbers?: InputMaybe<Scalars['Boolean']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
  /** Query for the contacts first/last name */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /**
   * If true, results are sorted first by number of Fi users that know the contact.
   * Defaults is false.
   */
  sortByKnownFiUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Returned by the `contactsForUser` query. Used for referral/invitations. */
export type ContactsForUserResponse = {
  __typename?: 'ContactsForUserResponse';
  contacts: Array<ContactInfoWithMeta>;
  pageInfo: PageInfo;
};

export type CouponInput = {
  code: Scalars['ID']['input'];
};

export type CouponRedirectInput = {
  bannerText?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  isPartner?: InputMaybe<Scalars['Boolean']['input']>;
  redirectUri: Scalars['String']['input'];
};

export type CreateBillingAccountInput = {
  address?: InputMaybe<AddressInput>;
};

export type CreateCertGenJobResult = {
  __typename?: 'CreateCertGenJobResult';
  jobId?: Maybe<Scalars['String']['output']>;
  jobName?: Maybe<Scalars['String']['output']>;
};

export type CreateDeviceInput = {
  carrier?: InputMaybe<Scalars['String']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  platform: Scalars['String']['input'];
};

export type CreateDynamicEnumMemberInput = {
  code: Scalars['String']['input'];
  kind: DynamicEnumKind;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFiNewsNotificationInput = {
  featureImageUrl: Scalars['String']['input'];
  message: Scalars['String']['input'];
  publishedAt: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CreateFirmwareChannelInput = {
  channelName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  platform: Scalars['String']['input'];
  releaseClass: FirmwareReleaseClass;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type CreateImageFromUrlInput = {
  url: Scalars['String']['input'];
};

export type CreateImageFromUrlResult = {
  __typename?: 'CreateImageFromUrlResult';
  image: Image;
  photoId: Scalars['ID']['output'];
};

export type CreateInPersonEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['String']['input'];
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  startingInventory: Scalars['JSON']['input'];
};

export type CreateMobileAppBannerInput = {
  backgroundColorHex: Scalars['HexColorCode']['input'];
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  description: Scalars['String']['input'];
  destinationUrl: Scalars['String']['input'];
  internalName: Scalars['String']['input'];
  leadingImage?: InputMaybe<MobileAppBannerImageInput>;
  title: Scalars['String']['input'];
};

export type CreateMobileAppBottomSheetButtonInput = {
  backgroundColorHex?: InputMaybe<Scalars['HexColorCode']['input']>;
  destinationUrl: Scalars['String']['input'];
  text: Scalars['String']['input'];
  type: MobileAppBottomSheetButtonType;
};

export type CreateMobileAppBottomSheetImageInput = {
  imageUrl: Scalars['String']['input'];
  type: MobileAppBottomSheetImageType;
};

export type CreateMobileAppBottomSheetInput = {
  buttons: Array<CreateMobileAppBottomSheetButtonInput>;
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  description: Scalars['String']['input'];
  image?: InputMaybe<CreateMobileAppBottomSheetImageInput>;
  internalName: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateMobileAppDiscoverItemInput = {
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  description: Scalars['String']['input'];
  destinationUrl: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  internalName: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateMobileAppSplashScreenButtonInput = {
  destinationUrl?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type CreateMobileAppSplashScreenInput = {
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  internalName: Scalars['String']['input'];
  pages: Array<CreateMobileAppSplashScreenPageInput>;
};

export type CreateMobileAppSplashScreenPageInput = {
  button: CreateMobileAppSplashScreenButtonInput;
  description: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreatePackInput = {
  fiHandle: Scalars['String']['input'];
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  petId: Scalars['ID']['input'];
};

export type CreatePetInput = {
  breedId?: InputMaybe<Scalars['ID']['input']>;
  dayOfBirth?: InputMaybe<Scalars['Int']['input']>;
  gender: Gender;
  householdId?: InputMaybe<Scalars['ID']['input']>;
  isPurebred: Scalars['Boolean']['input'];
  monthOfBirth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  species?: InputMaybe<Species>;
  weight: Scalars['Float']['input'];
  yearOfBirth: Scalars['Int']['input'];
};

export type CreatePlaceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** Should only be set for polygon safe zones. */
  boundary?: InputMaybe<Scalars['JSON']['input']>;
  coordinates?: InputMaybe<Array<PositionInput>>;
  householdId?: InputMaybe<Scalars['ID']['input']>;
  ignoreIfAlreadyExists?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<PositionInput>;
  /** Should only be set for circle safe zones. */
  radius?: InputMaybe<Scalars['Float']['input']>;
  /** Circle or polygon safe zone. */
  type?: InputMaybe<PlaceType>;
};

export type CreatePromotionInput = {
  internalName: Scalars['String']['input'];
};

export type CreatePurchaseInput = {
  coupons: Array<CouponInput>;
  inPersonEventUuid?: InputMaybe<Scalars['String']['input']>;
  isRescueOrganizationPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  lineItems: Array<LineItemInput>;
  redeemGiftCard?: InputMaybe<RedeemGiftCardInput>;
  referral?: InputMaybe<ReferralInput>;
  shippingOption?: InputMaybe<ShippingOptionInput>;
};

export type CreateRMAInput = {
  creationNotes?: InputMaybe<Scalars['String']['input']>;
  customerEmail: Scalars['String']['input'];
  customerName: Scalars['String']['input'];
  drilldownCode: Scalars['String']['input'];
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceSource?: InputMaybe<InvoiceSource>;
  items: Array<RMAItemInput>;
  /** Agent made a policy exception to create the RMA. We trust the frontend in this case. */
  madePolicyException?: InputMaybe<Scalars['Boolean']['input']>;
  policyExceptionReason?: InputMaybe<Scalars['String']['input']>;
  processType: RMAProcessType;
  purchaseProvenanceCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
  replacementProductAudits: Array<ReplacementProductAudit>;
  replacementProducts: Array<ReplacementProductInput>;
  shippingAddress?: InputMaybe<AddressInput>;
  tags?: InputMaybe<Array<RMATag>>;
  zendeskNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTextPostInput = {
  /** Users can only specific pre-defined styles (ie. text/background). */
  styleId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  nanoChipRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  /** If null, the user will have to reset their password via email */
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoPostInput = {
  aspectRatio: AspectRatioInput;
  caption?: InputMaybe<Scalars['String']['input']>;
  includesAudio?: InputMaybe<Scalars['Boolean']['input']>;
  /** The same key returned when pre-signed upload URL was fetched */
  key: Scalars['String']['input'];
};

export type CreateZendeskCancellationTicketInput = {
  /** Optional additional text to include in the ticket description. */
  additionalText?: InputMaybe<Scalars['String']['input']>;
  cancellationReason: zendeskCancellationReason;
  recurlySubscriptionId: Scalars['String']['input'];
};

export type CreateZendeskReplacementTicketInput = {
  recurlySubscriptionId: Scalars['String']['input'];
  /** Free form text explaining reason for requesting replacement. Included in the ticket description. */
  replacementReason: Scalars['String']['input'];
};

export type CreateZendeskTicketResult = {
  __typename?: 'CreateZendeskTicketResult';
  ticketCreated?: Maybe<Scalars['Boolean']['output']>;
  ticketUrl?: Maybe<Scalars['String']['output']>;
};

export type CredentialUpdate = {
  __typename?: 'CredentialUpdate';
  encryptedCredentials: Scalars['Binary']['output'];
  hash: Scalars['String']['output'];
};

export type DailyRestSummary = {
  date: Scalars['DateTime']['output'];
};

export type DailyStepSummary = {
  __typename?: 'DailyStepSummary';
  date: Scalars['DateTime']['output'];
  stepGoal: Scalars['Int']['output'];
  totalSteps: Scalars['Int']['output'];
};

/** DEPRECATED: Use activity summaries instead. */
export type DailySummary = {
  __typename?: 'DailySummary';
  activityGoal: ActivityGoal;
  activityIntensities: Array<ActivityIntensity>;
  end: Scalars['DateTime']['output'];
  nextCursor?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

/** DEPRECATED: Use activity summaries instead. */
export type DailySummaryactivityIntensitiesArgs = {
  stride?: InputMaybe<Scalars['Int']['input']>;
};

/** DEPRECATED: Use activity summaries instead. */
export type DailySummaryFeed = {
  __typename?: 'DailySummaryFeed';
  dailySummaries: Array<DailySummary>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type DateRangeInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type DeleteFirmwareChannelInput = {
  channelName: Scalars['String']['input'];
  platform: Scalars['String']['input'];
};

export type DeleteFirmwareInput = {
  versions: Array<Scalars['String']['input']>;
};

export type DensityBucket = {
  __typename?: 'DensityBucket';
  day: Scalars['DateTime']['output'];
  total: Scalars['Int']['output'];
};

/**
 * Represents an activity type that has been detected from a phone.
 * (Android and iOS both expose APIs to get this.)
 */
export enum DetectedActivityType {
  AUTOMOTIVE = 'AUTOMOTIVE',
  UNKNOWN = 'UNKNOWN',
  WALKING = 'WALKING',
}

export type Device = {
  __typename?: 'Device';
  activityReportCount?: Maybe<Scalars['Int']['output']>;
  activityReports: Array<ActivityReport>;
  adminRecentGPSTests: AdminRecentGPSTests;
  adminTimeline: AdminTimeline;
  /** Admin only */
  autoAddUserToHousehold?: Maybe<User>;
  availableLedColors: Array<LedColor>;
  batch?: Maybe<Scalars['String']['output']>;
  bluetoothAddress: Scalars['String']['output'];
  capabilities: Array<ModuleCapability>;
  carrier?: Maybe<Scalars['String']['output']>;
  /** Admin only (Note: this query makes a request to a 3rd-party service and can be slow) */
  carrierDetails?: Maybe<Scalars['JSON']['output']>;
  /** Admin only (Note: this query makes a request to a 3rd-party service and can be slow) */
  carrierSessionInfo?: Maybe<Scalars['JSON']['output']>;
  /** Admin only (Note: this query makes a request to a 3rd-party service and can be slow) */
  carrierUsage?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credentialUpdate?: Maybe<CredentialUpdate>;
  currentCollarModel?: Maybe<Scalars['String']['output']>;
  dbId: Scalars['Int']['output'];
  /** Admin only */
  deviceResponseOverride?: Maybe<DeviceResponseOverride>;
  diagnostics?: Maybe<DeviceDiagnostics>;
  fccId?: Maybe<Scalars['String']['output']>;
  firmwareUpdate?: Maybe<FirmwareUpdate>;
  hardwareRevision?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use subscriptionAccessType */
  hasActiveSubscription: Scalars['Boolean']['output'];
  /** Month-to-Month upgrade promotion */
  hasClaimedFreeMonthsDuringMonthlyUpgrade?: Maybe<Scalars['Boolean']['output']>;
  hasSubscriptionOverride: Scalars['Boolean']['output'];
  iccid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imei?: Maybe<Scalars['String']['output']>;
  info: Scalars['JSON']['output'];
  infoLastUpdated: Scalars['DateTime']['output'];
  /** Admin only (Note: queries RedShift for this data) */
  inventoryData?: Maybe<Scalars['JSON']['output']>;
  /** Admin only */
  isBlocked: Scalars['Boolean']['output'];
  /** Admin only */
  isRefurbished: Scalars['Boolean']['output'];
  /** Whether the device can use the Search Party feature for publicly sharing live location */
  isSearchPartyEligible: Scalars['Boolean']['output'];
  lastConnectionState: ConnectionState;
  lastLocation?: Maybe<Location>;
  lastLocationReport?: Maybe<LocationReport>;
  lastReportTimestampsBySource: Scalars['JSON']['output'];
  lastReportedFirmwareVersion?: Maybe<Scalars['String']['output']>;
  ledColor: LedColor;
  /** @deprecated It is infeasible to get a devices location report count */
  locationReportCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Storage V2 does not easily support this query */
  locationReportDensity: Array<DensityBucket>;
  locationReportsByDate: Array<LocationReport>;
  macAddress?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  /**
   * Date by which we expect a location report. If this date is in the future, it means
   * we have an accurate location for the module.
   */
  nextLocationUpdateExpectedBy?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Date by which we next expect any report. If this date is in the future, it means
   * the module is online.
   */
  nextReportExpectedBy?: Maybe<Scalars['DateTime']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  operationParams: OperationParams;
  /** Admin-only. Return information about the original plan that the device was activated with. */
  originalPlan?: Maybe<SubscriptionOption>;
  originalPurchaser?: Maybe<User>;
  pet?: Maybe<Pet>;
  platform: Platform;
  preselectedPlanCodeForNewSubscription?: Maybe<Scalars['String']['output']>;
  /**
   * Return the set of SubscriptionOptions that a user is allowed to purchase for a device;
   * e.g. if they purchased with a $29/mo plan, only return $29/mo. For users who purchased
   * on a yearly plan this would return all yearly plans.
   */
  purchasableSubscriptionOptions: Array<SubscriptionOption>;
  returnEligibility?: Maybe<RMAPolicyResult>;
  series2UpgradeEligible: Scalars['Boolean']['output'];
  /** Admin only */
  shipmentData?: Maybe<DeviceShipment>;
  shouldOfferDowngradeDuringCancellation?: Maybe<Scalars['Boolean']['output']>;
  /** In-app cancellation flow offers. */
  shouldOfferFreeMonthDuringCancellation?: Maybe<Scalars['Boolean']['output']>;
  shouldOfferPauseMonthDuringCancellation?: Maybe<Scalars['Boolean']['output']>;
  /** NOTE: This makes a third-party API call to Recurly so please don't call it in a critical path. */
  subscription?: Maybe<BillingSubscription>;
  subscriptionAccessType: SubscriptionAccessType;
  /** Returns the URL to the webview where a user can manage their device's subscription. */
  subscriptionDetailsWebviewUrl: Scalars['String']['output'];
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionOverrideEndsAt?: Maybe<Scalars['DateTime']['output']>;
  subscriptionRenewalStatus?: Maybe<SubscriptionRenewalStatus>;
  updateChannel?: Maybe<Scalars['String']['output']>;
  updateFrequencyOptions: Array<UpdateFrequencyOption>;
  upgradeRedeemed?: Maybe<DeviceUpgrade>;
  warrantyStatus?: Maybe<RMAPolicyResult>;
  /** Admin only */
  wsConnectionDetails?: Maybe<Scalars['JSON']['output']>;
};

export type DeviceactivityReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DevicecredentialUpdateArgs = {
  currentHash?: InputMaybe<Scalars['String']['input']>;
};

export type DevicediagnosticsArgs = {
  period: DeviceDiagnosticsPeriod;
};

export type DevicefirmwareUpdateArgs = {
  currentVersion: Scalars['String']['input'];
};

export type DevicelastConnectionStateArgs = {
  ignoreOffline?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DevicelocationReportsByDateArgs = {
  after: Scalars['DateTime']['input'];
  before: Scalars['DateTime']['input'];
};

export type DevicepurchasableSubscriptionOptionsArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  inAppFlow?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyUpgrade?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DevicereturnEligibilityArgs = {
  drilldownCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
};

export type DevicewarrantyStatusArgs = {
  drilldownCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
};

export type DeviceActivity = DeviceEventBase & {
  __typename?: 'DeviceActivity';
  date: Scalars['DateTime']['output'];
  duration: Scalars['Float']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  steps: Scalars['Int']['output'];
};

export type DeviceAssignable = {
  __typename?: 'DeviceAssignable';
  assignable: Scalars['Boolean']['output'];
};

export type DeviceBatchInfo = {
  __typename?: 'DeviceBatchInfo';
  batch: Scalars['String']['output'];
  numActivated: Scalars['Int']['output'];
  numDevices: Scalars['Int']['output'];
};

export enum DeviceConnectionMode {
  BASE = 'BASE',
  GPS = 'GPS',
  MOBILE = 'MOBILE',
  OFFLINE = 'OFFLINE',
  WIFI = 'WIFI',
}

export type DeviceConnectionRatio = {
  __typename?: 'DeviceConnectionRatio';
  mode: DeviceConnectionMode;
  percent: Scalars['Int']['output'];
  seconds: Scalars['Int']['output'];
};

/** Get the `errorRadius` from the `location` field. */
export type DeviceDiagnosticLocationReport = {
  __typename?: 'DeviceDiagnosticLocationReport';
  cellStrength: DiagnosticStatus;
  date: Scalars['DateTime']['output'];
  /** If false, this is a celllookup report meaning the GPS wasn't able to aquire a fix. */
  fromGPS: Scalars['Boolean']['output'];
  /** Not the actual location report ID (we don't expose that) */
  id: Scalars['ID']['output'];
  location: Location;
};

export type DeviceDiagnostics = {
  __typename?: 'DeviceDiagnostics';
  connectionRatios: Array<DeviceConnectionRatio>;
  /** Clients should poll this to get the result of a GPS test. */
  gpsTestResultSince?: Maybe<GPSTestResult>;
  lastCellularReport?: Maybe<DeviceDiagnosticLocationReport>;
  /** Healthy if the collar is typically connected to BLE. */
  overallSystemHealth: DiagnosticStatus;
  periodDurationSeconds: Scalars['Int']['output'];
};

export type DeviceDiagnosticsgpsTestResultSinceArgs = {
  date: Scalars['DateTime']['input'];
};

export enum DeviceDiagnosticsPeriod {
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  THREE_DAYS = 'THREE_DAYS',
}

export type DeviceError = {
  __typename?: 'DeviceError';
  crashSite?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  edata?: Maybe<Scalars['String']['output']>;
  expr?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  func?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  line?: Maybe<Scalars['Int']['output']>;
  pc?: Maybe<Scalars['Int']['output']>;
  rev?: Maybe<Scalars['String']['output']>;
  stack?: Maybe<Array<Scalars['String']['output']>>;
  stackKey?: Maybe<Scalars['String']['output']>;
  stackLines?: Maybe<Array<Scalars['String']['output']>>;
};

export type DeviceEventBase = {
  date: Scalars['DateTime']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  platform: Scalars['String']['output'];
};

export type DevicePowerState = {
  __typename?: 'DevicePowerState';
  hasPowerSupply?: Maybe<Scalars['Boolean']['output']>;
  isCharging?: Maybe<Scalars['Boolean']['output']>;
};

export type DeviceQueryInput = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  updateChannel?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceResponseOverride = {
  __typename?: 'DeviceResponseOverride';
  expiresAt: Scalars['DateTime']['output'];
  responseOverride: Scalars['JSON']['output'];
};

export type DeviceShipment = {
  __typename?: 'DeviceShipment';
  createdAt: Scalars['DateTime']['output'];
  externalOrderId?: Maybe<Scalars['String']['output']>;
  externalOrderSource?: Maybe<Scalars['String']['output']>;
  fulfillmentFlow?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  shippedAt: Scalars['DateTime']['output'];
  sku: Scalars['String']['output'];
};

export type DeviceStat = {
  __typename?: 'DeviceStat';
  friendlyName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum DeviceType {
  BASE = 'BASE',
  MODULE = 'MODULE',
}

export enum DeviceUpgrade {
  SERIES_2 = 'SERIES_2',
  SERIES_3 = 'SERIES_3',
}

export enum DiagnosticStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
  WARNING = 'WARNING',
}

export type DisconnectAppIntegrationResult = {
  __typename?: 'DisconnectAppIntegrationResult';
  /** Populated when success is false */
  reason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DiscoverFeed = {
  __typename?: 'DiscoverFeed';
  feedItems: Array<DiscoverFeedItem>;
  pageInfo: PageInfo;
};

export type DiscoverFeedArticleItem = DiscoverFeedItem & {
  __typename?: 'DiscoverFeedArticleItem';
  article: Article;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

/**
 * This is just an empty placeholder, it does not include data about suggested dogs.
 * On receiving this, clients need to query the `suggestedPetsToFollow` endpoint
 * to get data to populate the carousel.
 */
export type DiscoverFeedCarouselItem = DiscoverFeedItem & {
  __typename?: 'DiscoverFeedCarouselItem';
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type DiscoverFeedItem = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type DiscoverFeedPhotoItem = DiscoverFeedItem & {
  __typename?: 'DiscoverFeedPhotoItem';
  id: Scalars['ID']['output'];
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type DiscoverFeedTextPostItem = DiscoverFeedItem & {
  __typename?: 'DiscoverFeedTextPostItem';
  id: Scalars['ID']['output'];
  post: TextPost;
  timestamp: Scalars['DateTime']['output'];
};

export type DiscoverFeedVideoPostItem = DiscoverFeedItem & {
  __typename?: 'DiscoverFeedVideoPostItem';
  id: Scalars['ID']['output'];
  post: VideoPost;
  timestamp: Scalars['DateTime']['output'];
};

export type DismissPromotionBannerInput = {
  bannerType: Scalars['String']['input'];
  dismissReason?: InputMaybe<Scalars['String']['input']>;
};

/**
 * A piece of in-app content that has been dismissed by a user,
 * and a timestamp of when it was dismissed.
 */
export type DismissedMobileAppContent = {
  __typename?: 'DismissedMobileAppContent';
  /**
   * Pieces of Content can have a dismissal expiration time. This
   * field will be set if the user dismissed the content, but then
   * their dismissal expired, and the content should now be visible.
   */
  dismissalExpiredAt?: Maybe<Scalars['DateTime']['output']>;
  dismissalId: Scalars['ID']['output'];
  dismissedAt: Scalars['DateTime']['output'];
  dismissedContent: AdminMobileAppContent;
};

export type DogPark = {
  __typename?: 'DogPark';
  address?: Maybe<ReverseGeocodeResult>;
  boundary?: Maybe<Scalars['JSON']['output']>;
  dbId: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Position;
  sourceId?: Maybe<Scalars['String']['output']>;
  type: POIType;
};

export type DogParkCheckin = {
  __typename?: 'DogParkCheckin';
  activity?: Maybe<Activity>;
  connectedUser?: Maybe<User>;
  dogPark: DogPark;
  timestamp: Scalars['DateTime']['output'];
};

export type DogParkCheckinFeed = {
  __typename?: 'DogParkCheckinFeed';
  checkins: Array<DogParkCheckin>;
  pageInfo: PageInfo;
};

export type DogParkCheckinFilter = {
  types?: InputMaybe<Array<POIType>>;
};

export type DogParkCheckinsForPetInput = {
  filter?: InputMaybe<DogParkCheckinFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
  petId: Scalars['ID']['input'];
};

export type DogParkFeed = {
  __typename?: 'DogParkFeed';
  count: Scalars['Int']['output'];
  dogParks: Array<DogPark>;
};

export type DogParkNotificationSettingOption = {
  __typename?: 'DogParkNotificationSettingOption';
  description: Scalars['String']['output'];
  shareVisitsEnabled: Scalars['Boolean']['output'];
  visitNotificationsEnabled: Scalars['Boolean']['output'];
};

export type DogParkNotificationSettings = {
  __typename?: 'DogParkNotificationSettings';
  availableNotificationSettingOptions: Array<DogParkNotificationSettingOption>;
  currentNotificationSetting?: Maybe<DogParkNotificationSettingOption>;
  /** @deprecated Use dogParkShareableSubscriptionText instead */
  publicSmsLink?: Maybe<Scalars['String']['output']>;
};

export type DogParkNotificationSettingsInput = {
  shareVisitsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  visitNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DogParkShareableSubscriptionText = {
  __typename?: 'DogParkShareableSubscriptionText';
  text: Scalars['String']['output'];
};

export type DogParkVisitor = {
  __typename?: 'DogParkVisitor';
  /**
   * Optional text for the client to display.
   * For example "Last visited today" for recent visitors.
   */
  displayText?: Maybe<Scalars['String']['output']>;
  /** True when the dog is at the dog park now. */
  isAtDogPark?: Maybe<Scalars['Boolean']['output']>;
  /** Time at which this pet last visited the dog park. */
  lastVisitTimestamp?: Maybe<Scalars['DateTime']['output']>;
  /** Visits this pet has made to the dog park, over a server-defined period. */
  numVisitsLastPeriod: Scalars['Int']['output'];
  pet: StrangerPet;
  /**
   * Step count for this pet, over a server-defined period.
   * @deprecated No longer displayed in UI
   */
  rankingPeriodStepCount?: Maybe<Scalars['Int']['output']>;
  /** Position of this pet in the dog park leaderboard. */
  visitorRankNumber: Scalars['Int']['output'];
};

export type DogParkVisitorFeed = {
  __typename?: 'DogParkVisitorFeed';
  /**
   * Total number of visitors: may be greater than len(visitors) if
   * the backend is truncating feeds, or pagination is implemented.
   */
  count: Scalars['Int']['output'];
  /**
   * The date of the beginning of the rolling window used to count
   * and rank visits to the dog park.
   */
  rankingPeriodStartDate: Scalars['DateTime']['output'];
  visitors: Array<DogParkVisitor>;
};

export type DowngradeSeries2Input = {
  moduleId: Scalars['String']['input'];
};

export type DowngradeSeries2Result = {
  __typename?: 'DowngradeSeries2Result';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DowngradeSeries3Input = {
  moduleId: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type DowngradeSeries3Result = {
  __typename?: 'DowngradeSeries3Result';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DuplicatePromotionInput = {
  newInternalName: Scalars['String']['input'];
  sourceId: Scalars['Int']['input'];
};

export type DynamicEnum = {
  __typename?: 'DynamicEnum';
  id: Scalars['ID']['output'];
  kind: DynamicEnumKind;
  members: Array<DynamicEnumMember>;
};

export enum DynamicEnumKind {
  /** List of hardware defects that can be noted in an RMA. */
  RMA_HARDWARE_DEFECT = 'RMA_HARDWARE_DEFECT',
  /** Where an RMA item was purchased from: Fi Ecomm, Chewy, etc. */
  RMA_PURCHASE_PROVENANCE = 'RMA_PURCHASE_PROVENANCE',
  /** Reason codes for an RMA return. */
  RMA_RETURN_REASON = 'RMA_RETURN_REASON',
  /** Reason codes for an RMA shipping error. */
  RMA_SHIPPING_ERROR_REASON = 'RMA_SHIPPING_ERROR_REASON',
  /** Reason codes for an unknown RMA check-in. */
  RMA_UNKNOWN_RETURN_REASON = 'RMA_UNKNOWN_RETURN_REASON',
  /** Reason codes for an RMA warranty exchange. */
  RMA_WARRANTY_EXCHANGE_REASON = 'RMA_WARRANTY_EXCHANGE_REASON',
}

export type DynamicEnumMember = {
  __typename?: 'DynamicEnumMember';
  canDelete: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type EcommerceExperiment = {
  __typename?: 'EcommerceExperiment';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  variants: Array<EcommerceExperimentVariant>;
};

export type EcommerceExperimentEnrollment = {
  __typename?: 'EcommerceExperimentEnrollment';
  experiment: EcommerceExperiment;
  variant: EcommerceExperimentVariant;
};

export type EcommerceExperimentVariant = {
  __typename?: 'EcommerceExperimentVariant';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /**
   * Returns a signed URL that can be used to direct users to the shop with this
   * variant enabled.
   */
  redirectUrl: Scalars['String']['output'];
};

export type EventFeedbackResponse = {
  __typename?: 'EventFeedbackResponse';
  createdAt: Scalars['DateTime']['output'];
  feedback: Scalars['String']['output'];
  helpCenterLink: HelpCenterLink;
};

export type ExternalUser = {
  __typename?: 'ExternalUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePicture?: Maybe<Image>;
};

export type FalseBooleanSetting = BooleanSetting & {
  __typename?: 'FalseBooleanSetting';
  /**
   * DO NOT USE: This is required since GraphQL doesn't support empty types.
   * Use the __typename to determine the flag's boolean value.
   */
  emptyType?: Maybe<Scalars['Boolean']['output']>;
  platformOverrides: Array<BooleanSettingPlatformOverride>;
};

export type FeatureFlag = {
  accessedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dynamicSettingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FiFeed = {
  __typename?: 'FiFeed';
  feedItems: Array<FiFeedItem>;
  pageInfo: PageInfo;
};

export type FiFeedActivityItem = FiFeedItem & {
  __typename?: 'FiFeedActivityItem';
  activity: Activity;
  existingFeedback?: Maybe<EventFeedbackResponse>;
  feedbackOptions?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
};

export type FiFeedBirthdayCardItem = FiFeedItem & {
  __typename?: 'FiFeedBirthdayCardItem';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  petId: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

/** DEPRECATED: This type has been replaced by FiFeedLikableWalkItem */
export type FiFeedClappableWalkItem = FiFeedItem & {
  __typename?: 'FiFeedClappableWalkItem';
  followedPet: StrangerPet;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
  walk: StrangerWalk;
};

export type FiFeedDogParkVisitItem = FiFeedItem & {
  __typename?: 'FiFeedDogParkVisitItem';
  dogPark: DogPark;
  id: Scalars['ID']['output'];
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
};

export type FiFeedFollowedPetPhotoAddedItem = FiFeedItem & {
  __typename?: 'FiFeedFollowedPetPhotoAddedItem';
  followedPet: StrangerPet;
  id: Scalars['ID']['output'];
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type FiFeedGenericNotificationItem = FiFeedItem & {
  __typename?: 'FiFeedGenericNotificationItem';
  body: FormatString;
  existingFeedback?: Maybe<EventFeedbackResponse>;
  feedbackOptions?: Maybe<Array<Scalars['String']['output']>>;
  hideTimestamp: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  optionalPet?: Maybe<Pet>;
  timestamp: Scalars['DateTime']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type FiFeedGoalStreakItem = FiFeedItem & {
  __typename?: 'FiFeedGoalStreakItem';
  /** The number of bonus points associated with the current streak bonus */
  currentBonusPoints: Scalars['Int']['output'];
  daysToNextBonus: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  numDays: Scalars['Int']['output'];
  pet: Pet;
  /** Indicates if this streak event resulted in a bonus being unlocked */
  streakBonusUnlocked: Scalars['Boolean']['output'];
  subtitle: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type FiFeedItem = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type FiFeedLikableWalkItem = FiFeedItem & {
  __typename?: 'FiFeedLikableWalkItem';
  followedPet: StrangerPet;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
  walk: StrangerWalk;
};

export type FiFeedPetFollowedItem = FiFeedItem & {
  __typename?: 'FiFeedPetFollowedItem';
  followedPet: Pet;
  followingPet: StrangerPet;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type FiFeedPhotoAddedItem = FiFeedItem & {
  __typename?: 'FiFeedPhotoAddedItem';
  id: Scalars['ID']['output'];
  pet: Pet;
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
  user: User;
};

export type FiFeedPhotoLikedItem = FiFeedItem & {
  __typename?: 'FiFeedPhotoLikedItem';
  id: Scalars['ID']['output'];
  /** The pet who is liking the photo */
  likingPet: StrangerPet;
  numPhotosLiked: Scalars['Int']['output'];
  /** The pet whose photo is being liked */
  pet: Pet;
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type FiFeedRankingUpdateItem = FiFeedItem & {
  __typename?: 'FiFeedRankingUpdateItem';
  id: Scalars['ID']['output'];
  newBreedRank: Scalars['Int']['output'];
  newOverallRank: Scalars['Int']['output'];
  newOverallRankPercentile?: Maybe<Scalars['Int']['output']>;
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
};

export type FiNewsNotification = {
  __typename?: 'FiNewsNotification';
  /** Admin-only */
  dbId: Scalars['Int']['output'];
  featureImageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  publishedAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FiNewsNotificationFeed = {
  __typename?: 'FiNewsNotificationFeed';
  items: Array<FiNewsNotification>;
};

/** A statistic about firmware versions in the field. */
export type FirmwareDeploymentStatistics = {
  __typename?: 'FirmwareDeploymentStatistics';
  versionDistribution: Array<FirmwareVersionStatistic>;
};

export enum FirmwareReleaseClass {
  PROD = 'PROD',
  TEST = 'TEST',
}

export type FirmwareStatistics = {
  __typename?: 'FirmwareStatistics';
  /** The distribution of updateChannel values across this platform. */
  configuredDistribution: Array<FirmwareUpdateChannelStatistic>;
  /** The distribution of versions reported by recent devices. */
  deploymentStatistics: FirmwareDeploymentStatistics;
};

export type FirmwareUpdate = {
  __typename?: 'FirmwareUpdate';
  /**
   * Indicates that the client's firmware is out of date and they should download an update
   * from this URL.
   */
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

export type FirmwareUpdateChannel = {
  __typename?: 'FirmwareUpdateChannel';
  currentAppVersion?: Maybe<FirmwareVersion>;
  currentBootloaderVersion?: Maybe<FirmwareVersion>;
  currentVersion?: Maybe<FirmwareVersion>;
  name: Scalars['String']['output'];
  releaseClass: FirmwareReleaseClass;
  remoteLogEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** A statistic about how many devices are on a particular firmware channel and version. */
export type FirmwareUpdateChannelStatistic = {
  __typename?: 'FirmwareUpdateChannelStatistic';
  count: Scalars['Int']['output'];
  updateChannel: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export enum FirmwareUpdateType {
  APPLICATION = 'APPLICATION',
  BOOTLOADER = 'BOOTLOADER',
}

export type FirmwareVersion = {
  __typename?: 'FirmwareVersion';
  buildUrl?: Maybe<Scalars['String']['output']>;
  commit?: Maybe<Scalars['String']['output']>;
  compareUrl?: Maybe<Scalars['String']['output']>;
  compatiblePlatformId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  releaseClass: FirmwareReleaseClass;
  s3Suffix: Scalars['String']['output'];
  type: FirmwareUpdateType;
};

export type FirmwareVersionChangeDeviceEvent = DeviceEventBase & {
  __typename?: 'FirmwareVersionChangeDeviceEvent';
  date: Scalars['DateTime']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  newVersion?: Maybe<Scalars['String']['output']>;
  oldVersion?: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
};

/** A statistic about how many devices are reporting a particular firmware version. */
export type FirmwareVersionStatistic = {
  __typename?: 'FirmwareVersionStatistic';
  count: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

export enum FollowStatus {
  BLOCKED = 'BLOCKED',
  FOLLOWED = 'FOLLOWED',
  UNFOLLOWED = 'UNFOLLOWED',
}

export enum FontStyle {
  EMPHASIZED = 'EMPHASIZED',
}

export type ForModuleBundleCartItemInput = {
  cartItemId: Scalars['String']['input'];
  forModuleId: Scalars['String']['input'];
  lineItem: CartLineItemInput;
  quantity: Scalars['Int']['input'];
  subscriptionLineItem: CartLineItemInput;
};

export type ForModuleCartItemInput = {
  cartItemId: Scalars['String']['input'];
  forModuleId: Scalars['String']['input'];
  lineItem: CartLineItemInput;
  quantity: Scalars['Int']['input'];
};

export type FormatString = {
  __typename?: 'FormatString';
  spans: Array<Span>;
  text: Scalars['String']['output'];
};

export type ForwardGeocodeCityResult = {
  __typename?: 'ForwardGeocodeCityResult';
  city?: Maybe<Scalars['String']['output']>;
  cityState: Scalars['String']['output'];
  position: Position;
  state?: Maybe<Scalars['String']['output']>;
};

export type ForwardGeocodeResult = {
  __typename?: 'ForwardGeocodeResult';
  address: Scalars['String']['output'];
  cityState: Scalars['String']['output'];
  position: Position;
};

export enum FulfillmentProviderCode {
  DCL = 'DCL',
  TAGG = 'TAGG',
}

export type FulfillmentWarehouse = {
  __typename?: 'FulfillmentWarehouse';
  active: Scalars['Boolean']['output'];
  externalCode: Scalars['String']['output'];
  fulfillmentProviderCode: FulfillmentProviderCode;
  id: Scalars['ID']['output'];
  metadata?: Maybe<FulfillmentWarehouseMetadata>;
  name: Scalars['String']['output'];
};

export type FulfillmentWarehouseMetadata = {
  __typename?: 'FulfillmentWarehouseMetadata';
  b2bAccountNumber?: Maybe<Scalars['String']['output']>;
  d2cAccountNumber?: Maybe<Scalars['String']['output']>;
  includeDangerousGoodsSticker?: Maybe<Scalars['Boolean']['output']>;
  inventoryTypes: Array<Scalars['String']['output']>;
  maxGoodsValuePerShipmentInDollars?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentWarehouseMetadataInput = {
  /** b2bAccountNumber is included with orders when submitted to the warehouse. B2B === Business to Business. */
  b2bAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /** d2cAccountNumber is included with orders when submitted to the warehouse. D2C === Direct to Consumer. */
  d2cAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /**
   * When true, for any orders that are shipped from this warehouse, the backend will explicitly include a dangerous
   * goods sticker as a line item in the shipment when the number of dangerous goods exceeds the appropriate threshold.
   */
  includeDangerousGoodsSticker?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The types of inventory that are stored at this warehouse. Used when fetching inventory from the 3PL
   * to match results with our fulfillment warehouse records.
   */
  inventoryTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * If defined, we must split the order into multiple shipments when the order contains items that are valued
   * over the specified amount
   */
  maxGoodsValuePerShipmentInDollars?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The timezone of the warehouse. Warehouse data is usually reported in their local time without specifying
   * a timezone so we use this to convert times to UTC.
   */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type FullAdminUserProfile = AdminBaseUserProfile & {
  __typename?: 'FullAdminUserProfile';
  automaticallyActivateDevices: Scalars['Boolean']['output'];
  billingAccount?: Maybe<BillingAccount>;
  chipReseller?: Maybe<ChipReseller>;
  dbId: Scalars['Int']['output'];
  deactivated: Scalars['Boolean']['output'];
  ecommerceExperimentEnrollments: Array<EcommerceExperimentEnrollment>;
  ecommerceOrders: Array<Order>;
  email: Scalars['String']['output'];
  emails: Array<UserEmail>;
  fiNewsNotificationsEnabled: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  groups: Array<UserGroup>;
  handsets: Array<Handset>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  latestLocation?: Maybe<Location>;
  note?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  referrals: Referrals;
  settings: UserSettings;
  socialBanned: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
  userHouseholds: Array<UserHousehold>;
};

export type FullAdminUserProfilegroupsArgs = {
  excludePseudo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FullAdminUserProfilehandsetsArgs = {
  status?: InputMaybe<Array<HandsetStatus>>;
};

export type GPSTestCompleteResult = {
  __typename?: 'GPSTestCompleteResult';
  report: DeviceDiagnosticLocationReport;
};

/** Connection was stolen by a charging base or another mobile user. */
export type GPSTestConnectionStolenResult = {
  __typename?: 'GPSTestConnectionStolenResult';
  /**
   * Connection state of the collar which can be used to determine if it was another user
   * or a base that stole the connection.
   */
  state: ConnectionState;
};

export type GPSTestResult = GPSTestCompleteResult | GPSTestConnectionStolenResult;

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export type GetPetDocumentsInput = {
  householdId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type GiftCardDeliveryInput = {
  deliverAt?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gifterName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  personalMessage?: InputMaybe<Scalars['String']['input']>;
};

export type Give1MonthGet1DollarPerMonthDetails = {
  __typename?: 'Give1MonthGet1DollarPerMonthDetails';
  numActiveReferrals: Scalars['Int']['output'];
  totalDollarsEarned: Scalars['Float']['output'];
};

export type GnssFixFailure = {
  __typename?: 'GnssFixFailure';
  gnssTimeToFixInSeconds: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GroupsBooleanSetting = BooleanSetting & {
  __typename?: 'GroupsBooleanSetting';
  groups: Array<Scalars['String']['output']>;
  platformOverrides: Array<BooleanSettingPlatformOverride>;
};

export type Handset = {
  __typename?: 'Handset';
  /** Admin only */
  client?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dbId: Scalars['Int']['output'];
  /** Admin only */
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Admin only */
  deviceToken: Scalars['String']['output'];
  /** Admin only */
  endpointArn: Scalars['String']['output'];
  fromImpersonation?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  platform: HandsetPlatform;
  updatedAt: Scalars['DateTime']['output'];
};

export type HandsetLogClient = {
  __typename?: 'HandsetLogClient';
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceModel?: Maybe<Scalars['String']['output']>;
  osVersion?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type HandsetLogDocument = {
  __typename?: 'HandsetLogDocument';
  assignable?: Maybe<Scalars['Boolean']['output']>;
  authCode?: Maybe<Scalars['Int']['output']>;
  baseId?: Maybe<Scalars['String']['output']>;
  client?: Maybe<HandsetLogClient>;
  logLevel?: Maybe<Scalars['String']['output']>;
  logType?: Maybe<HandsetLogType>;
  logdate: Scalars['DateTime']['output'];
  moduleId?: Maybe<Scalars['String']['output']>;
  msg: Scalars['String']['output'];
  networkName?: Maybe<Scalars['String']['output']>;
  peripheralInfo?: Maybe<HandsetLogPeripheralInfo>;
  results?: Maybe<Array<WifiScanResult>>;
  rssi?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type HandsetLogPeripheralInfo = {
  __typename?: 'HandsetLogPeripheralInfo';
  peripheralType?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['String']['output']>;
};

export enum HandsetLogType {
  ADD_WIFI_CREDENTIALS_ERROR = 'ADD_WIFI_CREDENTIALS_ERROR',
  ADD_WIFI_CREDENTIALS_SUCCESS = 'ADD_WIFI_CREDENTIALS_SUCCESS',
  APP_LAUNCH = 'APP_LAUNCH',
  ASSIGNABILITY_RESULT = 'ASSIGNABILITY_RESULT',
  BASE_ASSIGNMENT_FAILURE = 'BASE_ASSIGNMENT_FAILURE',
  BASE_ASSIGNMENT_SUCCESS = 'BASE_ASSIGNMENT_SUCCESS',
  BASE_WIFI_AUTH_ERROR = 'BASE_WIFI_AUTH_ERROR',
  BASE_WIFI_CONNECT_SUCCESS = 'BASE_WIFI_CONNECT_SUCCESS',
  BASE_WIFI_SCAN_RESULTS = 'BASE_WIFI_SCAN_RESULTS',
  BLE_CONNECTED = 'BLE_CONNECTED',
  BLE_DISCONNECT = 'BLE_DISCONNECT',
  BLE_ERROR = 'BLE_ERROR',
  BLE_OPERATION = 'BLE_OPERATION',
  BLE_READ_INFO = 'BLE_READ_INFO',
  BLE_SCAN_RESULT = 'BLE_SCAN_RESULT',
  BLE_START_CONNECT = 'BLE_START_CONNECT',
  BLE_STATE_SNAPSHOT = 'BLE_STATE_SNAPSHOT',
  CREDENTIAL_PACK_QUERY_ERROR = 'CREDENTIAL_PACK_QUERY_ERROR',
  CREDENTIAL_PACK_QUERY_SUCCESS = 'CREDENTIAL_PACK_QUERY_SUCCESS',
  MODULE_ASSIGNMENT_FAILURE = 'MODULE_ASSIGNMENT_FAILURE',
  MODULE_ASSIGNMENT_SUCCESS = 'MODULE_ASSIGNMENT_SUCCESS',
  READ_BASE_DIAGNOSTIC = 'READ_BASE_DIAGNOSTIC',
  READ_CREDENTIAL_PACK_CHARACTERISTIC_SUCCESS = 'READ_CREDENTIAL_PACK_CHARACTERISTIC_SUCCESS',
  SELECTED_ENTER_MANUAL_WIFI_CREDENTIALS = 'SELECTED_ENTER_MANUAL_WIFI_CREDENTIALS',
  SELECTED_WIFI_NETWORK = 'SELECTED_WIFI_NETWORK',
  STARTING_BASE_ASSIGNMENT = 'STARTING_BASE_ASSIGNMENT',
  STARTING_BASE_CONFIGURATION = 'STARTING_BASE_CONFIGURATION',
  STARTING_BASE_WIFI_SCAN = 'STARTING_BASE_WIFI_SCAN',
  STARTING_MODULE_ASSIGNMENT = 'STARTING_MODULE_ASSIGNMENT',
  STARTING_MODULE_WIFI_CONFIGURATION = 'STARTING_MODULE_WIFI_CONFIGURATION',
  STARTING_WIFI_CONFIGURATION = 'STARTING_WIFI_CONFIGURATION',
  STARTING_WIFI_SCAN = 'STARTING_WIFI_SCAN',
  SYSTEM_METRIC = 'SYSTEM_METRIC',
  USER_NOTIFICATION = 'USER_NOTIFICATION',
  WIFI_AUTH_ERROR = 'WIFI_AUTH_ERROR',
  WIFI_CONNECT_SUCCESS = 'WIFI_CONNECT_SUCCESS',
  WIFI_SCAN_RESULTS = 'WIFI_SCAN_RESULTS',
  WRITE_CREDENTIAL_PACK_CHARACTERISTIC_SUCCESS = 'WRITE_CREDENTIAL_PACK_CHARACTERISTIC_SUCCESS',
}

export enum HandsetPlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export enum HandsetStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  FROM_IMPERSONATION = 'FROM_IMPERSONATION',
}

export type HardwareDefect = {
  __typename?: 'HardwareDefect';
  createdBy?: Maybe<Scalars['String']['output']>;
  defect: DynamicEnumMember;
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceSource?: Maybe<InvoiceSource>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
};

/** Represents current state based on current/past collar reporting: */
export type HasReportsState = PetCollarState & {
  __typename?: 'HasReportsState';
  /** Represents collar-specific state (i.e. online/offline) */
  collarStatus: CollarStatus;
  /** Current Lost Dog Mode state. */
  ldmState: CollarLostDogModeState;
  /** Represents the latest activity at the time */
  ongoingActivity: OngoingActivity;
  timestamp: Scalars['DateTime']['output'];
};

export type HelpCenterLink = {
  __typename?: 'HelpCenterLink';
  displayText: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HourlySleepBar = {
  __typename?: 'HourlySleepBar';
  /** The number of minutes the pet was resting within this hour. */
  minutesResting: Scalars['Int']['output'];
  /** Percent of time period pet was resting (0-1). */
  percentResting: Scalars['Float']['output'];
  /** Start of the period of sleep (note end = this + 1 hour). */
  start: Scalars['DateTime']['output'];
  type: SleepType;
};

export type Household = {
  __typename?: 'Household';
  bases: Array<ChargingBase>;
  dbId: Scalars['Int']['output'];
  following: StrangerPetFeed;
  id: Scalars['ID']['output'];
  invites: Array<UserInvite>;
  pets: Array<Pet>;
  places: Array<Place>;
  socialOptedOut: Scalars['Boolean']['output'];
  suggestedVets: Array<Veterinary>;
  users: Array<HouseholdUser>;
  vets: Array<Veterinary>;
  wifiNetworks: WifiNetworks;
};

export type HouseholdfollowingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type HouseholdinvitesArgs = {
  state?: InputMaybe<InviteState>;
};

export type HouseholdpetsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HouseholdusersArgs = {
  role?: InputMaybe<UserHouseholdRole>;
};

export type HouseholdInvite = {
  __typename?: 'HouseholdInvite';
  role: UserHouseholdRole;
};

export type HouseholdInviteInput = {
  householdId: Scalars['ID']['input'];
  role: UserHouseholdRole;
};

export type HouseholdUser = {
  __typename?: 'HouseholdUser';
  adminUnderlyingUser: User;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** @deprecated Use id2 for a unique id or userId for the User.id */
  id: Scalars['ID']['output'];
  /** id was returning the User.id before which was not unique across HouseholdUsers, this is a unique ID */
  id2: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role: UserHouseholdRole;
  userId: Scalars['ID']['output'];
};

export type Image = {
  __typename?: 'Image';
  fullSize?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field is a duplicate of `fullSize` and only adds to confusion on client-side. */
  id: Scalars['ID']['output'];
  /**
   * Returns a URL that is a best effort to resize the image to the specified dimensions.
   * The image may not be able to be resized, and the dimensions may be snapped to nearby
   * prespecified dimensions. Clients should still resize the image as they see fit when
   * they get it.
   */
  nearestResized: Scalars['String']['output'];
};

export type ImagenearestResizedArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type InAppPromotionBanner = {
  id: Scalars['ID']['output'];
};

export type InPersonEvent = {
  __typename?: 'InPersonEvent';
  closed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  startingInventory: Scalars['JSON']['output'];
  status: InPersonEventStatus;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

export type InPersonEventQuery = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum InPersonEventStatus {
  PAST = 'PAST',
  PLANNED = 'PLANNED',
}

export enum IncentiveType {
  BASE = 'BASE',
  EVENING_WALK = 'EVENING_WALK',
  MORNING_WALK = 'MORNING_WALK',
}

export type InsightLeadingImage = {
  __typename?: 'InsightLeadingImage';
  displayHeight: Scalars['Int']['output'];
  displayWidth: Scalars['Int']['output'];
  image: Image;
};

export type InstagramMediaFeed = {
  __typename?: 'InstagramMediaFeed';
  items: Array<InstagramMediaItem>;
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type InstagramMediaItem = {
  __typename?: 'InstagramMediaItem';
  id: Scalars['ID']['output'];
  mediaUrl: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type InstagramUser = {
  __typename?: 'InstagramUser';
  mediaFeed: InstagramMediaFeed;
};

export type InstagramUsermediaFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
};

export type InvalidToken = {
  __typename?: 'InvalidToken';
  /** GraphQL doesn't allow empty types; this is a bogus field that will always be null. */
  invalid?: Maybe<Scalars['Boolean']['output']>;
};

export type InventoryConfiguration = {
  __typename?: 'InventoryConfiguration';
  /** whether this warehouse should be allowed to fulfill this sku */
  allowFulfillment: Scalars['Boolean']['output'];
  /** whether just-in-time kitting is eligible for this sku at this warehouse */
  eligibleForJustInTimeKitting: Scalars['Boolean']['output'];
  fulfillmentWarehouse: FulfillmentWarehouse;
  id: Scalars['ID']['output'];
  /** whether just-in-time kitting is enabled for this sku at this warehouse */
  justInTimeKittingEnabled: Scalars['Boolean']['output'];
  sku: Scalars['String']['output'];
};

export enum InviteState {
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  OUTSTANDING = 'OUTSTANDING',
  REVOKED = 'REVOKED',
}

export type InviteUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Can consider making this nullable if we support other types of invites. */
  householdInvite: HouseholdInviteInput;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  suppressSms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InviteUserToHouseholdResult = {
  __typename?: 'InviteUserToHouseholdResult';
  household: Household;
  inviteMessage: Scalars['String']['output'];
  inviteUrl: Scalars['String']['output'];
  /**
   * If a user account exists with that phone number, that user is immediately
   * added to the household and returned here.
   */
  user?: Maybe<HouseholdUser>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
  /**
   * This technically shouldn't be part of Invoice but we need to return it from createPurchase.
   * It'd be better if createPurchase returned its own specific CreatePurchaseResult, but
   * holding off on that migration for now.
   */
  isReturningCustomer: Scalars['Boolean']['output'];
};

export enum InvoiceSource {
  CHEWY = 'CHEWY',
  RECURLY = 'RECURLY',
}

export type Label = {
  __typename?: 'Label';
  subtext?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type LabeledCard = Card & {
  __typename?: 'LabeledCard';
  date: Scalars['DateTime']['output'];
  icon?: Maybe<Image>;
  id: Scalars['ID']['output'];
  labels: Array<Label>;
  message: FormatString;
  title?: Maybe<Scalars['String']['output']>;
};

export type LedColor = {
  __typename?: 'LedColor';
  hexCode: Scalars['String']['output'];
  ledColorCode: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LimitedAdminUserProfile = AdminBaseUserProfile & {
  __typename?: 'LimitedAdminUserProfile';
  automaticallyActivateDevices: Scalars['Boolean']['output'];
  billingAccount?: Maybe<BillingAccount>;
  chipReseller?: Maybe<ChipReseller>;
  dbId: Scalars['Int']['output'];
  deactivated: Scalars['Boolean']['output'];
  ecommerceExperimentEnrollments: Array<EcommerceExperimentEnrollment>;
  ecommerceOrders: Array<Order>;
  email: Scalars['String']['output'];
  emails: Array<UserEmail>;
  fiNewsNotificationsEnabled: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  groups: Array<UserGroup>;
  handsets: Array<Handset>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  referrals: Referrals;
  settings: UserSettings;
  socialBanned: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
  userHouseholds: Array<UserHousehold>;
};

export type LimitedAdminUserProfilegroupsArgs = {
  excludePseudo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LimitedAdminUserProfilehandsetsArgs = {
  status?: InputMaybe<Array<HandsetStatus>>;
};

export type LineItem = {
  __typename?: 'LineItem';
  account: BillingAccountMini;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
  isPhysicalProduct: Scalars['Boolean']['output'];
  orderStatus?: Maybe<OrderLineItemStatus>;
  productCode: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  returned: Scalars['Boolean']['output'];
  /** Look up serial numbers associated with this line item using Tagg shipping information. */
  serialNumbers: Array<Scalars['String']['output']>;
  /**
   * When invoice line item is for a physical product subscription (e.g. supplements), skuToShip will be the
   * sku of the physical product that needs to be shipped.
   */
  skuToShip: Scalars['String']['output'];
  subItems: Array<LineSubItem>;
  subtotal: Scalars['Float']['output'];
};

export type LineItemInput = {
  /**
   * If the item being purchased is a SubscriptionBundle, the `id` will be the SKU for the
   * bundle and this field should be set to the SKU of the bundled product being purchased
   * (e.g. collar variant).
   */
  bundledProductSku?: InputMaybe<Scalars['String']['input']>;
  /** Extra data for a gift card line item, about to whom it is going to be delivered. */
  giftCardDelivery?: InputMaybe<GiftCardDeliveryInput>;
  id: Scalars['ID']['input'];
  /**
   * A module ID associated with this line item in some way. Affects different types
   * of products differently. If the product is a subscription, the `moduleId` will cause
   * the plan to be immediately associated with the specified module.
   */
  moduleId?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type LineSubItem = {
  __typename?: 'LineSubItem';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  /** Altitude in meters */
  altitude?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTime']['output'];
  /** Accuracy radius in meters */
  errorRadius?: Maybe<Scalars['Float']['output']>;
  /** Value from 0-360 where 0 represents true north */
  heading?: Maybe<Scalars['Float']['output']>;
  /** `Place` in which this `Location` is contained. */
  place?: Maybe<Place>;
  position: Position;
};

/**
 * A single location report from a device
 * !! IMPORTANT: This should never be exposed to or used by non-admin clients,
 * since it has a monotonically increasing ID (not a UUID).
 */
export type LocationReport = {
  __typename?: 'LocationReport';
  accuracyRadius: Scalars['Float']['output'];
  altitude?: Maybe<Scalars['Float']['output']>;
  attributes: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  detectedActivityType?: Maybe<DetectedActivityType>;
  device: Device;
  id: Scalars['ID']['output'];
  location: Location;
  /** Places (safe-zones) this location report overlaps with. */
  overlappingPlaces: Array<Place>;
  /** Points of interest (e.g. dog parks) this location report overlaps with. */
  pois?: Maybe<Array<DogPark>>;
  position: Position;
  reporter?: Maybe<User>;
  source: Scalars['String']['output'];
};

export type LocationReportInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  /** DEPRECATED: Use `connectedWifiInput` instead */
  connectedToSsid?: InputMaybe<Scalars['String']['input']>;
  connectedWifiInput?: InputMaybe<ConnectedWifiInput>;
  date: Scalars['DateTime']['input'];
  horizontalAccuracy: Scalars['Float']['input'];
  params?: InputMaybe<Scalars['JSON']['input']>;
  position: PositionInput;
  recentlySeenBases?: InputMaybe<Array<RecentlySeenBaseInput>>;
  /** Optional, defaults to "mobile */
  source?: InputMaybe<Scalars['String']['input']>;
  verticalAccuracy?: InputMaybe<Scalars['Float']['input']>;
};

export enum LogLevel {
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  INFO = 'INFO',
  VERBOSE = 'VERBOSE',
  WARNING = 'WARNING',
}

export type LowLTESignalDeviceInfo = {
  __typename?: 'LowLTESignalDeviceInfo';
  rsrq: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type MapMatchedPath = {
  __typename?: 'MapMatchedPath';
  path: Array<Position>;
};

export type MapPath = MapMatchedPath | UnmatchedPath;

export enum MapStyle {
  ACTIVITY_CARD = 'ACTIVITY_CARD',
  LEGACY = 'LEGACY',
}

export type MigrateModulesToFirmwareChannelResult = {
  __typename?: 'MigrateModulesToFirmwareChannelResult';
  /** The number of records that were updated. */
  numUpdated: Scalars['Int']['output'];
};

export type MissedLocationReport = {
  __typename?: 'MissedLocationReport';
  numberOfSecondsBehind: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type MissingReports = Activity & {
  __typename?: 'MissingReports';
  address?: Maybe<Scalars['String']['output']>;
  areaName?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  dbId: Scalars['Int']['output'];
  debugMetadata: Scalars['JSON']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use isLikable */
  isClappable: Scalars['Boolean']['output'];
  isLikable: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type MobileAppBanner = MobileAppBannerInterface & {
  __typename?: 'MobileAppBanner';
  backgroundColorHex: Scalars['HexColorCode']['output'];
  description: Scalars['String']['output'];
  destinationUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  leadingImage?: Maybe<MobileAppBannerImage>;
  title: Scalars['String']['output'];
};

export type MobileAppBannerImage = {
  __typename?: 'MobileAppBannerImage';
  displayHeight: Scalars['Int']['output'];
  displayWidth: Scalars['Int']['output'];
  image: Image;
};

export type MobileAppBannerImageInput = {
  displayHeight: Scalars['Int']['input'];
  displayWidth: Scalars['Int']['input'];
  url: Scalars['String']['input'];
};

export type MobileAppBannerInterface = {
  backgroundColorHex: Scalars['HexColorCode']['output'];
  description: Scalars['String']['output'];
  destinationUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  leadingImage?: Maybe<MobileAppBannerImage>;
  title: Scalars['String']['output'];
};

export type MobileAppBottomSheet = MobileAppBottomSheetInterface & {
  __typename?: 'MobileAppBottomSheet';
  buttons: Array<MobileAppBottomSheetButton>;
  description: Scalars['String']['output'];
  formattedDescription: FormatString;
  formattedTitle: FormatString;
  id: Scalars['ID']['output'];
  image?: Maybe<MobileAppBottomSheetImage>;
  title: Scalars['String']['output'];
};

export type MobileAppBottomSheetButton = MobileAppBottomSheetCTAButton | MobileAppBottomSheetTextLinkButton;

export type MobileAppBottomSheetButtonInterface = {
  destinationUrl: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export enum MobileAppBottomSheetButtonType {
  CTA = 'CTA',
  TEXT_LINK = 'TEXT_LINK',
}

export type MobileAppBottomSheetCTAButton = MobileAppBottomSheetButtonInterface & {
  __typename?: 'MobileAppBottomSheetCTAButton';
  backgroundColorHex: Scalars['HexColorCode']['output'];
  destinationUrl: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MobileAppBottomSheetImage = {
  __typename?: 'MobileAppBottomSheetImage';
  image: Image;
  type: MobileAppBottomSheetImageType;
};

export enum MobileAppBottomSheetImageType {
  RECTANGULAR = 'RECTANGULAR',
  ROUND = 'ROUND',
  SQUARE = 'SQUARE',
}

export type MobileAppBottomSheetInterface = {
  buttons: Array<MobileAppBottomSheetButton>;
  description: Scalars['String']['output'];
  formattedDescription: FormatString;
  formattedTitle: FormatString;
  id: Scalars['ID']['output'];
  image?: Maybe<MobileAppBottomSheetImage>;
  title: Scalars['String']['output'];
};

export type MobileAppBottomSheetTextLinkButton = MobileAppBottomSheetButtonInterface & {
  __typename?: 'MobileAppBottomSheetTextLinkButton';
  destinationUrl: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MobileAppContent = {
  __typename?: 'MobileAppContent';
  banners: Array<MobileAppBanner>;
  bottomSheet?: Maybe<MobileAppBottomSheet>;
  discover: Array<MobileAppDiscoverItem>;
  splashScreen?: Maybe<MobileAppSplashScreen>;
};

export type MobileAppContentTargetingFact = {
  __typename?: 'MobileAppContentTargetingFact';
  name: Scalars['String']['output'];
  type: MobileAppContentTargetingFactType;
};

export enum MobileAppContentTargetingFactType {
  ARRAY_OF_STRINGS = 'ARRAY_OF_STRINGS',
  BOOLEAN = 'BOOLEAN',
  DATE_TIME = 'DATE_TIME',
  INTEGER = 'INTEGER',
  STRING = 'STRING',
}

export type MobileAppContentTargetingInput = {
  dismissalDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  requiresTrigger?: InputMaybe<Scalars['Boolean']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * JSON converted `json-rules-engine` rules.
   * No targeting rules will result in content being visible to any user/pet
   * See https://github.com/CacheControl/json-rules-engine/blob/master/docs/rules.md#persisting
   */
  targetingRules?: InputMaybe<Scalars['JSON']['input']>;
};

export type MobileAppDiscoverItem = MobileAppDiscoverItemInterface & {
  __typename?: 'MobileAppDiscoverItem';
  description: Scalars['String']['output'];
  destinationUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Image;
  title: Scalars['String']['output'];
};

export type MobileAppDiscoverItemInterface = {
  description: Scalars['String']['output'];
  destinationUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Image;
  title: Scalars['String']['output'];
};

export type MobileAppSplashScreen = MobileAppSplashScreenInterface & {
  __typename?: 'MobileAppSplashScreen';
  id: Scalars['ID']['output'];
  pages: Array<MobileAppSplashScreenPage>;
};

export type MobileAppSplashScreenButton = MobileAppSplashScreenNextButton | MobileAppSplashScreenUrlButton;

export type MobileAppSplashScreenButtonInterface = {
  text: Scalars['String']['output'];
};

export type MobileAppSplashScreenInterface = {
  id: Scalars['ID']['output'];
  pages: Array<MobileAppSplashScreenPage>;
};

export type MobileAppSplashScreenNextButton = MobileAppSplashScreenButtonInterface & {
  __typename?: 'MobileAppSplashScreenNextButton';
  text: Scalars['String']['output'];
};

export type MobileAppSplashScreenPage = {
  __typename?: 'MobileAppSplashScreenPage';
  button: MobileAppSplashScreenButton;
  description: Scalars['String']['output'];
  image: Image;
  title: Scalars['String']['output'];
};

export type MobileAppSplashScreenUrlButton = MobileAppSplashScreenButtonInterface & {
  __typename?: 'MobileAppSplashScreenUrlButton';
  destinationUrl: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MobilityReport = {
  __typename?: 'MobilityReport';
  attributes: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  device: Device;
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  type: MobilityType;
};

export type MobilityReportInput = {
  date: Scalars['DateTime']['input'];
  recentlySeenBases?: InputMaybe<Array<RecentlySeenBaseInput>>;
  /** Optional, defaults to "mobile */
  source?: InputMaybe<Scalars['String']['input']>;
  type: MobilityType;
};

export enum MobilityType {
  NO_SIGNIFICANT_CHANGE = 'NO_SIGNIFICANT_CHANGE',
  UNKNOWN = 'UNKNOWN',
}

export type ModerationCommentArticleSubject = {
  __typename?: 'ModerationCommentArticleSubject';
  article: Article;
};

export type ModerationCommentFeed = {
  __typename?: 'ModerationCommentFeed';
  items: Array<ModerationCommentItem>;
};

export type ModerationCommentItem = ModerationItem & {
  __typename?: 'ModerationCommentItem';
  comment: Comment;
  commentHistory: Array<AuditLogEvent>;
  complaintCount: Scalars['Int']['output'];
  complaintCountSinceLastModerationAction: Scalars['Int']['output'];
  lastModerationAction?: Maybe<AuditLogEvent>;
  pet?: Maybe<Pet>;
  subject: ModerationSubject;
  user?: Maybe<User>;
};

export type ModerationCommentPhotoSubject = {
  __typename?: 'ModerationCommentPhotoSubject';
  photo: Photo;
};

export type ModerationCommentTextPostSubject = {
  __typename?: 'ModerationCommentTextPostSubject';
  textPost: TextPost;
};

export type ModerationFeed = {
  items: Array<ModerationItem>;
  totalCount: Scalars['Int']['output'];
};

export type ModerationFeedQueryInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isShowingComplainedContentOnly?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['ID']['input']>;
  posterPetId?: InputMaybe<Scalars['ID']['input']>;
  posterPetName?: InputMaybe<Scalars['String']['input']>;
  posterUserId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  textPostContent?: InputMaybe<Scalars['String']['input']>;
  textPostId?: InputMaybe<Scalars['ID']['input']>;
};

export type ModerationItem = {
  complaintCount: Scalars['Int']['output'];
  complaintCountSinceLastModerationAction: Scalars['Int']['output'];
  lastModerationAction?: Maybe<AuditLogEvent>;
  pet?: Maybe<Pet>;
  subject: ModerationSubject;
  user?: Maybe<User>;
};

export type ModerationPhotoFromCommentItem = {
  __typename?: 'ModerationPhotoFromCommentItem';
  moderationCommentFeed: ModerationCommentFeed;
  photo: Photo;
  user?: Maybe<User>;
};

export type ModerationPhotoItem = ModerationItem & {
  __typename?: 'ModerationPhotoItem';
  commentHistory: Array<AuditLogEvent>;
  complaintCount: Scalars['Int']['output'];
  complaintCountSinceLastModerationAction: Scalars['Int']['output'];
  lastModerationAction?: Maybe<AuditLogEvent>;
  pet?: Maybe<Pet>;
  photo: Photo;
  subject: ModerationSubject;
  user?: Maybe<User>;
};

export type ModerationSubject =
  | ModerationCommentArticleSubject
  | ModerationCommentPhotoSubject
  | ModerationCommentTextPostSubject;

export type ModerationTextPostFromCommentItem = {
  __typename?: 'ModerationTextPostFromCommentItem';
  moderationCommentFeed: ModerationCommentFeed;
  textPost: TextPost;
  user?: Maybe<User>;
};

export type ModerationTextPostItem = ModerationItem & {
  __typename?: 'ModerationTextPostItem';
  commentHistory: Array<AuditLogEvent>;
  complaintCount: Scalars['Int']['output'];
  complaintCountSinceLastModerationAction: Scalars['Int']['output'];
  lastModerationAction?: Maybe<AuditLogEvent>;
  pet?: Maybe<Pet>;
  subject: ModerationSubject;
  textPost: TextPost;
  user?: Maybe<User>;
};

export type ModifyWifiCredentialResult = {
  __typename?: 'ModifyWifiCredentialResult';
  hash: Scalars['String']['output'];
};

export enum ModuleCapability {
  BQ27421_SUBMESSAGE = 'BQ27421_SUBMESSAGE',
  LIVE_TRACKING = 'LIVE_TRACKING',
  LLE_UPDATE_IN_RESPONSE = 'LLE_UPDATE_IN_RESPONSE',
  PROTO_RESPONSE = 'PROTO_RESPONSE',
  REMOTE_LOG = 'REMOTE_LOG',
  REMOTE_REBOOT = 'REMOTE_REBOOT',
  SLEEP_TRACKING = 'SLEEP_TRACKING',
  USER_LED = 'USER_LED',
  WIFI = 'WIFI',
}

export type ModuleHSMTransitionDetails = {
  __typename?: 'ModuleHSMTransitionDetails';
  eventName: Scalars['String']['output'];
  fromStateName: Scalars['String']['output'];
  toStateName: Scalars['String']['output'];
};

export type MultidogHouseholdPromotionBanner = InAppPromotionBanner & {
  __typename?: 'MultidogHouseholdPromotionBanner';
  ctaUrl: Scalars['String']['output'];
  dismissFeedbackOptions?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  line1Text: FormatString;
  line2Text: FormatString;
};

export type MultipleModerationFeed = {
  __typename?: 'MultipleModerationFeed';
  commentFeed: CommentModerationFeed;
  photoFeed: PhotoModerationFeed;
  textPostFeed: TextPostModerationFeed;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptUserInvite: AcceptUserInviteResult;
  addCommentComplaint?: Maybe<Scalars['Boolean']['output']>;
  addHouseholdVet: Array<Veterinary>;
  addOrderLineItems: Order;
  addPetDocument: PetDocument;
  /** @deprecated Use addPhotoForPet instead */
  addPetPhoto: Pet;
  addPhotoComment?: Maybe<AddPhotoCommentResult>;
  addPhotoComplaint?: Maybe<Scalars['Boolean']['output']>;
  addPhotoForPet: Photo;
  /** In-app supplements management mutations. */
  addSupplementSubscription?: Maybe<AddSupplementSubscriptionResult>;
  addTextPostComment?: Maybe<AddTextPostCommentResult>;
  addTextPostComplaint?: Maybe<Scalars['Boolean']['output']>;
  addUserEmail: User;
  addVideoPostComment?: Maybe<AddVideoPostCommentResult>;
  addVideoPostComplaint?: Maybe<Scalars['Boolean']['output']>;
  addWifiCredentials: ModifyWifiCredentialResult;
  adminActivateFulfillmentWarehouse: FulfillmentWarehouse;
  adminAddItemToList: AdminAddItemToListResult;
  adminAddResellerModules?: Maybe<Array<ResellerModule>>;
  adminAddUserToGroup?: Maybe<AdminBaseUserProfile>;
  adminAddUserToHousehold: AddUserToHouseholdResult;
  adminAddVetSuggestion: Array<Veterinary>;
  adminBulkAssignUsersToGroup: Scalars['ID']['output'];
  adminClaimNanoWebIds: Array<NanoWebId>;
  adminClearChip?: Maybe<Scalars['Boolean']['output']>;
  adminCloseInPersonEvent: InPersonEvent;
  adminCreateBatchRecords?: Maybe<Scalars['Boolean']['output']>;
  adminCreateBreed: Breed;
  adminCreateFirmwareChannel?: Maybe<FirmwareUpdateChannel>;
  adminCreateInPersonEvent: InPersonEvent;
  adminCreateOrder: CartPurchaseResult;
  adminCreatePlace?: Maybe<Place>;
  adminCreatePlatform?: Maybe<Platform>;
  adminCreatePromotion: Promotion;
  adminCreateUserGroup?: Maybe<UserGroup>;
  adminDeactivateFulfillmentWarehouse: FulfillmentWarehouse;
  /**
   * Deactivates user, removes them from households, anonymizes their information.
   *
   * This is an irrevocable, destructive operation.
   */
  adminDeactivateUser?: Maybe<User>;
  adminDeleteFirmware?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Deletes a firmware update channel.  This is a "safe" operation as only an unused
   * channel can be deleted.  If there are devices assigned to the channel, the operation
   * will fail.
   */
  adminDeleteFirmwareChannel?: Maybe<Scalars['Boolean']['output']>;
  adminDeletePromotion?: Maybe<Scalars['Boolean']['output']>;
  adminDeleteUserGroup?: Maybe<Scalars['Boolean']['output']>;
  adminDisableShelterluv: ChipReseller;
  /** Create a copy of a target promotion */
  adminDuplicatePromotion: Promotion;
  adminEnableShelterluv: ChipReseller;
  adminImportChips?: Maybe<Array<ResellerChip>>;
  adminImportPartnerOrders: Scalars['Int']['output'];
  adminIncrementNumConfirmedReferrals?: Maybe<AdminBaseUserProfile>;
  adminMigrateModulesToFirmwareChannel?: Maybe<MigrateModulesToFirmwareChannelResult>;
  adminPublishTestPetUpdate?: Maybe<Scalars['Boolean']['output']>;
  adminReinstateStreak?: Maybe<Scalars['Boolean']['output']>;
  adminRemoveFeatureFlag?: Maybe<Scalars['Boolean']['output']>;
  adminRemoveItemFromList: AdminList;
  adminRemoveUserFromGroup?: Maybe<AdminBaseUserProfile>;
  adminSendBackgroundNotification?: Maybe<Scalars['Boolean']['output']>;
  adminSendFreeNanoBoxes: CartPurchaseResult;
  adminSendNotification?: Maybe<Scalars['Boolean']['output']>;
  adminSendScreenSharingNotification?: Maybe<Scalars['Boolean']['output']>;
  adminSendServerPushToDevice?: Maybe<Scalars['Boolean']['output']>;
  adminSetAutomaticallyActivateDevicesOnUser?: Maybe<AdminBaseUserProfile>;
  adminSetChargingBaseOwner?: Maybe<ChargingBase>;
  adminSetChargingBaseUpdateChannel?: Maybe<ChargingBase>;
  adminSetChipResellerName: ChipReseller;
  adminSetChipResellerStatus: ChipReseller;
  adminSetCommentHidden?: Maybe<Comment>;
  adminSetDeviceAutoAddUserToHousehold?: Maybe<Device>;
  adminSetDeviceIsBlocked?: Maybe<Device>;
  adminSetDeviceIsRefurbished?: Maybe<Device>;
  adminSetFeatureFlag?: Maybe<FeatureFlag>;
  adminSetFirmwareRolloutQuantity?: Maybe<SetFirmwareRolloutResult>;
  adminSetPetCityState: Pet;
  adminSetPhotoDeleted?: Maybe<Photo>;
  adminSetRemoteLoggingForFirmwareChannel?: Maybe<FirmwareUpdateChannel>;
  adminSetSubscriptionIdOnDevice?: Maybe<Device>;
  adminSetUserGroups?: Maybe<AdminBaseUserProfile>;
  adminSubmitCertGenJob?: Maybe<CreateCertGenJobResult>;
  /**
   * Undmismiss a previous mobile app content dismissal.
   * dbId is the id of the dismissal record.
   */
  adminUndismissMobileAppContent?: Maybe<Scalars['Boolean']['output']>;
  adminUpdateChipResellerByBox: ResellerChipFeed;
  adminUpdateChipResellerBySerialNumber: ResellerChipFeed;
  adminUpdateDeviceCellularStatus?: Maybe<Scalars['String']['output']>;
  adminUpdateDeviceHasActiveSubscription?: Maybe<Device>;
  adminUpdateDeviceSubscriptionOverrideEndsAt?: Maybe<Device>;
  adminUpdateFirmware?: Maybe<FirmwareVersion>;
  adminUpdateFirmwareChannel?: Maybe<FirmwareUpdateChannel>;
  adminUpdateInPersonEvent: InPersonEvent;
  adminUpdateInPersonEventInventory: InPersonEvent;
  adminUpdateInventoryConfiguration: ProductInventoryLevels;
  adminUpdateListItem: AdminList;
  adminUpdatePromotion: Promotion;
  adminUpdateShelterluvConfig: ChipReseller;
  /**
   * Upload an image to the barkinglabs-media bucket with a public ACL.
   * One of `prefix` or `path` must be specified.
   */
  adminUploadImage: AdminUploadImageResult;
  adminUpsertFulfillmentWarehouse: FulfillmentWarehouse;
  /** Apply multidog discount to all dogs paid for by current user. */
  applyMultidogDiscount?: Maybe<ApplyMultidogDiscountResult>;
  /** Assign an unowned charging base to the current user. */
  assignChargingBase: ChargingBase;
  /** Assign an unowned device to a pet. */
  assignDevice: Pet;
  /** Chip resellers: transfer the a chip in their inventory to the new owner */
  assignNewOwnerToChip: ResellerChip;
  /** Chip resellers: add a pet to a chip in their inventory */
  assignNewPetToChip: ResellerChip;
  associateChipWithPet?: Maybe<Scalars['Boolean']['output']>;
  cancelOrderLineItems: Order;
  cancelOrders: Array<Order>;
  cancelRMA?: Maybe<RMA>;
  cancelSupplementSubscription?: Maybe<CancelSupplementSubscriptionResult>;
  changePetHousehold?: Maybe<Pet>;
  changePlaceHousehold?: Maybe<Place>;
  checkInRMA?: Maybe<RMA>;
  checkInUnknownReturn?: Maybe<RMA>;
  /**
   * Pet owners: Step 1 of the chip registration process for pet owners who want to register a chip that is injected
   * into their pet. Only connect their email address to the chip.
   */
  chipRegistrationAddEmail: BaseChip;
  /**
   * Pet owners: Alternate step 2 of the chip registration process for pet owners. Choose an existing pet to register to
   * the chip.
   */
  chipRegistrationAddExistingPet: BaseChip;
  /** Pet owners: Step 2 of the chip registration process for pet owners. Add their pet information. */
  chipRegistrationAddNewPet: BaseChip;
  /** Pet owners: Step 3 of the chip registration process for pet owners. Add their contact information */
  chipRegistrationUpdateContact: BaseChip;
  /** In-app cancellation flow retention offers. */
  claimFreeMonth?: Maybe<ClaimFreeMonthResult>;
  claimPauseMonth?: Maybe<ClaimPauseMonthResult>;
  clearDeviceResponseOverride?: Maybe<Scalars['Boolean']['output']>;
  createBillingAccount: BillingAccount;
  createDevice?: Maybe<Device>;
  createDynamicEnumMember: DynamicEnum;
  createFiNewsNotification: FiNewsNotification;
  createImageFromUrl?: Maybe<CreateImageFromUrlResult>;
  createMobileAppBanner: AdminMobileAppBanner;
  createMobileAppBottomSheet: AdminMobileAppBottomSheet;
  createMobileAppDiscoverItem: AdminMobileAppDiscoverItem;
  createMobileAppSplashScreen: AdminMobileAppSplashScreen;
  createPack: Pack;
  createPet: Pet;
  createPlace: Place;
  createRMA?: Maybe<RMA>;
  createSharingSession: SharingSession;
  createTestimonialReminder: User;
  createTextPost: TextPost;
  createUser?: Maybe<User>;
  createVideoPost: VideoPost;
  /**
   * Create a Zendesk ticket from in-app cancellation webview flow.
   * Return true if ticket was successfully created.
   */
  createZendeskCancellationTicket?: Maybe<CreateZendeskTicketResult>;
  createZendeskReplacementTicket?: Maybe<CreateZendeskTicketResult>;
  deleteComment?: Maybe<Scalars['Boolean']['output']>;
  deleteTextPost?: Maybe<Scalars['Boolean']['output']>;
  deleteVideoPost?: Maybe<Scalars['Boolean']['output']>;
  disconnectAppIntegration: DisconnectAppIntegrationResult;
  /** Dismisses a mobile app content item for the current user where id is associated with Splash Screen or Banner id */
  dismissMobileAppContent?: Maybe<Scalars['Boolean']['output']>;
  dismissPetInsights?: Maybe<Scalars['Boolean']['output']>;
  dismissPromotionBanner?: Maybe<Scalars['Boolean']['output']>;
  downgradeSeries2?: Maybe<DowngradeSeries2Result>;
  downgradeSeries3?: Maybe<DowngradeSeries3Result>;
  ignoreVetSuggestion: Array<Veterinary>;
  inviteUserToHousehold: InviteUserToHouseholdResult;
  joinPack?: Maybe<Pack>;
  leavePack?: Maybe<Pack>;
  moderationApproveContent?: Maybe<Scalars['Boolean']['output']>;
  moderationRejectContent?: Maybe<Scalars['Boolean']['output']>;
  notifyWroteReview: User;
  purchaseConsumerStoreCart: CartPurchaseResult;
  purchaseMembershipUpgrade: CartPurchaseResult;
  purchaseMonthlyUpgradePromotion: CartPurchaseResult;
  purchaseSeries3UpgradeCart: CartPurchaseResult;
  purchaseSubscription: CartPurchaseResult;
  purchaseSubscriptionCart: CartPurchaseResult;
  recordAppInstallForUser: RecordAppInstallForUserResult;
  registerChipReseller: ChipReseller;
  registerHandset: Handset;
  registerThirdPartyChip: ResellerChip;
  removeDynamicEnumMember: DynamicEnum;
  removeFiNewsNotification?: Maybe<Scalars['Boolean']['output']>;
  removeHouseholdVets: Array<Veterinary>;
  removePet?: Maybe<Scalars['Boolean']['output']>;
  removePetInstagramHandle: Pet;
  removePhoto?: Maybe<Scalars['Boolean']['output']>;
  removePlace?: Maybe<Scalars['Boolean']['output']>;
  removeUserEmail: User;
  removeUserFromHousehold: RemoveUserFromHouseholdResult;
  removeWifiCredentials: ModifyWifiCredentialResult;
  renewSubscription?: Maybe<RenewSubscriptionResult>;
  /** Assign the input member IDs monotonically increasing `order` values. */
  reorderDynamicEnumMembers: DynamicEnum;
  /** Report a charging base that was in BLE connection range of the provided position */
  reportChargingBaseAtPosition: Scalars['Boolean']['output'];
  requestEmailVerification: Scalars['Boolean']['output'];
  requestUserAccountDeletion: Scalars['Boolean']['output'];
  requestVerificationCode: User;
  rescueRecallPet?: Maybe<RescueRecallPetResult>;
  rescueTransferPetPermanently?: Maybe<RescueTransferPetPermanentlyResult>;
  rescueTransferPetTemporarily?: Maybe<RescueTransferPetTemporarilyResult>;
  resolveChipTransfer: ChipTransfer;
  resumeSupplementSubscription?: Maybe<ResumeSupplementSubscriptionResult>;
  revokeUserInvite: RevokeUserInviteResult;
  setActivityNote?: Maybe<Activity>;
  setArticleLiked?: Maybe<Article>;
  setCommentLiked?: Maybe<Comment>;
  setDeviceLed: Device;
  setDeviceNote: Device;
  /** Force assign a device to a pet, for admins only. */
  setDevicePet?: Maybe<Pet>;
  setDeviceResponseOverride: DeviceResponseOverride;
  setDeviceUpdateChannel: Device;
  setFiNewsNotificationsEnabled: User;
  /** An acting pet is required to make this call. */
  setPetBlocked: StrangerPet;
  /**
   * An acting pet is required to make this call.
   * A dismissed pet will no longer appear in suggested pets to follow.
   */
  setPetDismissed: StrangerPet;
  /** An acting pet is required to make this call. */
  setPetFollowed: StrangerPet;
  setPetPrimaryPhoto: Pet;
  setPhotoLiked?: Maybe<Photo>;
  setPrimaryUserEmail: User;
  setSocialBanUser?: Maybe<Scalars['Boolean']['output']>;
  setSocialOptedOut: Household;
  setStepGoalForPet?: Maybe<SetStepGoalForPetResult>;
  setSupplementNextShipDate?: Maybe<SetNextShipmentDateResult>;
  setSupplementShippingAddress?: Maybe<SetShippingAddressResult>;
  setTextPostLiked?: Maybe<TextPost>;
  setUserLocation: User;
  setUserRoleInHousehold: SetUserRoleInHouseholdResult;
  setVideoPostLiked?: Maybe<VideoPost>;
  setWalkLiked?: Maybe<StrangerWalk>;
  shipOrderLineItems: Order;
  shipOrders: Array<Order>;
  skipSupplementShipment?: Maybe<SkipSupplementShipmentResult>;
  /** @deprecated Phase this out in favor of only using [submitReports].  Arbitrarily overwriting DeviceInfo fields is problematic. */
  submitDeviceInfo?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated User `userFeedbackEscapeAlertFeedbackSubmit` */
  submitNotificationFeedback?: Maybe<Scalars['Boolean']['output']>;
  submitReports?: Maybe<SubmitReportsResult>;
  syncOrders: SyncOrdersResults;
  /** Unassign an owned charging base from the current user. */
  unassignChargingBase: Scalars['Boolean']['output'];
  /** Unassign an owned device from a pet. */
  unassignDevice: Pet;
  undeletePet?: Maybe<Scalars['Boolean']['output']>;
  unregisterHandset?: Maybe<Scalars['Boolean']['output']>;
  updateBillingAccount: BillingAccount;
  updateChargingBase: ChargingBase;
  updateChipCondition: ResellerChip;
  /** @deprecated Use `chipRegistration*` or `assignNew*ToChip` mutations instead. */
  updateChipContactInfo: UpdateChipContactInfoResult;
  /** Update operational parameters for a module */
  updateDeviceOperationParams: Device;
  /** @deprecated Dog park notifications were removed. */
  updateDogParkNotificationSettings?: Maybe<DogParkNotificationSettings>;
  updateDynamicEnumMember: DynamicEnum;
  updateFiNewsNotification: FiNewsNotification;
  updateMobileAppBanner: AdminMobileAppBanner;
  updateMobileAppBottomSheet: AdminMobileAppBottomSheet;
  updateMobileAppDiscoverItem: AdminMobileAppDiscoverItem;
  updateMobileAppSplashScreen: AdminMobileAppSplashScreen;
  updateOrderShippingAddress: Order;
  updateOrderShippingOption: Order;
  updatePet: Pet;
  updatePetAlertPreferences: Pet;
  updatePetCityState?: Maybe<Pet>;
  updatePhoneNumber: User;
  updatePlace: Place;
  updateResellerContactInfo: ChipReseller;
  updateSupplementSubscription?: Maybe<UpdateSupplementSubscriptionResult>;
  updateUser: User;
  updateUserCommunicationPreferences: User;
  updateWifiNetwork: WifiNetwork;
  /** Unassigns the current device and assigns the new unowned device */
  upgradeDevice: Pet;
  uploadContacts?: Maybe<Scalars['Boolean']['output']>;
  /** Accepts an activityId/description of issue and uploads zip file of walk segments in GeoJSON format to Amazon S3 */
  uploadWalkGeoJSONToS3?: Maybe<Scalars['Boolean']['output']>;
  userFeedbackActivityPlayFeedbackSubmit?: Maybe<EventFeedbackResponse>;
  userFeedbackActivityRestFeedbackSubmit?: Maybe<EventFeedbackResponse>;
  userFeedbackActivityTravelFeedbackSubmit?: Maybe<EventFeedbackResponse>;
  userFeedbackActivityWalkFeedbackSubmit?: Maybe<EventFeedbackResponse>;
  userFeedbackEscapeAlertFeedbackSubmit?: Maybe<EventFeedbackResponse>;
  userFeedbackLostDogFeedbackSubmit?: Maybe<EventFeedbackResponse>;
  userFeedbackLostDogModeDidHelp?: Maybe<Scalars['Boolean']['output']>;
  /** Returns base-64 encoded zip file of walk segments in GeoJSON format */
  walkSegmentsGeoJSONZip?: Maybe<Scalars['String']['output']>;
};

export type MutationacceptUserInviteArgs = {
  input: AcceptInviteInput;
};

export type MutationaddCommentComplaintArgs = {
  commentId: Scalars['ID']['input'];
};

export type MutationaddHouseholdVetArgs = {
  householdId: Scalars['ID']['input'];
  vetId: Scalars['ID']['input'];
};

export type MutationaddOrderLineItemsArgs = {
  input: AddOrderLineItemsInput;
};

export type MutationaddPetDocumentArgs = {
  input: AddPetDocumentInput;
};

export type MutationaddPetPhotoArgs = {
  input: AddPetPhotoInput;
};

export type MutationaddPhotoCommentArgs = {
  comment: Scalars['String']['input'];
  photoId: Scalars['ID']['input'];
};

export type MutationaddPhotoComplaintArgs = {
  photoId: Scalars['ID']['input'];
};

export type MutationaddPhotoForPetArgs = {
  input: AddPetPhotoInput;
};

export type MutationaddSupplementSubscriptionArgs = {
  input: AddSupplementSubscriptionInput;
};

export type MutationaddTextPostCommentArgs = {
  comment: Scalars['String']['input'];
  textPostId: Scalars['ID']['input'];
};

export type MutationaddTextPostComplaintArgs = {
  textPostId: Scalars['ID']['input'];
};

export type MutationaddUserEmailArgs = {
  input: AddUserEmailInput;
};

export type MutationaddVideoPostCommentArgs = {
  comment: Scalars['String']['input'];
  videoPostId: Scalars['ID']['input'];
};

export type MutationaddVideoPostComplaintArgs = {
  videoPostId: Scalars['ID']['input'];
};

export type MutationaddWifiCredentialsArgs = {
  input: AddWifiCredentialInput;
};

export type MutationadminActivateFulfillmentWarehouseArgs = {
  warehouseId: Scalars['ID']['input'];
};

export type MutationadminAddItemToListArgs = {
  listName: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
};

export type MutationadminAddResellerModulesArgs = {
  input: AddResellerModulesInput;
};

export type MutationadminAddUserToGroupArgs = {
  groupName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationadminAddUserToHouseholdArgs = {
  input: AddUserToHouseholdInput;
};

export type MutationadminAddVetSuggestionArgs = {
  householdId: Scalars['ID']['input'];
  vetId: Scalars['ID']['input'];
};

export type MutationadminBulkAssignUsersToGroupArgs = {
  input: AdminBulkAssignUsersToGroupInput;
};

export type MutationadminClaimNanoWebIdsArgs = {
  input: AdminClaimNanoWebIdsInput;
};

export type MutationadminClearChipArgs = {
  chipId: Scalars['ID']['input'];
};

export type MutationadminCloseInPersonEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminCreateBatchRecordsArgs = {
  batch: Scalars['String']['input'];
  count: Scalars['Int']['input'];
  deviceType: DeviceType;
  hwRev: Scalars['String']['input'];
  numDigits?: InputMaybe<Scalars['Int']['input']>;
  platform: Scalars['ID']['input'];
  prefix: Scalars['String']['input'];
  startOffset: Scalars['Int']['input'];
};

export type MutationadminCreateBreedArgs = {
  input: AdminCreateBreedInput;
};

export type MutationadminCreateFirmwareChannelArgs = {
  input: CreateFirmwareChannelInput;
};

export type MutationadminCreateInPersonEventArgs = {
  input: CreateInPersonEventInput;
};

export type MutationadminCreateOrderArgs = {
  input: AdminCreateOrderInput;
};

export type MutationadminCreatePlaceArgs = {
  input: CreatePlaceInput;
  userId: Scalars['ID']['input'];
};

export type MutationadminCreatePlatformArgs = {
  platformId: Scalars['ID']['input'];
};

export type MutationadminCreatePromotionArgs = {
  input: CreatePromotionInput;
};

export type MutationadminCreateUserGroupArgs = {
  name: Scalars['String']['input'];
};

export type MutationadminDeactivateFulfillmentWarehouseArgs = {
  warehouseId: Scalars['ID']['input'];
};

export type MutationadminDeactivateUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationadminDeleteFirmwareArgs = {
  input: DeleteFirmwareInput;
};

export type MutationadminDeleteFirmwareChannelArgs = {
  input: DeleteFirmwareChannelInput;
};

export type MutationadminDeletePromotionArgs = {
  id: Scalars['Int']['input'];
};

export type MutationadminDeleteUserGroupArgs = {
  name: Scalars['String']['input'];
};

export type MutationadminDisableShelterluvArgs = {
  chipResellerId: Scalars['ID']['input'];
};

export type MutationadminDuplicatePromotionArgs = {
  input: DuplicatePromotionInput;
};

export type MutationadminEnableShelterluvArgs = {
  input: UpdateShelterluvConfigInput;
};

export type MutationadminImportChipsArgs = {
  input: ChipImportInput;
};

export type MutationadminImportPartnerOrdersArgs = {
  csvData: Scalars['String']['input'];
};

export type MutationadminIncrementNumConfirmedReferralsArgs = {
  amount: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationadminMigrateModulesToFirmwareChannelArgs = {
  channel: Scalars['String']['input'];
  deviceType: DeviceType;
  moduleIds: Array<Scalars['String']['input']>;
  platform: Scalars['String']['input'];
};

export type MutationadminPublishTestPetUpdateArgs = {
  changeType?: InputMaybe<PetChangeType>;
  petId: Scalars['ID']['input'];
};

export type MutationadminReinstateStreakArgs = {
  petId: Scalars['ID']['input'];
};

export type MutationadminRemoveFeatureFlagArgs = {
  name: Scalars['String']['input'];
};

export type MutationadminRemoveItemFromListArgs = {
  id: Scalars['ID']['input'];
};

export type MutationadminRemoveUserFromGroupArgs = {
  groupName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationadminSendBackgroundNotificationArgs = {
  input: SendBackgroundNotificationInput;
};

export type MutationadminSendFreeNanoBoxesArgs = {
  input: AdminSendFreeNanoBoxesInput;
};

export type MutationadminSendNotificationArgs = {
  input: SendNotificationInput;
};

export type MutationadminSendScreenSharingNotificationArgs = {
  input: AdminSendScreenSharingNotificationInput;
};

export type MutationadminSendServerPushToDeviceArgs = {
  moduleId: Scalars['String']['input'];
};

export type MutationadminSetAutomaticallyActivateDevicesOnUserArgs = {
  automaticallyActivateDevices: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationadminSetChargingBaseOwnerArgs = {
  baseId: Scalars['String']['input'];
  householdId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationadminSetChargingBaseUpdateChannelArgs = {
  baseId: Scalars['String']['input'];
  channelName: Scalars['String']['input'];
};

export type MutationadminSetChipResellerNameArgs = {
  name: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
};

export type MutationadminSetChipResellerStatusArgs = {
  status: ResellerStatus;
  subjectId: Scalars['ID']['input'];
};

export type MutationadminSetCommentHiddenArgs = {
  commentId: Scalars['ID']['input'];
  hidden: Scalars['Boolean']['input'];
};

export type MutationadminSetDeviceAutoAddUserToHouseholdArgs = {
  moduleId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationadminSetDeviceIsBlockedArgs = {
  isBlocked: Scalars['Boolean']['input'];
  moduleId: Scalars['String']['input'];
};

export type MutationadminSetDeviceIsRefurbishedArgs = {
  isRefurbished: Scalars['Boolean']['input'];
  moduleId: Scalars['String']['input'];
};

export type MutationadminSetFeatureFlagArgs = {
  name: Scalars['String']['input'];
  type: SetFeatureFlagType;
  value: SetFeatureFlagValue;
};

export type MutationadminSetFirmwareRolloutQuantityArgs = {
  deviceType: DeviceType;
  platform: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  sourceChannel?: InputMaybe<Scalars['String']['input']>;
  sourceVersion?: InputMaybe<Scalars['String']['input']>;
  targetChannel?: InputMaybe<Scalars['String']['input']>;
};

export type MutationadminSetPetCityStateArgs = {
  city: Scalars['String']['input'];
  petId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

export type MutationadminSetPhotoDeletedArgs = {
  deleted: Scalars['Boolean']['input'];
  photoId: Scalars['ID']['input'];
};

export type MutationadminSetRemoteLoggingForFirmwareChannelArgs = {
  input: SetRemoteLoggingForFirmwareChannelInput;
};

export type MutationadminSetSubscriptionIdOnDeviceArgs = {
  moduleId: Scalars['String']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationadminSetUserGroupsArgs = {
  groups: Array<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationadminSubmitCertGenJobArgs = {
  batch: Scalars['String']['input'];
  deviceType: DeviceType;
};

export type MutationadminUndismissMobileAppContentArgs = {
  dbId: Scalars['Int']['input'];
};

export type MutationadminUpdateChipResellerByBoxArgs = {
  input: AdminUpdateChipResellerByBoxInput;
};

export type MutationadminUpdateChipResellerBySerialNumberArgs = {
  input: AdminUpdateChipResellerBySerialNumberInput;
};

export type MutationadminUpdateDeviceCellularStatusArgs = {
  moduleId: Scalars['String']['input'];
};

export type MutationadminUpdateDeviceHasActiveSubscriptionArgs = {
  hasSubscriptionOverride: Scalars['Boolean']['input'];
  moduleId: Scalars['String']['input'];
};

export type MutationadminUpdateDeviceSubscriptionOverrideEndsAtArgs = {
  moduleId: Scalars['String']['input'];
  subscriptionOverrideEndsAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationadminUpdateFirmwareArgs = {
  input: UpdateFirmwareInput;
};

export type MutationadminUpdateFirmwareChannelArgs = {
  input: UpdateFirmwareChannelInput;
};

export type MutationadminUpdateInPersonEventArgs = {
  input: UpdateInPersonEventInput;
};

export type MutationadminUpdateInPersonEventInventoryArgs = {
  input: UpdateInPersonEventInventoryInput;
};

export type MutationadminUpdateInventoryConfigurationArgs = {
  input: AdminUpdateInventoryConfigurationInput;
};

export type MutationadminUpdateListItemArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['JSON']['input'];
};

export type MutationadminUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};

export type MutationadminUpdateShelterluvConfigArgs = {
  input: UpdateShelterluvConfigInput;
};

export type MutationadminUploadImageArgs = {
  data: Scalars['Binary']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
};

export type MutationadminUpsertFulfillmentWarehouseArgs = {
  input: AdminUpsertFulfillmentWarehouseInput;
};

export type MutationassignChargingBaseArgs = {
  baseId: Scalars['String']['input'];
  householdId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationassignDeviceArgs = {
  iccid?: InputMaybe<Scalars['String']['input']>;
  moduleId: Scalars['String']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationassignNewOwnerToChipArgs = {
  input: AssignNewOwnerToChipInput;
};

export type MutationassignNewPetToChipArgs = {
  input: AssignNewPetToChipInput;
};

export type MutationassociateChipWithPetArgs = {
  input: AssociateChipWithPetInput;
};

export type MutationcancelOrderLineItemsArgs = {
  input: CancelOrderLineItemsInput;
};

export type MutationcancelOrdersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationcancelRMAArgs = {
  id: Scalars['ID']['input'];
};

export type MutationcancelSupplementSubscriptionArgs = {
  input: CancelSupplementSubscriptionInput;
};

export type MutationchangePetHouseholdArgs = {
  householdId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
};

export type MutationchangePlaceHouseholdArgs = {
  householdId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
};

export type MutationcheckInRMAArgs = {
  input: CheckInRMAInput;
};

export type MutationcheckInUnknownReturnArgs = {
  input: CheckInUnknownReturnInput;
};

export type MutationchipRegistrationAddEmailArgs = {
  input: ChipRegistrationAddEmailInput;
};

export type MutationchipRegistrationAddExistingPetArgs = {
  input: ChipRegistrationAddExistingPetInput;
};

export type MutationchipRegistrationAddNewPetArgs = {
  input: ChipRegistrationAddNewPetInput;
};

export type MutationchipRegistrationUpdateContactArgs = {
  input: ChipRegistrationUpdateContactInput;
};

export type MutationclaimFreeMonthArgs = {
  input: ClaimFreeMonthInput;
};

export type MutationclaimPauseMonthArgs = {
  input: ClaimPauseMonthInput;
};

export type MutationclearDeviceResponseOverrideArgs = {
  moduleId: Scalars['String']['input'];
};

export type MutationcreateBillingAccountArgs = {
  input: CreateBillingAccountInput;
};

export type MutationcreateDeviceArgs = {
  input: CreateDeviceInput;
};

export type MutationcreateDynamicEnumMemberArgs = {
  input: CreateDynamicEnumMemberInput;
};

export type MutationcreateFiNewsNotificationArgs = {
  input: CreateFiNewsNotificationInput;
};

export type MutationcreateImageFromUrlArgs = {
  input: CreateImageFromUrlInput;
};

export type MutationcreateMobileAppBannerArgs = {
  input: CreateMobileAppBannerInput;
};

export type MutationcreateMobileAppBottomSheetArgs = {
  input: CreateMobileAppBottomSheetInput;
};

export type MutationcreateMobileAppDiscoverItemArgs = {
  input: CreateMobileAppDiscoverItemInput;
};

export type MutationcreateMobileAppSplashScreenArgs = {
  input: CreateMobileAppSplashScreenInput;
};

export type MutationcreatePackArgs = {
  input: CreatePackInput;
};

export type MutationcreatePetArgs = {
  input: CreatePetInput;
};

export type MutationcreatePlaceArgs = {
  input: CreatePlaceInput;
};

export type MutationcreateRMAArgs = {
  input: CreateRMAInput;
};

export type MutationcreateSharingSessionArgs = {
  petId: Scalars['ID']['input'];
};

export type MutationcreateTestimonialReminderArgs = {
  petId: Scalars['ID']['input'];
};

export type MutationcreateTextPostArgs = {
  input: CreateTextPostInput;
};

export type MutationcreateUserArgs = {
  input: CreateUserInput;
};

export type MutationcreateVideoPostArgs = {
  input: CreateVideoPostInput;
};

export type MutationcreateZendeskCancellationTicketArgs = {
  input: CreateZendeskCancellationTicketInput;
};

export type MutationcreateZendeskReplacementTicketArgs = {
  input: CreateZendeskReplacementTicketInput;
};

export type MutationdeleteCommentArgs = {
  commentId: Scalars['ID']['input'];
};

export type MutationdeleteTextPostArgs = {
  textPostId: Scalars['ID']['input'];
};

export type MutationdeleteVideoPostArgs = {
  videoPostId: Scalars['ID']['input'];
};

export type MutationdisconnectAppIntegrationArgs = {
  app: AppIntegrationId;
};

export type MutationdismissMobileAppContentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdismissPetInsightsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationdismissPromotionBannerArgs = {
  input: DismissPromotionBannerInput;
};

export type MutationdowngradeSeries2Args = {
  input: DowngradeSeries2Input;
};

export type MutationdowngradeSeries3Args = {
  input: DowngradeSeries3Input;
};

export type MutationignoreVetSuggestionArgs = {
  householdId: Scalars['ID']['input'];
  vetId: Scalars['ID']['input'];
};

export type MutationinviteUserToHouseholdArgs = {
  input: InviteUserInput;
};

export type MutationjoinPackArgs = {
  packId: Scalars['ID']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationleavePackArgs = {
  packId: Scalars['ID']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationmoderationApproveContentArgs = {
  input: SocialContentInput;
};

export type MutationmoderationRejectContentArgs = {
  input: SocialContentInput;
};

export type MutationpurchaseConsumerStoreCartArgs = {
  input: ConsumerStoreCartInput;
};

export type MutationpurchaseMembershipUpgradeArgs = {
  input: UpgradeMembershipInput;
};

export type MutationpurchaseMonthlyUpgradePromotionArgs = {
  input: UpgradeMembershipInput;
};

export type MutationpurchaseSeries3UpgradeCartArgs = {
  input: Series3UpgradeCartInput;
};

export type MutationpurchaseSubscriptionArgs = {
  input: PurchaseSubscriptionInput;
};

export type MutationpurchaseSubscriptionCartArgs = {
  input: SubscriptionCartInput;
};

export type MutationrecordAppInstallForUserArgs = {
  input: RecordAppInstallForUserInput;
};

export type MutationregisterChipResellerArgs = {
  input: RegisterChipResellerInput;
};

export type MutationregisterHandsetArgs = {
  input: RegisterHandsetInput;
};

export type MutationregisterThirdPartyChipArgs = {
  input: ThirdPartyChipInput;
};

export type MutationremoveDynamicEnumMemberArgs = {
  id: Scalars['ID']['input'];
};

export type MutationremoveFiNewsNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationremoveHouseholdVetsArgs = {
  householdId: Scalars['ID']['input'];
  vetIds: Array<Scalars['ID']['input']>;
};

export type MutationremovePetArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationremovePetInstagramHandleArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationremovePhotoArgs = {
  photoId: Scalars['ID']['input'];
};

export type MutationremovePlaceArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationremoveUserEmailArgs = {
  input: RemoveUserEmailInput;
};

export type MutationremoveUserFromHouseholdArgs = {
  input: RemoveUserFromHouseholdInput;
};

export type MutationremoveWifiCredentialsArgs = {
  input: RemoveWifiCredentialInput;
};

export type MutationrenewSubscriptionArgs = {
  input: RenewSubscriptionInput;
};

export type MutationreorderDynamicEnumMembersArgs = {
  ids: Array<Scalars['ID']['input']>;
  kind: DynamicEnumKind;
};

export type MutationreportChargingBaseAtPositionArgs = {
  baseId: Scalars['String']['input'];
  horizontalAccuracy: Scalars['Float']['input'];
  position: PositionInput;
};

export type MutationrequestEmailVerificationArgs = {
  input: RequestEmailVerificationInput;
};

export type MutationrequestUserAccountDeletionArgs = {
  input: UserAccountDeletionInput;
};

export type MutationrequestVerificationCodeArgs = {
  input: RequestVerificationCodeInput;
};

export type MutationrescueRecallPetArgs = {
  input: RescueRecallPetInput;
};

export type MutationrescueTransferPetPermanentlyArgs = {
  input: RescueTransferPetPermanentlyInput;
};

export type MutationrescueTransferPetTemporarilyArgs = {
  input: RescueTransferPetTemporarilyInput;
};

export type MutationresolveChipTransferArgs = {
  input: ResolveChipTransferInput;
};

export type MutationresumeSupplementSubscriptionArgs = {
  input: ResumeSupplementSubscriptionInput;
};

export type MutationrevokeUserInviteArgs = {
  input: RevokeUserInviteInput;
};

export type MutationsetActivityNoteArgs = {
  activityId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsetArticleLikedArgs = {
  articleId: Scalars['ID']['input'];
  liked: Scalars['Boolean']['input'];
};

export type MutationsetCommentLikedArgs = {
  commentId: Scalars['ID']['input'];
  liked: Scalars['Boolean']['input'];
};

export type MutationsetDeviceLedArgs = {
  ledColorCode: Scalars['Int']['input'];
  moduleId: Scalars['String']['input'];
};

export type MutationsetDeviceNoteArgs = {
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsetDevicePetArgs = {
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationsetDeviceResponseOverrideArgs = {
  deviceResponseOverride: Scalars['JSON']['input'];
  moduleId: Scalars['String']['input'];
  overrideDurationInMinutes: Scalars['Int']['input'];
};

export type MutationsetDeviceUpdateChannelArgs = {
  channelName: Scalars['String']['input'];
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsetFiNewsNotificationsEnabledArgs = {
  fiNewsNotificationsEnabled: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationsetPetBlockedArgs = {
  blocked: Scalars['Boolean']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationsetPetDismissedArgs = {
  dismissed: Scalars['Boolean']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationsetPetFollowedArgs = {
  followed: Scalars['Boolean']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationsetPetPrimaryPhotoArgs = {
  input: SetPetPrimaryPhotoInput;
};

export type MutationsetPhotoLikedArgs = {
  actingPetId?: InputMaybe<Scalars['ID']['input']>;
  liked: Scalars['Boolean']['input'];
  photoId: Scalars['ID']['input'];
};

export type MutationsetPrimaryUserEmailArgs = {
  input: SetPrimaryUserEmailInput;
};

export type MutationsetSocialBanUserArgs = {
  banned: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationsetSocialOptedOutArgs = {
  householdId: Scalars['ID']['input'];
  socialOptedOut: Scalars['Boolean']['input'];
};

export type MutationsetStepGoalForPetArgs = {
  input: SetStepGoalForPetInput;
};

export type MutationsetSupplementNextShipDateArgs = {
  input: SetNextShipmentDateInput;
};

export type MutationsetSupplementShippingAddressArgs = {
  input: SetShippingAddressInput;
};

export type MutationsetTextPostLikedArgs = {
  liked: Scalars['Boolean']['input'];
  textPostId: Scalars['ID']['input'];
};

export type MutationsetUserLocationArgs = {
  input: LocationReportInput;
};

export type MutationsetUserRoleInHouseholdArgs = {
  input: SetUserRoleInHouseholdInput;
};

export type MutationsetVideoPostLikedArgs = {
  liked: Scalars['Boolean']['input'];
  videoPostId: Scalars['ID']['input'];
};

export type MutationsetWalkLikedArgs = {
  liked: Scalars['Boolean']['input'];
  walkId: Scalars['ID']['input'];
};

export type MutationshipOrderLineItemsArgs = {
  input: ShipOrderLineItemsInput;
};

export type MutationshipOrdersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationskipSupplementShipmentArgs = {
  input: SkipSupplementShipmentInput;
};

export type MutationsubmitDeviceInfoArgs = {
  deviceInfo: Scalars['JSON']['input'];
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsubmitNotificationFeedbackArgs = {
  input: NotificationReportInput;
};

export type MutationsubmitReportsArgs = {
  activityInput?: InputMaybe<Array<ActivityReportInput>>;
  binaryResponse?: InputMaybe<Scalars['Boolean']['input']>;
  locationInput?: InputMaybe<Array<LocationReportInput>>;
  mobilityInput?: InputMaybe<Array<MobilityReportInput>>;
  moduleId: Scalars['String']['input'];
  moduleRequest?: InputMaybe<Scalars['String']['input']>;
};

export type MutationsyncOrdersArgs = {
  input: SyncOrdersInput;
};

export type MutationunassignChargingBaseArgs = {
  baseId: Scalars['String']['input'];
};

export type MutationunassignDeviceArgs = {
  moduleId: Scalars['String']['input'];
  petId: Scalars['ID']['input'];
};

export type MutationundeletePetArgs = {
  subjectId: Scalars['ID']['input'];
};

export type MutationunregisterHandsetArgs = {
  input: RegisterHandsetInput;
};

export type MutationupdateBillingAccountArgs = {
  input: BillingAccountInput;
  trackEvent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationupdateChargingBaseArgs = {
  baseId: Scalars['String']['input'];
  input: UpdateChargingBaseInput;
};

export type MutationupdateChipConditionArgs = {
  input: UpdateChipConditionInput;
};

export type MutationupdateChipContactInfoArgs = {
  input: UpdateChipContactInfoInput;
};

export type MutationupdateDeviceOperationParamsArgs = {
  input: UpdateDeviceOperationParamsInput;
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationupdateDogParkNotificationSettingsArgs = {
  input: UpdateDogParkNotificationSettingsInput;
};

export type MutationupdateDynamicEnumMemberArgs = {
  input: UpdateDynamicEnumMemberInput;
};

export type MutationupdateFiNewsNotificationArgs = {
  input: UpdateFiNewsNotificationInput;
};

export type MutationupdateMobileAppBannerArgs = {
  input: UpdateMobileAppBannerInput;
};

export type MutationupdateMobileAppBottomSheetArgs = {
  input: UpdateMobileAppBottomSheetInput;
};

export type MutationupdateMobileAppDiscoverItemArgs = {
  input: UpdateMobileAppDiscoverItemInput;
};

export type MutationupdateMobileAppSplashScreenArgs = {
  input: UpdateMobileAppSplashScreenInput;
};

export type MutationupdateOrderShippingAddressArgs = {
  input: UpdateOrderShippingAddressInput;
};

export type MutationupdateOrderShippingOptionArgs = {
  input: UpdateOrderShippingOptionInput;
};

export type MutationupdatePetArgs = {
  input: UpdatePetInput;
};

export type MutationupdatePetAlertPreferencesArgs = {
  input: UpdatePetAlertPreferencesInput;
};

export type MutationupdatePetCityStateArgs = {
  input: UpdatePetCityStateInput;
};

export type MutationupdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput;
};

export type MutationupdatePlaceArgs = {
  input: UpdatePlaceInput;
};

export type MutationupdateResellerContactInfoArgs = {
  input: UpdateResellerContactInfoInput;
};

export type MutationupdateSupplementSubscriptionArgs = {
  input: UpdateSupplementSubscriptionInput;
};

export type MutationupdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationupdateUserCommunicationPreferencesArgs = {
  input: UpdateUserCommunicationPreferencesInput;
};

export type MutationupdateWifiNetworkArgs = {
  input: UpdateWifiNetworkInput;
};

export type MutationupgradeDeviceArgs = {
  currentModuleId: Scalars['String']['input'];
  petId: Scalars['ID']['input'];
  upgradeModuleId: Scalars['String']['input'];
};

export type MutationuploadContactsArgs = {
  uploadContactInputs: UploadContactInput;
};

export type MutationuploadWalkGeoJSONToS3Args = {
  input: WalkSegmentsGeoJSONZipInput;
};

export type MutationuserFeedbackActivityPlayFeedbackSubmitArgs = {
  input: UserFeedbackActivityPlayFeedbackSubmitInput;
};

export type MutationuserFeedbackActivityRestFeedbackSubmitArgs = {
  input: UserFeedbackActivityRestFeedbackSubmitInput;
};

export type MutationuserFeedbackActivityTravelFeedbackSubmitArgs = {
  input: UserFeedbackActivityTravelFeedbackSubmitInput;
};

export type MutationuserFeedbackActivityWalkFeedbackSubmitArgs = {
  input: UserFeedbackActivityWalkFeedbackSubmitInput;
};

export type MutationuserFeedbackEscapeAlertFeedbackSubmitArgs = {
  input: UserFeedbackEscapeAlertFeedbackSubmitInput;
};

export type MutationuserFeedbackLostDogFeedbackSubmitArgs = {
  input: UserFeedbackLostDogFeedbackSubmitInput;
};

export type MutationuserFeedbackLostDogModeDidHelpArgs = {
  input: UserFeedbackLostDogModeDidHelpInput;
};

export type MutationwalkSegmentsGeoJSONZipArgs = {
  input: WalkSegmentsGeoJSONZipInput;
};

export type NanoWebId = {
  __typename?: 'NanoWebId';
  batchId?: Maybe<Scalars['String']['output']>;
  claimed: Scalars['Boolean']['output'];
  claimedAt?: Maybe<Scalars['DateTime']['output']>;
  claimedBy?: Maybe<Scalars['String']['output']>;
  webId: Scalars['String']['output'];
};

export type NanoWebIdBatchInfo = {
  __typename?: 'NanoWebIdBatchInfo';
  batchId: Scalars['String']['output'];
  claimedAt: Scalars['DateTime']['output'];
  claimedBy: Scalars['String']['output'];
  idCount: Scalars['String']['output'];
};

export type NearbyDogPark = {
  __typename?: 'NearbyDogPark';
  /**
   * Text describing distance to dog park.
   * e.g. "2.5 miles away"
   */
  distanceText: Scalars['String']['output'];
  dogPark: DogPark;
};

export type NearbyDogParkFeed = {
  __typename?: 'NearbyDogParkFeed';
  count: Scalars['Int']['output'];
  nearbyDogParks: Array<NearbyDogPark>;
};

export type NearbyPetItem = {
  __typename?: 'NearbyPetItem';
  approximateDistance: ApproximateDistance;
  pet: StrangerPet;
};

export type NearbyPetsFeed = {
  __typename?: 'NearbyPetsFeed';
  items: Array<NearbyPetItem>;
  totalCount: Scalars['Int']['output'];
};

/**
 * Represents a state where the current state can't be fully determined due to a lack of reports
 * resulting from the pet either needing a collar or a lack of reports for that pet/collar
 */
export type NeedsReportsState = PetCollarState & {
  __typename?: 'NeedsReportsState';
  /** Whether the pet has a collar assigned */
  needsCollar: Scalars['Boolean']['output'];
  /** Whether the pet has any reports associated with its collar */
  needsReports: Scalars['Boolean']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type NextSupplementShipmentDetails = {
  __typename?: 'NextSupplementShipmentDetails';
  deliveryExpected?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  trackingLink?: Maybe<Scalars['String']['output']>;
};

export type NoLTEConnectivityDeviceInfo = {
  __typename?: 'NoLTEConnectivityDeviceInfo';
  modemNoRegistrationCount: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
  uptime: Scalars['Int']['output'];
};

export enum NotificationChannel {
  PUSH = 'PUSH',
  SMS = 'SMS',
}

export type NotificationCountByType = {
  __typename?: 'NotificationCountByType';
  logDocumentCount: Scalars['Int']['output'];
  notificationType: Scalars['String']['output'];
};

export type NotificationFeed = {
  __typename?: 'NotificationFeed';
  feedItems: Array<NotificationFeedItem>;
  pageInfo: PageInfo;
  unreadCount: Scalars['Int']['output'];
};

export type NotificationFeedunreadCountArgs = {
  since?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationFeedArticleItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedArticleItem';
  article: Article;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedDogParkVisitItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedDogParkVisitItem';
  /** Which dog park was visited */
  dogPark: DogPark;
  id: Scalars['ID']['output'];
  /** The pet that visited the dog park */
  pet: StrangerPet;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedFiNewsItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedFiNewsItem';
  fiNewsNotification: FiNewsNotification;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedFollowedPetPhotoAddedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedFollowedPetPhotoAddedItem';
  followedPet: StrangerPet;
  id: Scalars['ID']['output'];
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedItem = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedNearbyPetJoinedFiItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedNearbyPetJoinedFiItem';
  id: Scalars['ID']['output'];
  /** Where the new pet is located. */
  locationText: Scalars['String']['output'];
  /** The pet that just joined Fi. */
  pet: StrangerPet;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedPetFollowedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedPetFollowedItem';
  followedPet: Pet;
  followingPet: StrangerPet;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedPhotoCommentItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedPhotoCommentItem';
  comment: Comment;
  /** The pet who commented */
  commentingPet: StrangerPet;
  id: Scalars['ID']['output'];
  /** The pet whose photo was commented on */
  pet: Pet;
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedPhotoCommentLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedPhotoCommentLikedItem';
  comment: Comment;
  id: Scalars['ID']['output'];
  /** The pet who liked the comment */
  likingPet: StrangerPet;
  /** The pet whose post was commented on */
  pet: Pet;
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedPhotoLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedPhotoLikedItem';
  id: Scalars['ID']['output'];
  /** The pet who is liking the photo */
  likingPet: StrangerPet;
  numPhotosLiked: Scalars['Int']['output'];
  /** The pet whose photo is being liked */
  pet: Pet;
  photo: Photo;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedTextPostCommentItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedTextPostCommentItem';
  comment: Comment;
  /** The pet who commented */
  commentingPet: StrangerPet;
  id: Scalars['ID']['output'];
  /** The pet whose photo was commented on */
  pet: StrangerPet;
  textPost: TextPost;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedTextPostCommentLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedTextPostCommentLikedItem';
  comment: Comment;
  id: Scalars['ID']['output'];
  /** The pet who liked the comment */
  likingPet: StrangerPet;
  /** The pet whose post was commented on */
  pet: Pet;
  textPost: TextPost;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedTextPostLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedTextPostLikedItem';
  id: Scalars['ID']['output'];
  /** The pet who is liking the post */
  likingPet: StrangerPet;
  numPostsLiked: Scalars['Int']['output'];
  /** The pet whose post is being liked */
  pet: Pet;
  textPost: TextPost;
  timestamp: Scalars['DateTime']['output'];
};

export type NotificationFeedVideoPostCommentItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedVideoPostCommentItem';
  comment: Comment;
  /** The pet who commented */
  commentingPet: StrangerPet;
  id: Scalars['ID']['output'];
  /** The pet whose photo was commented on */
  pet: StrangerPet;
  timestamp: Scalars['DateTime']['output'];
  videoPost: VideoPost;
};

export type NotificationFeedVideoPostCommentLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedVideoPostCommentLikedItem';
  comment: Comment;
  id: Scalars['ID']['output'];
  /** The pet who liked the comment */
  likingPet: StrangerPet;
  /** The pet whose post was commented on */
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
  videoPost: VideoPost;
};

export type NotificationFeedVideoPostLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedVideoPostLikedItem';
  id: Scalars['ID']['output'];
  /** The pet who is liking the post */
  likingPet: StrangerPet;
  numPostsLiked: Scalars['Int']['output'];
  /** The pet whose post is being liked */
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
  videoPost: VideoPost;
};

/** DEPRECATED */
export type NotificationFeedWalkClappedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedWalkClappedItem';
  /** The pet who gave the clap */
  clappingPet: StrangerPet;
  id: Scalars['ID']['output'];
  /** The pet whose walk got a clap */
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
  walk: StrangerWalk;
};

export type NotificationFeedWalkLikedItem = NotificationFeedItem & {
  __typename?: 'NotificationFeedWalkLikedItem';
  id: Scalars['ID']['output'];
  /** The pet who gave the like */
  likingPet: StrangerPet;
  /** The pet whose walk got a like */
  pet: Pet;
  timestamp: Scalars['DateTime']['output'];
  walk: StrangerWalk;
};

export type NotificationLogDocument = {
  __typename?: 'NotificationLogDocument';
  channel: Scalars['String']['output'];
  msg: Scalars['String']['output'];
  notificationCategory?: Maybe<Scalars['String']['output']>;
  notificationMessage: Scalars['String']['output'];
  notificationType: Scalars['String']['output'];
  petId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  shouldShowBadge?: Maybe<Scalars['Boolean']['output']>;
  timestamp: Scalars['DateTime']['output'];
  twilio?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['Int']['output'];
};

export type NotificationReportInput = {
  feedback: Scalars['String']['input'];
  notificationId: Scalars['ID']['input'];
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type NumberFeatureFlag = FeatureFlag & {
  __typename?: 'NumberFeatureFlag';
  accessedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dynamicSettingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberValue: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OAuthSetupDetails = {
  __typename?: 'OAuthSetupDetails';
  authorizeUrl: Scalars['String']['output'];
  clientId: Scalars['Int']['output'];
  redirectUrl: Scalars['String']['output'];
  scope: Scalars['String']['output'];
};

export type ObfuscatedCardInfo = {
  __typename?: 'ObfuscatedCardInfo';
  lastFour: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export enum ObfuscatedReason {
  HAS_ACCESS = 'HAS_ACCESS',
  NO_ACCESS = 'NO_ACCESS',
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
}

export enum OfflineCollarDuration {
  /** Offline for > 30 minutes. */
  LONG = 'LONG',
  /** Offline for <= 30 minutes. */
  SHORT = 'SHORT',
}

/** Encapsulates any state associated with the collar being offline */
export type OfflineCollarStatus = {
  __typename?: 'OfflineCollarStatus';
  /** Indication of how long the collar has been offline. */
  offlineDuration: OfflineCollarDuration;
  /** Whether the system believes the collar is offline due to being out of power */
  outOfBattery: Scalars['Boolean']['output'];
};

/**
 * An onboarding session is a period of time during which a user is using the app to activate a device. This is for
 * retrieving handset logs for a specific period of time during an onboarding session.
 */
export type OnboardingSession = {
  __typename?: 'OnboardingSession';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type OngoingActivity = {
  address?: Maybe<Scalars['String']['output']>;
  areaName?: Maybe<Scalars['String']['output']>;
  atDogPark?: Maybe<DogPark>;
  cityState?: Maybe<Scalars['String']['output']>;
  debugMetadata: Scalars['JSON']['output'];
  lastReportTimestamp: Scalars['DateTime']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  obfuscatedReason: ObfuscatedReason;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  shouldShowDuration?: Maybe<Scalars['Boolean']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
  uncertaintyInfo?: Maybe<UncertaintyInfo>;
};

export type OngoingRest = OngoingActivity & {
  __typename?: 'OngoingRest';
  actualSegmentTypeForDebug: Scalars['String']['output'];
  address?: Maybe<Scalars['String']['output']>;
  allPositionsForDebug?: Maybe<Array<Position>>;
  areaName?: Maybe<Scalars['String']['output']>;
  atDogPark?: Maybe<DogPark>;
  cityState?: Maybe<Scalars['String']['output']>;
  debugMetadata: Scalars['JSON']['output'];
  lastReportTimestamp: Scalars['DateTime']['output'];
  location: Location;
  neighborhood?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  obfuscatedReason: ObfuscatedReason;
  place?: Maybe<Place>;
  /** @deprecated Use location instead */
  position: Position;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  shouldShowDuration?: Maybe<Scalars['Boolean']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
  uncertaintyInfo?: Maybe<UncertaintyInfo>;
};

export type OngoingWalk = OngoingActivity & {
  __typename?: 'OngoingWalk';
  address?: Maybe<Scalars['String']['output']>;
  areaName?: Maybe<Scalars['String']['output']>;
  atDogPark?: Maybe<DogPark>;
  cityState?: Maybe<Scalars['String']['output']>;
  debugMetadata: Scalars['JSON']['output'];
  distance: Scalars['Float']['output'];
  lastReportTimestamp: Scalars['DateTime']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  obfuscatedReason: ObfuscatedReason;
  path: Array<Position>;
  positions: Array<Location>;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  shouldShowDuration?: Maybe<Scalars['Boolean']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
  uncertaintyInfo?: Maybe<UncertaintyInfo>;
};

/** Encapsulates any state associated with the collar being online */
export type OnlineCollarStatus = {
  __typename?: 'OnlineCollarStatus';
  state: OnlineConnectionState;
};

export type OnlineConnectionState = ConnectedToBase | ConnectedToCellular | ConnectedToUser | ConnectedToWifi;

export type OperationParams = {
  __typename?: 'OperationParams';
  /** @deprecated Not used */
  gnssLongTimeout?: Maybe<Scalars['Int']['output']>;
  gnssMaxAcceptableRadiusM?: Maybe<Scalars['Float']['output']>;
  gnssMinAcceptableSatellites?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Not used */
  gnssShortTimeout?: Maybe<Scalars['Int']['output']>;
  ledEnabled?: Maybe<Scalars['Boolean']['output']>;
  ledOffAt?: Maybe<Scalars['DateTime']['output']>;
  mode?: Maybe<OperationParamsMode>;
  reboot?: Maybe<Scalars['Boolean']['output']>;
  updateFrequency?: Maybe<Scalars['Int']['output']>;
  updateFrequencyNoActivity?: Maybe<Scalars['Int']['output']>;
  wifiDisabled?: Maybe<Scalars['Boolean']['output']>;
};

export enum OperationParamsMode {
  DORMANT = 'DORMANT',
  EAGER = 'EAGER',
  LOST_DOG = 'LOST_DOG',
  NORMAL = 'NORMAL',
}

export type Order = {
  __typename?: 'Order';
  bigCommerceOrderId?: Maybe<Scalars['String']['output']>;
  billingInfo?: Maybe<BillingInfo>;
  canBeCanceled: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inPersonEvent?: Maybe<InPersonEvent>;
  lineItems: Array<OrderLineItem>;
  orderDate: Scalars['DateTime']['output'];
  orderSource: OrderSource;
  orderSourceId: Scalars['String']['output'];
  shipments: Array<OrderShipment>;
  shippingAddress: ShippingAddress;
  shippingOption: ShippingOption;
  status: OrderStatus;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<StrangerUser>;
};

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderSourceLineNumber?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  status: OrderLineItemStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum OrderLineItemStatus {
  BACKORDERED = 'BACKORDERED',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  RELEASED = 'RELEASED',
}

export type OrderShipment = {
  __typename?: 'OrderShipment';
  createdAt: Scalars['DateTime']['output'];
  fulfillmentProviderOrderNumber: Scalars['String']['output'];
  fulfillmentWarehouse: FulfillmentWarehouse;
  id: Scalars['ID']['output'];
  lineItems: Array<OrderShipmentLineItem>;
  packages: Array<OrderShipmentPackage>;
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  shippingAddress: ShippingAddress;
  shippingOption: ShippingOption;
  status: OrderShipmentStatus;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

export type OrderShipmentLineItem = {
  __typename?: 'OrderShipmentLineItem';
  id: Scalars['ID']['output'];
  orderLineItem?: Maybe<OrderLineItem>;
  productDescription?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  serialNumbers: Array<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  status: OrderShipmentLineItemStatus;
};

export enum OrderShipmentLineItemStatus {
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  CANCELED = 'CANCELED',
  SHIPPED = 'SHIPPED',
}

export type OrderShipmentPackage = {
  __typename?: 'OrderShipmentPackage';
  deliveredAt?: Maybe<Scalars['String']['output']>;
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  shippingCarrier?: Maybe<Scalars['String']['output']>;
  shippingMethod?: Maybe<Scalars['String']['output']>;
  trackingNumber: Scalars['String']['output'];
};

export enum OrderShipmentStatus {
  AWAITING_RELEASE = 'AWAITING_RELEASE',
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  CANCELED = 'CANCELED',
  DELIVERED = 'DELIVERED',
  PENDING = 'PENDING',
  RELEASED = 'RELEASED',
  SHIPPED = 'SHIPPED',
}

export type OrderShippingAddressInput = {
  city: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

export enum OrderSource {
  AMAZON = 'AMAZON',
  CHEWY = 'CHEWY',
  FLIP = 'FLIP',
  HSN = 'HSN',
  MANUAL = 'MANUAL',
  PETSMART = 'PETSMART',
  PETWATCH = 'PETWATCH',
  RACHIO = 'RACHIO',
  RECURLY = 'RECURLY',
  STUNTPUPPY = 'STUNTPUPPY',
}

export enum OrderStatus {
  BACKORDERED = 'BACKORDERED',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  ON_HOLD = 'ON_HOLD',
  PARTIALLY_RELEASED = 'PARTIALLY_RELEASED',
  PENDING = 'PENDING',
  RELEASED = 'RELEASED',
}

export type OrdersQueryInput = {
  endOrderDate?: InputMaybe<Scalars['DateTime']['input']>;
  inPersonEventUuid?: InputMaybe<Scalars['String']['input']>;
  orderSources?: InputMaybe<Array<OrderSource>>;
  orderStatuses?: InputMaybe<Array<OrderStatus>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shipmentStatuses?: InputMaybe<Array<OrderShipmentStatus>>;
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
  startOrderDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Origin = {
  __typename?: 'Origin';
  icon: Image;
  name: Scalars['String']['output'];
};

export type OvernightRestSummary = {
  date: Scalars['DateTime']['output'];
};

export enum POIType {
  BEACH = 'BEACH',
  DOG_PARK = 'DOG_PARK',
  NATURE_RESERVE = 'NATURE_RESERVE',
  PARK = 'PARK',
}

export type Pack = BasePack & {
  __typename?: 'Pack';
  actingPetCanJoin: Scalars['Boolean']['output'];
  actingPetCanLeave: Scalars['Boolean']['output'];
  actingPetCannotJoinReason?: Maybe<Scalars['String']['output']>;
  actingPetDidManuallyJoin: Scalars['Boolean']['output'];
  actingPetIsMember: Scalars['Boolean']['output'];
  actingPetMembershipReason: Scalars['String']['output'];
  ageFiltersWithEnoughRankedPets: Array<PackAgeFilter>;
  avatar: PackAvatar;
  category: PackCategory;
  dbId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Packs Phase 1 made fiHandle nullable */
  fiHandle: Scalars['String']['output'];
  /**
   * A 6-digit hex color code, including # sign, and letters lowercase (eg.
   * `#ff00b1`) used to highlight this pack in the app.
   */
  highlightColorHex?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use category instead */
  isAllFiDogsPack: Scalars['Boolean']['output'];
  /** @deprecated Use category instead */
  isLocationPack: Scalars['Boolean']['output'];
  /** @deprecated Use packMemberFeed instead */
  members: StrangerPetFeed;
  name: Scalars['String']['output'];
  /** @deprecated Use packMemberFeed instead */
  pendingMembers: StrangerPetFeed;
  petRanking: PackRanking;
  photo?: Maybe<Photo>;
  /** @deprecated Use totalRankedSteps instead */
  totalMonthlySteps: Scalars['Int']['output'];
  totalRankedPets: Scalars['Int']['output'];
  /**
   * A 64-bit integer representing the total steps for all pets in this pack for
   * the latest ranking period returned as a String.
   */
  totalRankedSteps: Scalars['String']['output'];
};

export type PackpetRankingArgs = {
  packAgeFilter?: InputMaybe<PackAgeFilter>;
  petId: Scalars['ID']['input'];
};

export enum PackAgeFilter {
  ADULT = 'ADULT',
  ALL = 'ALL',
  PUPPY = 'PUPPY',
  SENIOR = 'SENIOR',
}

export type PackAvatar = AvatarAbbreviation | AvatarImage;

export enum PackCategory {
  ALL_FI_DOGS = 'ALL_FI_DOGS',
  BREED = 'BREED',
  GEO = 'GEO',
  MY_PACK = 'MY_PACK',
  ORGANIZATION = 'ORGANIZATION',
}

export type PackFeed = {
  __typename?: 'PackFeed';
  packs: Array<Pack>;
  pageInfo: PageInfo;
};

export type PackMember = {
  __typename?: 'PackMember';
  /**
   * Only present for members of the "Your Pack" pack.
   * "Your Pack" pack without any members will be empty array.
   */
  ownersContactInfo?: Maybe<Array<PackMemberContactInfo>>;
  pet: StrangerPet;
  /** Empty when pet does not have a ranking */
  ranking?: Maybe<PackRanking>;
};

/** For the "Your Pack" pack, this is the contact info for the pet's owner. */
export type PackMemberContactInfo = {
  __typename?: 'PackMemberContactInfo';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type PackMemberFeed = {
  __typename?: 'PackMemberFeed';
  members: Array<PackMember>;
  pageInfo: PageInfo;
  tokenValidity: TokenValidity;
};

export type PackRanking = {
  __typename?: 'PackRanking';
  isPending: Scalars['Boolean']['output'];
  points: PackRankingPoints;
  rankChange?: Maybe<RankChange>;
  rankNumber: Scalars['Int']['output'];
  rankPercentile: Scalars['Int']['output'];
  stepCount: Scalars['Int']['output'];
};

export type PackRankingPoints = {
  __typename?: 'PackRankingPoints';
  /** Points before any bonuses are added */
  basePoints: Scalars['Int']['output'];
  /** Total bonus points granted from any source (currently only streaks) */
  totalBonusPoints: Scalars['Int']['output'];
  /** Combination of base points and bonus points */
  totalPoints: Scalars['Int']['output'];
};

/** Padding is in pixels */
export type PaddingInput = {
  bottom: Scalars['Int']['input'];
  left: Scalars['Int']['input'];
  right: Scalars['Int']['input'];
  top: Scalars['Int']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** Pass this as `before` to paginate backwards in time. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Pass this as `after` to paginate forwards in time. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginatedPhotoFeed = {
  __typename?: 'PaginatedPhotoFeed';
  feedItems: Array<Photo>;
  pageInfo: PageInfo;
};

/**
 * The semantics of PagingDirection are ambiguous depending on the context of its
 * use. In time-based paging scenarios, "forward" refers to moving forward in
 * time (more recent) whereas "backwards" is correspondingly backwards in time
 * (less recent). For most "feeds", the implementation has the user scrolling
 * down on the device from the most recent data towards older data ("backwards").
 * When implementing a time-based feed of data, these semantics make sense.
 *
 * However when implementing paging in non-time-based contexts (browsing
 * alphabetically, browsing by rank, etc) this temporal reasoning is abandoned
 * and instead we must think about "BACKWARDS" meaning scrolling down further
 * into the list of items, and "FORWARDS" meaning scrolling up. This is not
 * intuitive but keeps the client logic for scrolling in time-based feeds and
 * other forms of pagination consistent.
 */
export enum PagingDirection {
  /** endCursor on PageInfo + BACKWARD scrolls 'down' */
  BACKWARD = 'BACKWARD',
  /** startCursor on PageInfo + FORWARD scrolls 'up' */
  FORWARD = 'FORWARD',
}

export type PagingInstruction = {
  cursor: Scalars['String']['input'];
  direction: PagingDirection;
};

export type PathProcessingDataForDebug = {
  __typename?: 'PathProcessingDataForDebug';
  errors: Array<Scalars['String']['output']>;
  steps: Array<PathProcessingStepForDebug>;
};

export type PathProcessingStepForDebug = {
  __typename?: 'PathProcessingStepForDebug';
  details?: Maybe<Scalars['JSON']['output']>;
  geoJson: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
};

export type PayPalInfo = {
  __typename?: 'PayPalInfo';
  billingAgreementId?: Maybe<Scalars['String']['output']>;
};

export type PaymentInfo = ObfuscatedCardInfo | PayPalInfo;

export type PercentageOfUsersBooleanSetting = BooleanSetting & {
  __typename?: 'PercentageOfUsersBooleanSetting';
  percentage: Scalars['Int']['output'];
  platformOverrides: Array<BooleanSettingPlatformOverride>;
};

export type Pet = BasePet & {
  __typename?: 'Pet';
  activitiesForRange: ActivitiesForRangeResult;
  activity: Activity;
  activityFeed: ActivityFeed;
  /** `after` is exclusive. */
  activityIntensities: Array<ActivityIntensity>;
  /** @deprecated Use pet.stepFeed */
  activitySummaryFeed: ActivitySummaryFeed;
  additionalInfo?: Maybe<Scalars['String']['output']>;
  ageRange: AgeRange;
  /** @deprecated Use notificationSettings */
  alertPreferences: PetAlertPreferences;
  alertsEnabled: Scalars['Boolean']['output'];
  /**
   * Pet behaviors.
   * Experimental! This API is still in active development, this may break or change at any time.
   */
  behaviorSummary?: Maybe<PetBehaviorSummaryResult>;
  breed?: Maybe<Breed>;
  /**
   * Return a feed of this Pet's cards.
   * Pass `endCursor` to `before` to paginate backwards in time.
   * Pass `startCursor` to `after` to paginate forwards in time.
   * Results are always returned in descending chronological order (most recent first).
   */
  cardFeed: CardFeed;
  chip?: Maybe<AssignedChip>;
  createdAt: Scalars['DateTime']['output'];
  currentActivityIntensity: ActivityIntensity;
  currentActivitySummary: ActivitySummary;
  dayOfBirth?: Maybe<Scalars['Int']['output']>;
  dbId: Scalars['Int']['output'];
  debugActivitiesLastNDays: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  device?: Maybe<Device>;
  discoverPostFeed: PetDiscoverFeed;
  fiHandle?: Maybe<Scalars['String']['output']>;
  followers: StrangerPetFeed;
  following: StrangerPetFeed;
  gender: Gender;
  hasSleepData: Scalars['Boolean']['output'];
  /** @deprecated Use homeLocation instead */
  homeCityState?: Maybe<Scalars['String']['output']>;
  homeLocation?: Maybe<PetHomeLocation>;
  household: Household;
  id: Scalars['ID']['output'];
  instagramHandle?: Maybe<Scalars['String']['output']>;
  isEligibleForHealthReport: Scalars['Boolean']['output'];
  isIncludedInRanking: Scalars['Boolean']['output'];
  isPurebred: Scalars['Boolean']['output'];
  lastLocation?: Maybe<Location>;
  lastOvernightRestSummary: OvernightRestSummary;
  latestStepActivity?: Maybe<StepActivity>;
  monthOfBirth?: Maybe<Scalars['Int']['output']>;
  /** Pack containing this pet and the owners contacts */
  myPack?: Maybe<VirtualPack>;
  name: Scalars['String']['output'];
  notificationSettings: Array<PetNotificationSetting>;
  ongoingActivity?: Maybe<OngoingActivity>;
  overnightRestSummary: OvernightRestSummary;
  packFeed: PackFeed;
  packs: Array<Pack>;
  /** Returns a chip for which there is an outstanding transfer associated with this Pet. */
  pendingChip?: Maybe<StrangerChip>;
  /** The step count distribution specific to this pet's age and breed */
  personalizedStepCountDistribution?: Maybe<StepCountDistribution>;
  petCollarState: PetCollarState;
  photos: PhotoFeed;
  places: Array<Place>;
  publicProfile?: Maybe<PublicProfileSharing>;
  renderDebugActivitySegments: RenderedDebugActivitySegments;
  restFeed: RestFeed;
  restSegmentsForRange: Array<RestSegment>;
  /** @deprecated Use pet.restFeed */
  restSummaryFeed: RestSummaryFeed;
  species?: Maybe<Species>;
  stepActivityInfo: StepActivityInfo;
  /**
   * Added to support Activity/Rest UI refresh in June 2024.
   * https://docs.google.com/document/d/1T2qpG0_E1QC-k0gq7A0Cp-sfqkKDAyQNuMrB9zTLv2U/edit#heading=h.2rmuizvwm3bv
   */
  stepFeed: StepFeed;
  /** Returns the average strain score for all Fi dogs. */
  strainScoreAverageAllDogs?: Maybe<StrainScoreAverage>;
  /** Returns average strain score over last 60 days. */
  strainScoreAveragePast60Days?: Maybe<StrainScoreAverage>;
  /** Returns the average strain score over all “similar” dogs (same breed + age) */
  strainScoreAverageSimilarDogs?: Maybe<StrainScoreAverage>;
  /**
   * Returns N strain scores starting on the given day and going back in time.
   * The period argument determines if this is a daily score, or weekly average,
   * or monthly average.
   *
   * Returned strain scores are rounded.
   * @deprecated Use pet.strainFeed
   */
  strainScoreFeed?: Maybe<StrainScoreResult>;
  /**
   * Returns 24 buckets, one per hour, of strain scores (if available) on specified day.
   *
   * The `day` argument should be null to retrieve today's score.
   * To page back into the past, set `day` to be the `nextCursor` string provided in the
   * StrainScoreResult.
   *
   * Returned strain scores are *not* rounded.
   * @deprecated Use pet.strainFeed
   */
  strainScoreHourly?: Maybe<StrainScoreResult>;
  streakHistoryFeed: StreakHistoryFeed;
  timezone: Scalars['String']['output'];
  /** Total number of likes for this pet, across walks, photos, etc. */
  totalLikes: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
  yearOfBirth: Scalars['Int']['output'];
};

export type PetactivitiesForRangeArgs = {
  after: Scalars['DateTime']['input'];
  before?: InputMaybe<Scalars['DateTime']['input']>;
  includeAll?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PetactivityArgs = {
  id: Scalars['ID']['input'];
  walksVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type PetactivityFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  includeAll?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  typeFilter?: InputMaybe<Array<ActivityType>>;
  walksVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type PetactivityIntensitiesArgs = {
  after: Scalars['DateTime']['input'];
  before?: InputMaybe<Scalars['DateTime']['input']>;
  stride?: InputMaybe<Scalars['Int']['input']>;
};

export type PetactivitySummaryFeedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  period: ActivitySummaryPeriod;
};

export type PetbehaviorSummaryArgs = {
  input: PetBehaviorSummaryInput;
};

export type PetcardFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PetcurrentActivityIntensityArgs = {
  stride?: InputMaybe<Scalars['Int']['input']>;
};

export type PetcurrentActivitySummaryArgs = {
  period: ActivitySummaryPeriod;
};

export type PetdiscoverPostFeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type PetfollowersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type PetfollowingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type PetnotificationSettingsArgs = {
  category?: InputMaybe<PetNotificationSettingCategory>;
  forUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type PetongoingActivityArgs = {
  walksVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type PetovernightRestSummaryArgs = {
  date: Scalars['DateTime']['input'];
};

export type PetrenderDebugActivitySegmentsArgs = {
  input: Scalars['JSON']['input'];
};

export type PetrestFeedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  period: ActivityRestStrainPeriod;
};

export type PetrestSegmentsForRangeArgs = {
  after: Scalars['DateTime']['input'];
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PetrestSummaryFeedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  period: ActivitySummaryPeriod;
};

export type PetstepFeedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  period: ActivityRestStrainPeriod;
};

export type PetstrainScoreFeedArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  period: StrainScorePeriod;
};

export type PetstrainScoreHourlyArgs = {
  day?: InputMaybe<Scalars['String']['input']>;
};

export type PetstreakHistoryFeedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type PetAlertPreferences = {
  __typename?: 'PetAlertPreferences';
  notificationMode: PetNotificationMode;
  /** A value of 0 means these notifications are turned off. */
  numMinutesForOfflineNotification: Scalars['Int']['output'];
};

export type PetBehaviorSummaryInput = {
  duration: BehaviorSummaryDuration;
  type: BehaviorType;
};

export type PetBehaviorSummaryResult = {
  __typename?: 'PetBehaviorSummaryResult';
  /**
   * For 24h summaries this will have up to 144 entries (one per 10 minute window).
   * For 30d summaries this will have up to 720 entries (one per hour).
   */
  data: Array<BehaviorWindow>;
  startTimeUtc: Scalars['DateTime']['output'];
};

export enum PetChangeType {
  CARD = 'CARD',
  CURRENT_ACTIVITY = 'CURRENT_ACTIVITY',
  LOCATION = 'LOCATION',
  MODULE_INFO = 'MODULE_INFO',
  ONGOING_ACTIVITY = 'ONGOING_ACTIVITY',
  STATUS = 'STATUS',
  TEST = 'TEST',
}

/** Represents a pet/collar combo's synthesized state at a given moment in time */
export type PetCollarState = {
  /** The moment in time the state represents */
  timestamp: Scalars['DateTime']['output'];
};

export type PetDailyGoalStreakBonus = {
  __typename?: 'PetDailyGoalStreakBonus';
  /** The number of bonus points earned for meeting this goal */
  bonusPoints: Scalars['Int']['output'];
  /** The number of days in a row that pet must meat their step goal to qualify for this bonus */
  streakLengthRequirement: Scalars['Int']['output'];
  /** Indicates whether the pet has or has not met this streak goal */
  streakMet: Scalars['Boolean']['output'];
};

export type PetDiscoverFeed = {
  __typename?: 'PetDiscoverFeed';
  feedItems: Array<PetDiscoverFeedItem>;
  pageInfo: PageInfo;
};

export type PetDiscoverFeedItem = DiscoverFeedPhotoItem | DiscoverFeedTextPostItem | DiscoverFeedVideoPostItem;

export type PetDocument = {
  __typename?: 'PetDocument';
  date: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  fileSizeBytes: Scalars['Int']['output'];
  fileType: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isUnseen: Scalars['Boolean']['output'];
  labels?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  status: PetDocumentStatus;
  summary?: Maybe<Scalars['String']['output']>;
  totalCostCents?: Maybe<Scalars['Int']['output']>;
  type: PetDocumentType;
};

export type PetDocumentFeed = {
  __typename?: 'PetDocumentFeed';
  documents: Array<PetDocument>;
  pageInfo: PageInfo;
};

export enum PetDocumentStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
}

export enum PetDocumentType {
  DOCUMENT = 'DOCUMENT',
  PHOTO = 'PHOTO',
}

export type PetHomeLocation = {
  __typename?: 'PetHomeLocation';
  city?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type PetInsight = {
  __typename?: 'PetInsight';
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  graph: PetInsightGraph;
  id: Scalars['ID']['output'];
  leadingImage: InsightLeadingImage;
  title: Scalars['String']['output'];
};

export type PetInsightGraph = {
  __typename?: 'PetInsightGraph';
  currentPeriodLineHexColor: Scalars['HexColorCode']['output'];
  currentPeriodValues: Array<Scalars['Float']['output']>;
  previousPeriodLineHexColor: Scalars['HexColorCode']['output'];
  previousPeriodValues: Array<Scalars['Float']['output']>;
  showDividingLine: Scalars['Boolean']['output'];
};

export enum PetNotificationMode {
  SENSITIVE = 'SENSITIVE',
  SMART = 'SMART',
}

export type PetNotificationSetting = {
  __typename?: 'PetNotificationSetting';
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  id: Scalars['ID']['output'];
  pushEligible: Scalars['Boolean']['output'];
  pushEnabled: Scalars['Boolean']['output'];
  settingType: PetNotificationSettingType;
  smsEligible: Scalars['Boolean']['output'];
  smsEnabled: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export enum PetNotificationSettingCategory {
  COLLAR_ALERTS = 'COLLAR_ALERTS',
  SOCIAL = 'SOCIAL',
}

export type PetNotificationSettingInput = {
  pushEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  settingType: PetNotificationSettingType;
  smsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PetNotificationSettingType {
  BATTERY = 'BATTERY',
  BIRTHDAY_CARD = 'BIRTHDAY_CARD',
  END_OF_WALK = 'END_OF_WALK',
  ESCAPE = 'ESCAPE',
  FOLLOWED = 'FOLLOWED',
  FOLLOWED_PET_PHOTO_ADDED = 'FOLLOWED_PET_PHOTO_ADDED',
  MODULE_OFFLINE = 'MODULE_OFFLINE',
  NEARBY_PET_JOINED_FI = 'NEARBY_PET_JOINED_FI',
  NEW_PETS_JOINED_PACK = 'NEW_PETS_JOINED_PACK',
  PHOTO_COMMENT = 'PHOTO_COMMENT',
  PHOTO_LIKE = 'PHOTO_LIKE',
  RANKING = 'RANKING',
  SAFE_ZONE = 'SAFE_ZONE',
  STREAK = 'STREAK',
  TEXT_POST_COMMENT = 'TEXT_POST_COMMENT',
  TEXT_POST_LIKE = 'TEXT_POST_LIKE',
  VIDEO_POST_COMMENT = 'VIDEO_POST_COMMENT',
  VIDEO_POST_LIKE = 'VIDEO_POST_LIKE',
  WALK_LIKE = 'WALK_LIKE',
  WALK_WITH_OWNER = 'WALK_WITH_OWNER',
}

export enum PetRankFilter {
  AGE = 'AGE',
  BREED = 'BREED',
}

export type PetRanking = {
  __typename?: 'PetRanking';
  breedRankNumber?: Maybe<Scalars['Int']['output']>;
  breedRankPercentile?: Maybe<Scalars['Int']['output']>;
  overallRankNumber?: Maybe<Scalars['Int']['output']>;
  overallRankPercentile?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Might need to be totalStepsInRankedPeriod instead? */
  stepCount?: Maybe<Scalars['Int']['output']>;
};

export type PetStreakBonusSummary = {
  __typename?: 'PetStreakBonusSummary';
  bonuses: Array<PetDailyGoalStreakBonus>;
  daysUntilNextBonus: Scalars['Int']['output'];
};

export type PetUpdate = {
  __typename?: 'PetUpdate';
  changeType: PetChangeType;
};

export type PhoneSupport = {
  __typename?: 'PhoneSupport';
  /** The name of the digital support line in ZenDesk. */
  digitalSupportLineName?: Maybe<Scalars['String']['output']>;
  /** Indicates that user is eligible for phone support. Note that they may still require upgrade. */
  eligibleForSupport: Scalars['Boolean']['output'];
  /**
   * Phone support number.
   *
   * If null, indicates the user has no phone support option.
   */
  phoneSupportNumber?: Maybe<Scalars['String']['output']>;
  /** If true, the client should display the upgrade flow, rather than directly calling. */
  shouldPromptUpgrade: Scalars['Boolean']['output'];
  /** URL to open in webview for upgrade flow if applicable. */
  upgradeWebviewUrl?: Maybe<Scalars['String']['output']>;
};

export type Photo = {
  __typename?: 'Photo';
  aspectRatio: AspectRatio;
  caption?: Maybe<Scalars['String']['output']>;
  comments: CommentFeed;
  date: Scalars['DateTime']['output'];
  /** Admin-only */
  dbId: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  externalLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Image;
  isCommunityPhoto?: Maybe<Scalars['Boolean']['output']>;
  likeCount: Scalars['Int']['output'];
  /** Whether the current user has liked this photo. */
  liked: Scalars['Boolean']['output'];
  likedByPets: Array<StrangerPet>;
  /** Pet associated with this photo, if any. */
  pet?: Maybe<StrangerPet>;
  poster: PhotoPoster;
  publicShareLink?: Maybe<Scalars['String']['output']>;
  source?: Maybe<PhotoSource>;
};

export type PhotoFeed = {
  __typename?: 'PhotoFeed';
  feed: PaginatedPhotoFeed;
  first?: Maybe<Photo>;
  /**
   * items returns an unbounded list. Instead, please use `feed` which implements our standard paginated feed interface
   * @deprecated Use `feed` instead
   */
  items: Array<Photo>;
};

export type PhotoFeedfeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type PhotoModerationFeed = ModerationFeed & {
  __typename?: 'PhotoModerationFeed';
  items: Array<ModerationPhotoItem>;
  totalCount: Scalars['Int']['output'];
};

export type PhotoPoster = ExternalUser | StrangerUser;

export enum PhotoSource {
  APP = 'APP',
  IG_HASHTAG = 'IG_HASHTAG',
  IG_PROFILE_TAG = 'IG_PROFILE_TAG',
}

export type PhotoUpdatesInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated */
  isCommunityPhoto?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Place = {
  __typename?: 'Place';
  address?: Maybe<Scalars['String']['output']>;
  /** Should only be populated for polygon safe-zones. */
  boundary?: Maybe<Scalars['JSON']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Array<Position>>;
  dbId: Scalars['Int']['output'];
  geocodeAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  position: Position;
  /**
   * Circle safe-zones have a real value here.
   * Polygon safe-zones have a hardcoded fake value (clients should ignore).
   */
  radius: Scalars['Float']['output'];
  /** Allows clients to differentiate between circle and polygon safe-zones. */
  type: PlaceType;
};

export enum PlaceType {
  CIRCLE = 'CIRCLE',
  POLYGON = 'POLYGON',
}

export type PlanMini = {
  __typename?: 'PlanMini';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
};

export type Platform = {
  __typename?: 'Platform';
  attributes: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  updateChannels: Array<FirmwareUpdateChannel>;
};

export type Play = Activity & {
  __typename?: 'Play';
  address?: Maybe<Scalars['String']['output']>;
  allPositionsForDebug?: Maybe<Array<Position>>;
  areaName?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  dbId: Scalars['Int']['output'];
  debugMetadata: Scalars['JSON']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use isLikable */
  isClappable: Scalars['Boolean']['output'];
  isLikable: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  mapUrl: Scalars['String']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  overallStepsPerMinuteForDebug: Scalars['Float']['output'];
  place?: Maybe<Place>;
  position: Position;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type PlaymapUrlArgs = {
  height: Scalars['Int']['input'];
  scale?: InputMaybe<Scalars['Int']['input']>;
  style?: InputMaybe<MapStyle>;
  width: Scalars['Int']['input'];
};

export type PoorLTEConnectivityDeviceInfo = LowLTESignalDeviceInfo | NoLTEConnectivityDeviceInfo;

export type Position = {
  __typename?: 'Position';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type PositionInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type PowerStateChangeDeviceEvent = DeviceEventBase & {
  __typename?: 'PowerStateChangeDeviceEvent';
  date: Scalars['DateTime']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  new: DevicePowerState;
  old: DevicePowerState;
  platform: Scalars['String']['output'];
};

export enum PreSignedUploadPurpose {
  COMMUNITY_VIDEO_POST = 'COMMUNITY_VIDEO_POST',
  PET_DOCUMENT = 'PET_DOCUMENT',
}

export type PreSignedUploadUrl = {
  __typename?: 'PreSignedUploadUrl';
  expiration: Scalars['DateTime']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PreSignedUploadUrlInput = {
  contentType: Scalars['String']['input'];
  purpose: PreSignedUploadPurpose;
};

export type PreviewMembershipUpgradeResult = {
  __typename?: 'PreviewMembershipUpgradeResult';
  appliedAccountBalanceInCents: Scalars['Int']['output'];
  appliedDiscountInCents: Scalars['Int']['output'];
  proratedCreditAmountInCents: Scalars['Int']['output'];
  subtotalInCents: Scalars['Int']['output'];
  taxInCents: Scalars['Int']['output'];
  totalInCents: Scalars['Int']['output'];
};

export type PreviewPurchaseResult = {
  __typename?: 'PreviewPurchaseResult';
  activationFeesInCents: Scalars['Int']['output'];
  appliedDiscountInCents: Scalars['Int']['output'];
  shippingInCents: Scalars['Int']['output'];
  subtotalInCents: Scalars['Int']['output'];
  taxInCents: Scalars['Int']['output'];
  totalInCents: Scalars['Int']['output'];
};

export type PreviewSubscriptionResult = {
  __typename?: 'PreviewSubscriptionResult';
  activationFeeInCents?: Maybe<Scalars['Int']['output']>;
  couponAmountInCents?: Maybe<Scalars['Int']['output']>;
  /** Positive integer indicating the value of the gift card, if one was applied. */
  giftCardAmountInCents?: Maybe<Scalars['Int']['output']>;
  taxInCents: Scalars['Int']['output'];
  totalInCents: Scalars['Int']['output'];
};

export type Principal = {
  __typename?: 'Principal';
  capabilities: Array<PrincipalCapability>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
};

export enum PrincipalCapability {
  ADMIN = 'ADMIN',
  CREATE_ORDERS = 'CREATE_ORDERS',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  FICOMMERCE = 'FICOMMERCE',
  FIRMWARE_PROD = 'FIRMWARE_PROD',
  FIRMWARE_TEST = 'FIRMWARE_TEST',
  IMPORT_ORDERS = 'IMPORT_ORDERS',
  IN_APP_CONTENT_AUTHORING = 'IN_APP_CONTENT_AUTHORING',
  NEWS_NOTIFICATION = 'NEWS_NOTIFICATION',
  PUBLIC_PET_PROFILE = 'PUBLIC_PET_PROFILE',
  RMA = 'RMA',
}

export type Product = {
  __typename?: 'Product';
  /** Decompose into component pieces given the input sub item codes. */
  decompose: Array<Product>;
  description: Scalars['String']['output'];
  eligibleForRma: Scalars['Boolean']['output'];
  flagExchangeForProductReturn: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  optionsForRequiredBundledSubscription: Array<SubscriptionOption>;
  priceInCents: Scalars['Int']['output'];
  /**
   * When this is a physical product subscription, product to ship will be the product
   * that is shipped to the customer.
   */
  productToShip?: Maybe<Product>;
  sku: Scalars['String']['output'];
  subItems: Array<ReturnsTrackerSubItem>;
};

export type ProductdecomposeArgs = {
  subItemCodes: Array<Scalars['String']['input']>;
};

export type ProductoptionsForRequiredBundledSubscriptionArgs = {
  onlyAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductInventoryLevel = {
  __typename?: 'ProductInventoryLevel';
  /** @deprecated Will no longer be used */
  allowAutoMarkOutOfStock: Scalars['Boolean']['output'];
  /** @deprecated Query inventoryConfigurations instead */
  eligibleForJustInTimeKitting: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Will no longer be used */
  inStock: Scalars['Boolean']['output'];
  inStockAtAnyWarehouse: Scalars['Boolean']['output'];
  /** @deprecated Query inventoryConfigurations instead */
  justInTimeKittingEnabled: Scalars['Boolean']['output'];
  /** @deprecated Will no longer be used */
  manualInventoryAdjustment: Scalars['Int']['output'];
  /** @deprecated Will no longer be used */
  outOfStockThreshold: Scalars['Int']['output'];
  productDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use sources instead */
  quantityAvailable: Scalars['Int']['output'];
  /** @deprecated Use sources instead */
  quantityInStock: Scalars['Int']['output'];
  /** @deprecated Use sources instead */
  quantityPending: Scalars['Int']['output'];
  /** @deprecated Use sources instead */
  quantityReserved: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  sources: Array<WarehouseInventoryLevel>;
};

export type ProductInventoryLevels = {
  __typename?: 'ProductInventoryLevels';
  activeWarehouses: Array<FulfillmentWarehouse>;
  configurations: Array<InventoryConfiguration>;
  dateLastUpdated: Scalars['DateTime']['output'];
  levels: Array<ProductInventoryLevel>;
};

export type Promotion = {
  __typename?: 'Promotion';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  internalName: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  variants: Scalars['JSON']['output'];
};

export type PublicProfileSharing = {
  __typename?: 'PublicProfileSharing';
  url?: Maybe<Scalars['String']['output']>;
};

export type PurchaseResultInvoice = {
  __typename?: 'PurchaseResultInvoice';
  number: Scalars['String']['output'];
};

export type PurchaseSubscriptionInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  moduleId: Scalars['ID']['input'];
  redeemGiftCard?: InputMaybe<RedeemGiftCardInput>;
  sku: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  admin: Admin;
  adminGetDismissedMobileAppContent: Array<DismissedMobileAppContent>;
  /**
   * Admin Get a Pre-Signed URL for File Upload
   * This is a global mutation that can be used to get a pre-signed URL for any supported purpose
   */
  adminGetPreSignedUploadUrl: PreSignedUploadUrl;
  adminGetZoomVideoSdkToken?: Maybe<ZoomVideoSdkToken>;
  adminListAppIntegrationsForUser: Array<AppIntegration>;
  adminPreviewOrder: PreviewPurchaseResult;
  adminSearchDogParks: DogParkFeed;
  adminSearchResellerModules: Array<ResellerModule>;
  allBreeds: Array<Breed>;
  allDogParks: DogParkFeed;
  allNanoBatches: Array<NanoWebIdBatchInfo>;
  article?: Maybe<Article>;
  articles: Array<Article>;
  assignedChargingBase?: Maybe<ChargingBase>;
  /** @deprecated Used for legacy in-app subscription purchasing. Use Device.purchasableSubscriptionOptions instead. */
  availableSubscriptionOptions: Array<SubscriptionOption>;
  birthdayCardForPet: BirthdayCard;
  /** Paginates through Packs alphabetically. */
  browsePacks: BrowsePacksResponse;
  browsePopularPacks: BrowsePacksResponse;
  chargingBase?: Maybe<ChargingBase>;
  chargingBaseOfflineDateRanges: Array<DateRange>;
  checkChargingBaseAssignable: ChargingBaseAssignable;
  checkDeviceAssignable: DeviceAssignable;
  checkFiHandleValidAndAvailable?: Maybe<CheckFiHandleValidAndAvailableResponse>;
  checkForAppUpdatePrompt?: Maybe<AppUpdatePrompt>;
  chip?: Maybe<BaseChip>;
  chipReseller?: Maybe<ChipReseller>;
  clientUrls: ClientUrls;
  commentEventHistory: Array<AuditLogEvent>;
  contactsForUser: ContactsForUserResponse;
  currentPrincipal: Principal;
  currentUser: User;
  /** Lookup a device by moduleId or dbId. Exactly one of the arguments should be provided. */
  device?: Maybe<Device>;
  /**
   * Fetches a FW update URL for specified module (if update is available). Does
   * not do any household authorization checks, to allow for fetching FW updates
   * for unassigned devices during onboarding.
   */
  deviceFirmwareUpdate?: Maybe<FirmwareUpdate>;
  dogPark?: Maybe<DogPark>;
  dogParkCheckInsForPet: DogParkCheckinFeed;
  dogParkLeaderboard: DogParkVisitorFeed;
  /** @deprecated Dog park notifications were removed. */
  dogParkNotificationSettings?: Maybe<DogParkNotificationSettings>;
  /** @deprecated Dog park SMS notifications were never implemented. */
  dogParkShareableSubscriptionText?: Maybe<DogParkShareableSubscriptionText>;
  dynamicEnum: DynamicEnum;
  fiNewsNotification: FiNewsNotification;
  fiNewsNotificationFeed: FiNewsNotificationFeed;
  findAdministrativeAreas: Array<AdministrativeArea>;
  forwardGeocode: Array<ForwardGeocodeResult>;
  forwardGeocodeCity: Array<ForwardGeocodeCityResult>;
  getAllMobileAppContent: Array<AdminMobileAppContent>;
  getAvailableS3DowngradeableSubscriptionOptions: AvailableS3DowngradeSubscriptionOptionsResult;
  getInsightsForPet: Array<Maybe<PetInsight>>;
  getMobileAppContent: MobileAppContent;
  getMobileAppContentById?: Maybe<AdminMobileAppContent>;
  getMobileAppContentTargetingFacts: Array<MobileAppContentTargetingFact>;
  getOAuthSetupDetails: OAuthSetupDetails;
  getPetDocument: PetDocument;
  getPetDocumentFeed: PetDocumentFeed;
  getPetDocumentLabels: Array<Scalars['String']['output']>;
  /** Get a Pre-Signed URL for File Upload */
  getPreSignedUploadUrl: PreSignedUploadUrl;
  getSharingSession: SharingSession;
  getUserOAuthStatus: Scalars['Boolean']['output'];
  /**
   * In-app screen sharing.
   *
   * Regular users should call getZoomVideoSdkToken and start a session
   * with the returned result.
   * For Admins, call adminGetZoomVideoSdkToken with the userId of the user whose session you want to join.
   */
  getZoomVideoSdkToken?: Maybe<ZoomVideoSdkToken>;
  gnssFixFailures: Array<GnssFixFailure>;
  handsetLogs: Array<HandsetLogDocument>;
  households?: Maybe<Array<Household>>;
  inAppPromotionBanner?: Maybe<InAppPromotionBanner>;
  instagramUser: InstagramUser;
  listAppIntegrations: Array<AppIntegration>;
  missedLocationReports: Array<MissedLocationReport>;
  moderationPhotoFromCommentItem: ModerationPhotoFromCommentItem;
  moderationTextPostFromCommentItem: ModerationTextPostFromCommentItem;
  multidogHouseholdPromotionBanner?: Maybe<MultidogHouseholdPromotionBanner>;
  multipleModerationFeeds: MultipleModerationFeed;
  nanoBatch: Array<NanoWebId>;
  nearbyDogParks: NearbyDogParkFeed;
  nearbyPets: NearbyPetsFeed;
  notificationLogs: Array<NotificationLogDocument>;
  notificationTypeExampleMessages: Array<Scalars['String']['output']>;
  onboardingSessionLogs: Array<HandsetLogDocument>;
  onboardingSessions: Array<OnboardingSession>;
  order: Order;
  orderAuditLogs: Array<AuditLogEvent>;
  orders: Array<Order>;
  pack?: Maybe<Pack>;
  packMemberFeed: PackMemberFeed;
  pet?: Maybe<Pet>;
  /** Returns all pets with active (Recurly) subscriptions for the current user. */
  petsWithActiveSubscription: Array<Pet>;
  photo?: Maybe<Photo>;
  poorLTEConnectivityDeviceInfo: Array<PoorLTEConnectivityDeviceInfo>;
  previewAddOrderLineItems: PreviewPurchaseResult;
  previewMembershipUpgrade: PreviewMembershipUpgradeResult;
  previewSubscription: PreviewSubscriptionResult;
  prioritizedBackorders: Array<Order>;
  product: Product;
  products: Array<Product>;
  publicPetProfile?: Maybe<StrangerPet>;
  publicPetProfileHandles?: Maybe<Array<Scalars['String']['output']>>;
  publicPetProfiles: StrangerPetFeed;
  recentCommunityPhotos: PhotoFeed;
  recentDogParkVisitors: DogParkVisitorFeed;
  recentNotificationCountsByType: Array<NotificationCountByType>;
  /** One of `serialNumber` or `invoiceNumber` must be specified */
  recurlyInvoice: RecurlyInvoice;
  renewSubscriptionBanner?: Maybe<RenewSubscriptionBanner>;
  resellerChip?: Maybe<ResellerChip>;
  returnsTrackerSubItems: Array<ReturnsTrackerSubItem>;
  reverseGeocode?: Maybe<ReverseGeocodeResult>;
  rma: RMA;
  rmas: RMAFeed;
  searchFiHandle: SearchFiHandleResult;
  searchPacks: SearchPacksResponse;
  searchPets: Array<Pet>;
  searchVets: Array<Veterinary>;
  series3UpgradeOffer?: Maybe<Series3UpgradeOffer>;
  /**
   * Either both `breedId` and `ageRange` should be provided to return the step count
   * distribution for that breed and age range, or both should be null in which case
   * the step count distribution for all Fi pets is returned.
   */
  stepCountDistribution?: Maybe<StepCountDistribution>;
  strangerPet?: Maybe<StrangerPet>;
  subscription: BillingSubscription;
  suggestedPacks: SuggestedPacksResult;
  /** Pets to display in Discover tab Search. */
  suggestedPets: SuggestedPetResult;
  /** Pets to display in Discover tab carousel. */
  suggestedPetsToFollow: SuggestedPetResult;
  /** Pets in user's contacts ordered by creation date. */
  suggestedPetsToFollowFromContacts: SuggestedPetResult;
  textPost?: Maybe<TextPost>;
  textPostStyles: Array<TextPostStyle>;
  user?: Maybe<AdminBaseUserProfile>;
  userPetsAtDogPark: DogParkVisitorFeed;
  validateAddress: ValidateAddressResponse;
  videoPost?: Maybe<VideoPost>;
};

export type QueryadminGetDismissedMobileAppContentArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryadminGetPreSignedUploadUrlArgs = {
  input: AdminPreSignedUploadUrlInput;
};

export type QueryadminGetZoomVideoSdkTokenArgs = {
  input: AdminGetZoomVideoSdkTokenInput;
};

export type QueryadminListAppIntegrationsForUserArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryadminPreviewOrderArgs = {
  input: AdminPreviewOrderInput;
};

export type QueryadminSearchDogParksArgs = {
  input: AdminSearchDogParksInput;
};

export type QueryadminSearchResellerModulesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<ResellerModuleQueryInput>;
};

export type QueryarticleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryarticlesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryassignedChargingBaseArgs = {
  baseId: Scalars['String']['input'];
};

export type QueryavailableSubscriptionOptionsArgs = {
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerybirthdayCardForPetArgs = {
  petId: Scalars['ID']['input'];
};

export type QuerybrowsePacksArgs = {
  limit: Scalars['Int']['input'];
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type QuerybrowsePopularPacksArgs = {
  category?: InputMaybe<PackCategory>;
  limit: Scalars['Int']['input'];
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type QuerychargingBaseArgs = {
  baseId: Scalars['String']['input'];
};

export type QuerychargingBaseOfflineDateRangesArgs = {
  chargingBaseId: Scalars['ID']['input'];
  dateRange: DateRangeInput;
};

export type QuerycheckChargingBaseAssignableArgs = {
  baseId: Scalars['String']['input'];
};

export type QuerycheckDeviceAssignableArgs = {
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerycheckFiHandleValidAndAvailableArgs = {
  proposedHandle: Scalars['String']['input'];
};

export type QuerychipArgs = {
  id: Scalars['ID']['input'];
};

export type QuerychipResellerArgs = {
  id: Scalars['ID']['input'];
};

export type QuerycommentEventHistoryArgs = {
  commentId: Scalars['ID']['input'];
};

export type QuerycontactsForUserArgs = {
  input: ContactsForUserInput;
};

export type QuerydeviceArgs = {
  dbId?: InputMaybe<Scalars['Int']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerydeviceFirmwareUpdateArgs = {
  currentVersion: Scalars['String']['input'];
  moduleId: Scalars['String']['input'];
};

export type QuerydogParkArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydogParkCheckInsForPetArgs = {
  input: DogParkCheckinsForPetInput;
};

export type QuerydogParkLeaderboardArgs = {
  dogParkId: Scalars['ID']['input'];
};

export type QuerydogParkNotificationSettingsArgs = {
  dogParkId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerydogParkShareableSubscriptionTextArgs = {
  dogParkId: Scalars['ID']['input'];
};

export type QuerydynamicEnumArgs = {
  kind: DynamicEnumKind;
};

export type QueryfiNewsNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryfindAdministrativeAreasArgs = {
  level: AdminLevel;
  position: PositionInput;
};

export type QueryforwardGeocodeArgs = {
  partialAddress: Scalars['String']['input'];
  proximity?: InputMaybe<PositionInput>;
};

export type QueryforwardGeocodeCityArgs = {
  proximity?: InputMaybe<PositionInput>;
  query: Scalars['String']['input'];
};

export type QuerygetAvailableS3DowngradeableSubscriptionOptionsArgs = {
  input: AvailableS3DowngradeSubscriptionOptionsInput;
};

export type QuerygetInsightsForPetArgs = {
  petId: Scalars['ID']['input'];
};

export type QuerygetMobileAppContentArgs = {
  petId: Scalars['ID']['input'];
};

export type QuerygetMobileAppContentByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOAuthSetupDetailsArgs = {
  app: Scalars['String']['input'];
};

export type QuerygetPetDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetPetDocumentFeedArgs = {
  input: GetPetDocumentsInput;
};

export type QuerygetPreSignedUploadUrlArgs = {
  input: PreSignedUploadUrlInput;
};

export type QuerygetSharingSessionArgs = {
  petId: Scalars['ID']['input'];
};

export type QuerygetUserOAuthStatusArgs = {
  app: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type QuerygnssFixFailuresArgs = {
  dateRange: DateRangeInput;
  moduleId: Scalars['ID']['input'];
};

export type QueryhandsetLogsArgs = {
  dateRange: DateRangeInput;
  logTypes?: InputMaybe<Array<HandsetLogType>>;
  userId: Scalars['ID']['input'];
};

export type QueryhouseholdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryinAppPromotionBannerArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryinstagramUserArgs = {
  token: Scalars['String']['input'];
};

export type QuerymissedLocationReportsArgs = {
  dateRange: DateRangeInput;
  moduleId: Scalars['ID']['input'];
};

export type QuerymoderationPhotoFromCommentItemArgs = {
  photoId: Scalars['ID']['input'];
};

export type QuerymoderationTextPostFromCommentItemArgs = {
  textPostId: Scalars['ID']['input'];
};

export type QuerymultipleModerationFeedsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: ModerationFeedQueryInput;
};

export type QuerynanoBatchArgs = {
  input: AdminQueryNanoBatchInput;
};

export type QuerynearbyDogParksArgs = {
  position: PositionInput;
};

export type QuerynearbyPetsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  radiusInMeters?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerynotificationLogsArgs = {
  dateRange: DateRangeInput;
  userId: Scalars['ID']['input'];
};

export type QuerynotificationTypeExampleMessagesArgs = {
  notificationType: Scalars['String']['input'];
};

export type QueryonboardingSessionLogsArgs = {
  dateRange: DateRangeInput;
  userId: Scalars['ID']['input'];
};

export type QueryonboardingSessionsArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryorderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryorderAuditLogsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryordersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<OrdersQueryInput>;
};

export type QuerypackArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypackMemberFeedArgs = {
  adjacentPetId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  packAgeFilter?: InputMaybe<PackAgeFilter>;
  packId: Scalars['ID']['input'];
  pagingInstruction?: InputMaybe<PagingInstruction>;
  validityToken?: InputMaybe<Scalars['String']['input']>;
};

export type QuerypetArgs = {
  id: Scalars['ID']['input'];
};

export type QueryphotoArgs = {
  id: Scalars['ID']['input'];
};

export type QuerypoorLTEConnectivityDeviceInfoArgs = {
  dateRange: DateRangeInput;
  moduleId: Scalars['ID']['input'];
};

export type QuerypreviewAddOrderLineItemsArgs = {
  input: AddOrderLineItemsInput;
};

export type QuerypreviewMembershipUpgradeArgs = {
  input: UpgradeMembershipInput;
};

export type QuerypreviewSubscriptionArgs = {
  input: PurchaseSubscriptionInput;
};

export type QueryproductArgs = {
  sku: Scalars['String']['input'];
};

export type QueryproductsArgs = {
  noDiscontinued?: InputMaybe<Scalars['Boolean']['input']>;
  noNoLongerManufactured?: InputMaybe<Scalars['Boolean']['input']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  onlyPhysical?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerypublicPetProfileArgs = {
  handle: Scalars['String']['input'];
};

export type QuerypublicPetProfilesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type QueryrecentCommunityPhotosArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryrecentDogParkVisitorsArgs = {
  dogParkId: Scalars['ID']['input'];
};

export type QueryrecurlyInvoiceArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryresellerChipArgs = {
  dbId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryreverseGeocodeArgs = {
  position: PositionInput;
};

export type QueryrmaArgs = {
  id: Scalars['ID']['input'];
};

export type QueryrmasArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<RMAQueryInput>;
};

export type QuerysearchFiHandleArgs = {
  position?: InputMaybe<PositionInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QuerysearchPacksArgs = {
  category?: InputMaybe<PackCategory>;
  query: Scalars['String']['input'];
};

export type QuerysearchPetsArgs = {
  position?: InputMaybe<PositionInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QuerysearchVetsArgs = {
  proximity?: InputMaybe<PositionInput>;
  query: Scalars['String']['input'];
};

export type Queryseries3UpgradeOfferArgs = {
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerystepCountDistributionArgs = {
  ageRange?: InputMaybe<AgeRange>;
  breedId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerystrangerPetArgs = {
  householdId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type QuerysubscriptionArgs = {
  id: Scalars['String']['input'];
};

export type QuerysuggestedPetsArgs = {
  categoryTypes?: InputMaybe<Array<SuggestedPetCategoryType>>;
};

export type QuerytextPostArgs = {
  id: Scalars['ID']['input'];
};

export type QueryuserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryuserPetsAtDogParkArgs = {
  dogParkId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryvalidateAddressArgs = {
  address: AddressInput;
};

export type QueryvideoPostArgs = {
  id: Scalars['ID']['input'];
};

export enum QuickReportModeState {
  NO_CHANGE = 'NO_CHANGE',
  TURN_OFF = 'TURN_OFF',
  TURN_ON = 'TURN_ON',
}

export type RMA = {
  __typename?: 'RMA';
  checkedInAt?: Maybe<Scalars['DateTime']['output']>;
  checkedInBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  customerEmail: Scalars['String']['output'];
  customerName: Scalars['String']['output'];
  drilldown?: Maybe<DynamicEnumMember>;
  exchangeOrder?: Maybe<Order>;
  exchangeRecurlyInvoiceNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceSource?: Maybe<InvoiceSource>;
  items: Array<RMAItem>;
  notes: Array<RMANote>;
  processType: RMAProcessType;
  purchaseProvenance?: Maybe<DynamicEnumMember>;
  reason: DynamicEnumMember;
  refundStatus?: Maybe<RefundStatus>;
  shippingLabelUrl?: Maybe<Scalars['String']['output']>;
  status: RMAStatus;
  tags: Array<RMATag>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /**
   * Note: this is a StrangerUser not a User since RMAs can be accessed by
   * less privileged users. Don't want to introduce a privilege escalation.
   */
  user?: Maybe<StrangerUser>;
  wasUpgrade: Scalars['Boolean']['output'];
  zendeskNumber?: Maybe<Scalars['String']['output']>;
};

export type RMAFeed = {
  __typename?: 'RMAFeed';
  items: Array<RMA>;
  /** Total count of all RMAs, doesn't take into account your query. */
  totalCount: Scalars['Int']['output'];
};

export type RMAItem = {
  __typename?: 'RMAItem';
  checkInCondition?: Maybe<RMAItemCondition>;
  hardwareDefects: Array<HardwareDefect>;
  id: Scalars['ID']['output'];
  lineItemId?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Product>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  stockpileAssignmentDescription?: Maybe<Scalars['String']['output']>;
  stockpileAssignmentName?: Maybe<Scalars['String']['output']>;
  subItem?: Maybe<ReturnsTrackerSubItem>;
};

export enum RMAItemCondition {
  HEAVILY_USED = 'HEAVILY_USED',
  LIGHTLY_USED = 'LIGHTLY_USED',
  MISSING = 'MISSING',
  NEW = 'NEW',
  UNACCEPTABLE = 'UNACCEPTABLE',
}

export type RMAItemInput = {
  hardwareDefectCodes: Array<Scalars['String']['input']>;
  lineItemId?: InputMaybe<Scalars['String']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  subItemCode?: InputMaybe<Scalars['String']['input']>;
};

export type RMANote = {
  __typename?: 'RMANote';
  attributes: Scalars['JSON']['output'];
  body: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  initiatedBy?: Maybe<Scalars['String']['output']>;
  noteType: Scalars['String']['output'];
};

export type RMAPolicyResult = {
  __typename?: 'RMAPolicyResult';
  message: Scalars['String']['output'];
  requiresException: Scalars['Boolean']['output'];
  triggersNotification: Scalars['Boolean']['output'];
  type: RMAPolicyResultType;
};

export enum RMAPolicyResultType {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  UNKNOWN = 'UNKNOWN',
}

export enum RMAProcessType {
  RETURN = 'RETURN',
  SHIPPING_ERROR = 'SHIPPING_ERROR',
  UNKNOWN_RETURN = 'UNKNOWN_RETURN',
  WARRANTY_EXCHANGE = 'WARRANTY_EXCHANGE',
}

export type RMAQueryInput = {
  agent?: InputMaybe<Scalars['String']['input']>;
  customerNameOrEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  rmaNumber?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  zendeskNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum RMAStatus {
  AWAITING_RETURN = 'AWAITING_RETURN',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  LOST_PACKAGE = 'LOST_PACKAGE',
}

export enum RMATag {
  CUSTOMER_DISCARD = 'CUSTOMER_DISCARD',
  INSPECT_ON_ARRIVAL = 'INSPECT_ON_ARRIVAL',
}

export type RandomPercentageBooleanSetting = BooleanSetting & {
  __typename?: 'RandomPercentageBooleanSetting';
  floatPercentage: Scalars['Float']['output'];
  platformOverrides: Array<BooleanSettingPlatformOverride>;
};

export type RankChange = {
  __typename?: 'RankChange';
  /** e.g. 4, -5, 0 */
  amount: Scalars['Int']['output'];
};

export type RankedPetsResponse = {
  __typename?: 'RankedPetsResponse';
  pageInfo: PageInfo;
  pets: Array<BasePet>;
  tokenValidity: TokenValidity;
};

export type RebootDeviceEvent = DeviceEventBase & {
  __typename?: 'RebootDeviceEvent';
  date: Scalars['DateTime']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  oldUptime: Scalars['Float']['output'];
  platform: Scalars['String']['output'];
};

export type RecentlyPurchasedItemsResponse = {
  __typename?: 'RecentlyPurchasedItemsResponse';
  lineItems: Array<LineItem>;
};

export type RecentlySeenBaseInput = {
  baseId: Scalars['String']['input'];
  rssi?: InputMaybe<Scalars['Int']['input']>;
};

export type RecordAppInstallForUserInput = {
  app: AppIntegrationId;
  isInstalled: Scalars['Boolean']['input'];
  /** For admin/CX use */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RecordAppInstallForUserResult = {
  __typename?: 'RecordAppInstallForUserResult';
  /** Populated when success is false */
  reason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RecurlyInvoice = {
  __typename?: 'RecurlyInvoice';
  account: BillingAccount;
  createdAt: Scalars['DateTime']['output'];
  /**
   * The date that the package associated with the shipment associated with this invoice
   * was delivered. If null, it means that either the package hasn't been delivered yet,
   * or we could not find/determine the shipment.
   */
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lineItems: Array<LineItem>;
  number: Scalars['String']['output'];
  /** Are the items in this invoice within the 14-day return window? */
  returnEligibility: RMAPolicyResult;
  subtotal: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  /** Are the items in this invoice under warranty? */
  warrantyStatus: RMAPolicyResult;
};

export type RecurlyInvoicereturnEligibilityArgs = {
  drilldownCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
};

export type RecurlyInvoicewarrantyStatusArgs = {
  drilldownCode: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RedeemGiftCardInput = {
  redemptionCode: Scalars['String']['input'];
};

export type ReferralInput = {
  referralCode: Scalars['String']['input'];
};

export type ReferralMenuBannerSettings = {
  __typename?: 'ReferralMenuBannerSettings';
  isEnabled: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum ReferralProgramName {
  Give1MonthGet1DollarPerMonthNoLimit = 'Give1MonthGet1DollarPerMonthNoLimit',
  Give1MonthGet1DollarPerMonthWithLimit = 'Give1MonthGet1DollarPerMonthWithLimit',
  Give1MonthGet1Month = 'Give1MonthGet1Month',
}

export enum ReferralRewardType {
  DOLLAR_CREDIT = 'DOLLAR_CREDIT',
  FIRST_FREE_BAND = 'FIRST_FREE_BAND',
  ONE_MONTH_EXTENSION = 'ONE_MONTH_EXTENSION',
  ONE_YEAR_EXTENSION = 'ONE_YEAR_EXTENSION',
  SECOND_FREE_BAND = 'SECOND_FREE_BAND',
  THREE_YEAR_EXTENSION = 'THREE_YEAR_EXTENSION',
  TWO_YEAR_EXTENSION = 'TWO_YEAR_EXTENSION',
}

export type Referrals = {
  __typename?: 'Referrals';
  codeFraudulent: Scalars['Boolean']['output'];
  codeSuspended: Scalars['Boolean']['output'];
  give1MonthGet1DollarPerMonthDetails?: Maybe<Give1MonthGet1DollarPerMonthDetails>;
  invitedReferees: Array<StrangerUser>;
  numConfirmedReferrals: Scalars['Int']['output'];
  numPendingReferrals: Scalars['Int']['output'];
  /**
   * Number of users that have been referred so far.
   * DEPRECATED: This is associated with the V1 legacy referrals system; we're
   * keeping the resolver so that admin can use it.
   */
  numReferred: Scalars['Int']['output'];
  pendingReferrees: Array<AdminBaseUserProfile>;
  pendingReferrerToReward?: Maybe<AdminBaseUserProfile>;
  referralCode: Scalars['String']['output'];
  /** Message that the client should pre-populate the SMS message with. */
  referralMessage: Scalars['String']['output'];
  referralProgramName: Scalars['String']['output'];
  referralProgramTitle: Scalars['String']['output'];
  unlockedRewardTypes: Array<ReferralRewardType>;
};

export enum RefundStatus {
  NOT_REFUNDED = 'NOT_REFUNDED',
  NO_REFUND_NECESSARY = 'NO_REFUND_NECESSARY',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
}

export type RegisterChipResellerInput = {
  contactInfo: ChipContactInfoInput;
  name: Scalars['String']['input'];
  petInterest: ResellerPetInterest;
  type: ResellerType;
};

export type RegisterHandsetInput = {
  deviceToken: Scalars['String']['input'];
};

export type RemoveUserEmailInput = {
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveUserFromHouseholdInput = {
  householdId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveUserFromHouseholdResult = {
  __typename?: 'RemoveUserFromHouseholdResult';
  household: Household;
};

export type RemoveWifiCredentialInput = {
  householdId: Scalars['ID']['input'];
  ssid: Scalars['String']['input'];
};

export type RenderedDebugActivitySegments = {
  __typename?: 'RenderedDebugActivitySegments';
  activities: Array<SimpleDebugActivity>;
};

export type RenewSubscriptionBanner = InAppPromotionBanner & {
  __typename?: 'RenewSubscriptionBanner';
  ctaUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  line1Text: FormatString;
  line2Text: FormatString;
};

export type RenewSubscriptionInput = {
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type RenewSubscriptionResult = {
  __typename?: 'RenewSubscriptionResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ReplacementProductAudit = {
  previousQuantity?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  type: ReplacementProductAuditType;
};

export enum ReplacementProductAuditType {
  ITEM_ADDED = 'ITEM_ADDED',
  ITEM_REMOVED = 'ITEM_REMOVED',
  QUANTITY_CHANGED = 'QUANTITY_CHANGED',
}

export type ReplacementProductInput = {
  id: Scalars['ID']['input'];
  priceOverrideInCents?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
};

export enum ReportType {
  ACTIVITY = 'ACTIVITY',
  LOCATION = 'LOCATION',
  MOBILITY = 'MOBILITY',
}

export type ReportTypeFilterParams = {
  reportType: ReportType;
  sourceBlacklist?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceWhitelist?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RequestEmailVerificationInput = {
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type RequestVerificationCodeInput = {
  phoneNumber: Scalars['String']['input'];
};

export type RescueRecallPetInput = {
  petId: Scalars['ID']['input'];
  unassignBaseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RescueRecallPetResult = {
  __typename?: 'RescueRecallPetResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RescueTransferPetPermanentlyInput = {
  billingAccountInput?: InputMaybe<BillingAccountInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  giftCardRedemptionCode: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  petId: Scalars['ID']['input'];
};

export type RescueTransferPetPermanentlyResult = {
  __typename?: 'RescueTransferPetPermanentlyResult';
  accountCreated: Scalars['Boolean']['output'];
  billingAccount?: Maybe<BillingAccount>;
  newAccountTemporaryPassword?: Maybe<Scalars['String']['output']>;
};

export type RescueTransferPetTemporarilyInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  petId: Scalars['ID']['input'];
};

export type RescueTransferPetTemporarilyResult = {
  __typename?: 'RescueTransferPetTemporarilyResult';
  accountCreated: Scalars['Boolean']['output'];
  newAccountTemporaryPassword?: Maybe<Scalars['String']['output']>;
};

/** A chip with information relevant to a ChipReseller. */
export type ResellerChip = {
  __typename?: 'ResellerChip';
  boxId?: Maybe<Scalars['String']['output']>;
  cartonId?: Maybe<Scalars['String']['output']>;
  chipCondition: ChipCondition;
  /** Admin only */
  contactHistory: Array<ChipContactHistoryEntry>;
  createdAt: Scalars['DateTime']['output'];
  dbId: Scalars['Int']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  latestContactHistoryEntry?: Maybe<ChipContactHistoryEntry>;
  /** @deprecated Use `latestContactHistoryEntry.contactInfo` instead */
  latestContactInfo?: Maybe<ChipContactInfo>;
  manufacturedAt?: Maybe<Scalars['DateTime']['output']>;
  pet?: Maybe<StrangerPet>;
  primaryPetOwner?: Maybe<StrangerUser>;
  reseller?: Maybe<ChipReseller>;
  serialNumber: Scalars['String']['output'];
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  shortId: Scalars['String']['output'];
  status: ChipStatusType;
  transfer?: Maybe<ChipTransfer>;
};

export type ResellerChipFeed = {
  __typename?: 'ResellerChipFeed';
  chips: Array<ResellerChip>;
};

export type ResellerModule = {
  __typename?: 'ResellerModule';
  moduleId: Scalars['String']['output'];
  orderSource: OrderSource;
  shipDate: Scalars['DateTime']['output'];
};

export type ResellerModuleQueryInput = {
  moduleId?: InputMaybe<Scalars['String']['input']>;
  orderSource?: InputMaybe<OrderSource>;
};

export enum ResellerPetInterest {
  DOGS = 'DOGS',
  DOGS_AND_CATS = 'DOGS_AND_CATS',
  OTHER = 'OTHER',
}

export enum ResellerStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum ResellerType {
  BREEDER = 'BREEDER',
  RESCUE = 'RESCUE',
  VET = 'VET',
}

export type ResolveChipTransferInput = {
  chipTransferId: Scalars['ID']['input'];
  /**
   * If status == ACCEPTED and this is set, the transfer will be accepted
   * and also the chip will be associated with the specified pet.
   */
  petId?: InputMaybe<Scalars['ID']['input']>;
  status: ChipTransferStatus;
};

export type Rest = Activity & {
  __typename?: 'Rest';
  address?: Maybe<Scalars['String']['output']>;
  allPositionsForDebug?: Maybe<Array<Position>>;
  areaName?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  dbId: Scalars['Int']['output'];
  debugMetadata: Scalars['JSON']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use isLikable */
  isClappable: Scalars['Boolean']['output'];
  isLikable: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  mapUrl: Scalars['String']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  overallStepsPerMinuteForDebug: Scalars['Float']['output'];
  place?: Maybe<Place>;
  position: Position;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type RestmapUrlArgs = {
  height: Scalars['Int']['input'];
  scale?: InputMaybe<Scalars['Int']['input']>;
  style?: InputMaybe<MapStyle>;
  width: Scalars['Int']['input'];
};

export type RestFeed = {
  __typename?: 'RestFeed';
  nextCursor?: Maybe<Scalars['String']['output']>;
  period: ActivityRestStrainPeriod;
  prevCursor?: Maybe<Scalars['String']['output']>;
  restSummary: RestFeedSummary;
};

/**
 * Describes rests (naps + sleep) and associated metadata (total time, etc.)
 * across the specified Period.
 */
export type RestFeedSummary = {
  __typename?: 'RestFeedSummary';
  /** Maximum rest (sleep + nap) seconds over the period (e.g. maximum rest/month for YEAR). */
  maxRestSeconds: Scalars['Int']['output'];
  /**
   * Average per day over the period (only counting days we have data for).
   *
   * For the DAY period this will be the same as the Total field above.
   * For WEEK/MONTH/YEAR this will be the average per day that we have data for.
   */
  napSecondsAverage?: Maybe<Scalars['Int']['output']>;
  /** Summed over the entire period */
  napSecondsTotal: Scalars['Int']['output'];
  /** Only populated for DAY period */
  overnightRestSummary?: Maybe<OvernightRestSummary>;
  period: ActivityRestStrainPeriod;
  /**
   * One value for each sub-period:
   *   * Day: 24 values, one per hour
   *   * Week: 7 values, one per day
   *   * Month: 28-31 values, one per day
   *   * Year: 12 values, one per month
   */
  restData: Array<RestFeedSummaryData>;
  restSecondsAverage?: Maybe<Scalars['Int']['output']>;
  restSecondsTotal: Scalars['Int']['output'];
  sleepSecondsAverage?: Maybe<Scalars['Int']['output']>;
  sleepSecondsTotal: Scalars['Int']['output'];
  /** Midnight at the beginning of the period */
  start: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type RestFeedSummaryData = {
  __typename?: 'RestFeedSummaryData';
  availability: ActivityRestStrainPeriodDataAvailability;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  napSeconds?: Maybe<Scalars['Int']['output']>;
  /** Sum of nap and sleep seconds. */
  restSeconds?: Maybe<Scalars['Int']['output']>;
  sleepSeconds?: Maybe<Scalars['Int']['output']>;
};

/** Not exposed outside of admin. */
export type RestSegment = {
  __typename?: 'RestSegment';
  end?: Maybe<Scalars['DateTime']['output']>;
  start: Scalars['DateTime']['output'];
  type: RestSegmentType;
};

export enum RestSegmentType {
  ACTIVE = 'ACTIVE',
  OFFLINE = 'OFFLINE',
  OFF_DOG = 'OFF_DOG',
  RESTING = 'RESTING',
}

export type RestSummary = {
  __typename?: 'RestSummary';
  dailyRests: Array<DailyRestSummary>;
  data: RestSummaryData;
  end: Scalars['DateTime']['output'];
  period: ActivitySummaryPeriod;
  start: Scalars['DateTime']['output'];
};

export type RestSummaryData = ConcreteRestSummaryData | UnavailableRestSummaryData;

export type RestSummaryFeed = {
  __typename?: 'RestSummaryFeed';
  nextCursor?: Maybe<Scalars['String']['output']>;
  restSummaries: Array<RestSummary>;
};

export type ResumeSupplementSubscriptionInput = {
  recurlySubscriptionId: Scalars['ID']['input'];
};

export type ResumeSupplementSubscriptionResult = {
  __typename?: 'ResumeSupplementSubscriptionResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ReturnsTrackerSubItem = {
  __typename?: 'ReturnsTrackerSubItem';
  code: Scalars['String']['output'];
  friendlyName: Scalars['String']['output'];
  hasSerialNumber: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  requiredForRefund: Scalars['Boolean']['output'];
};

export type ReverseGeocodeResult = {
  __typename?: 'ReverseGeocodeResult';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  neighborhood?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type RevokeUserInviteInput = {
  inviteCode: Scalars['String']['input'];
};

export type RevokeUserInviteResult = {
  __typename?: 'RevokeUserInviteResult';
  household?: Maybe<Household>;
};

export type S3UpgradePromotionBanner = InAppPromotionBanner & {
  __typename?: 'S3UpgradePromotionBanner';
  backgroundColorHex: Scalars['String']['output'];
  ctaUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  line1Text: FormatString;
  line1TextColorHex: Scalars['String']['output'];
  line2Text: FormatString;
  line2TextColorHex: Scalars['String']['output'];
};

export type SearchFiHandleResult = {
  __typename?: 'SearchFiHandleResult';
  /** @deprecated Always empty */
  packs: Array<Pack>;
  pets: Array<StrangerPet>;
};

export type SearchPacksResponse = {
  __typename?: 'SearchPacksResponse';
  packFeed: PackFeed;
};

export type SeekParameters = {
  before: Scalars['DateTime']['input'];
};

export type SendBackgroundNotificationInput = {
  userId: Scalars['ID']['input'];
};

export type SendNotificationInput = {
  channelId: PetNotificationSettingCategory;
  deepLink?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type SendSMSInput = {
  message: Scalars['String']['input'];
  /** One of `userId` or `phoneNumber` must be set. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** One of `userId` or `phoneNumber` must be set. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Series3UpgradeCartInput = {
  cartItems: Array<ForModuleBundleCartItemInput>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  shippingCode?: InputMaybe<Scalars['String']['input']>;
};

export type Series3UpgradeDeviceOffer = {
  __typename?: 'Series3UpgradeDeviceOffer';
  amountDueTodayInCents: Scalars['Int']['output'];
  currentCollarModel: Scalars['String']['output'];
  device: Device;
  pet: Pet;
  promoDiscounts: Array<Series3UpgradePromoDiscount>;
  proratedCreditAmountInCents: Scalars['Int']['output'];
};

export type Series3UpgradeOffer = {
  __typename?: 'Series3UpgradeOffer';
  deviceOffers: Array<Series3UpgradeDeviceOffer>;
  nextBillDate: Scalars['DateTime']['output'];
  planCode: Scalars['String']['output'];
};

export type Series3UpgradePromoDiscount = {
  __typename?: 'Series3UpgradePromoDiscount';
  discountAmountInCents: Scalars['Int']['output'];
  planCode: Scalars['String']['output'];
};

export enum SetFeatureFlagType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

/**
 * GraphQL doesn't support union types for inputs, so this takes a lassez-faire
 * approach to type safety with all optional fields.
 */
export type SetFeatureFlagValue = {
  booleanFloatPercentage?: InputMaybe<Scalars['Float']['input']>;
  booleanGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  booleanPercentage?: InputMaybe<Scalars['Int']['input']>;
  booleanPlatformOverrides?: InputMaybe<Array<BooleanSettingPlatformOverrideInput>>;
  booleanType?: InputMaybe<BooleanSettingType>;
  booleanUserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  number?: InputMaybe<Scalars['Float']['input']>;
  setDynamicSettingName?: InputMaybe<Scalars['String']['input']>;
  string?: InputMaybe<Scalars['String']['input']>;
  unsetDynamicSettingName?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetFirmwareRolloutResult = {
  __typename?: 'SetFirmwareRolloutResult';
  /** The number of records that were updated. */
  numUpdated: Scalars['Int']['output'];
};

export type SetNextShipmentDateInput = {
  nextShipDate: Scalars['DateTime']['input'];
  recurlySubscriptionId: Scalars['ID']['input'];
};

export type SetNextShipmentDateResult = {
  __typename?: 'SetNextShipmentDateResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SetPetPrimaryPhotoInput = {
  petId: Scalars['ID']['input'];
  photoId: Scalars['ID']['input'];
};

export type SetPrimaryUserEmailInput = {
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type SetRemoteLoggingForFirmwareChannelInput = {
  channelName: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  platform: Scalars['String']['input'];
};

export type SetShippingAddressInput = {
  applyToAll: Scalars['Boolean']['input'];
  recurlySubscriptionId: Scalars['ID']['input'];
  shippingAddress: AddressInput;
};

export type SetShippingAddressResult = {
  __typename?: 'SetShippingAddressResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SetStepGoalForPetInput = {
  dailyStepGoal: Scalars['Int']['input'];
  petId: Scalars['ID']['input'];
};

export type SetStepGoalForPetResult = {
  __typename?: 'SetStepGoalForPetResult';
  pet: Pet;
};

export type SetUserPasswordToRandomStringResult = {
  __typename?: 'SetUserPasswordToRandomStringResult';
  newPassword: Scalars['String']['output'];
};

export type SetUserRoleInHouseholdInput = {
  householdId: Scalars['ID']['input'];
  role: UserHouseholdRole;
  userId: Scalars['ID']['input'];
};

export type SetUserRoleInHouseholdResult = {
  __typename?: 'SetUserRoleInHouseholdResult';
  userHousehold: UserHousehold;
};

export type SharingSession = {
  __typename?: 'SharingSession';
  session?: Maybe<SharingSessionDetails>;
};

export type SharingSessionDetails = {
  __typename?: 'SharingSessionDetails';
  sharingUrl: Scalars['String']['output'];
  /** Total number of visitors to the sharing session */
  visitorCount: Scalars['Int']['output'];
};

export type ShelterluvConfiguration = {
  __typename?: 'ShelterluvConfiguration';
  apiKeyEndsWith: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  fiNanoBackfillCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  lastImportAt?: Maybe<Scalars['DateTime']['output']>;
  thirdPartyChipBackfillCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  thirdPartyChipBackfillEnabled: Scalars['Boolean']['output'];
  thirdPartyChipIncrementalEnabled: Scalars['Boolean']['output'];
};

export type ShelterluvConfigurationInput = {
  apiKey: Scalars['String']['input'];
  thirdPartyChipBackfillEnabled: Scalars['Boolean']['input'];
  thirdPartyChipIncrementalEnabled: Scalars['Boolean']['input'];
};

export type ShipOrderLineItemsInput = {
  id: Scalars['ID']['input'];
  lineItemIds: Array<Scalars['ID']['input']>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  residential?: Maybe<Scalars['Boolean']['output']>;
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zipcode: Scalars['String']['output'];
};

export enum ShippingOption {
  GROUND = 'GROUND',
  IN_PERSON = 'IN_PERSON',
  OVERNIGHT = 'OVERNIGHT',
  PRIORITY_MAIL = 'PRIORITY_MAIL',
  TWO_DAY = 'TWO_DAY',
}

export type ShippingOptionInput = {
  code: Scalars['String']['input'];
};

export type SimpleCard = Card & {
  __typename?: 'SimpleCard';
  date: Scalars['DateTime']['output'];
  icon?: Maybe<Image>;
  id: Scalars['ID']['output'];
  message: FormatString;
  title?: Maybe<Scalars['String']['output']>;
};

export type SimpleDebugActivity = {
  __typename?: 'SimpleDebugActivity';
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  positions: Array<Position>;
  start: Scalars['DateTime']['output'];
  typeString?: Maybe<Scalars['String']['output']>;
};

export enum SizeOption {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  XLARGE = 'XLARGE',
  XSMALL = 'XSMALL',
}

export type SkipSupplementShipmentInput = {
  recurlySubscriptionId: Scalars['ID']['input'];
};

export type SkipSupplementShipmentResult = {
  __typename?: 'SkipSupplementShipmentResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SleepAmount = {
  __typename?: 'SleepAmount';
  /** Total duration in seconds */
  duration: Scalars['Int']['output'];
  type: SleepType;
};

export type SleepInterruptionSpan = {
  __typename?: 'SleepInterruptionSpan';
  end: Scalars['DateTime']['output'];
  /** Between 0-1: where to place end this bar between `sleepStart` and `sleepEnd`. */
  endCoordinate: Scalars['Float']['output'];
  start: Scalars['DateTime']['output'];
  /** Between 0-1: where to place end this bar between `sleepStart` and `sleepEnd`. */
  startCoordinate: Scalars['Float']['output'];
};

export enum SleepType {
  NAP = 'NAP',
  SLEEP = 'SLEEP',
}

/** One of inputs should be specified */
export type SocialContentInput = {
  commentId?: InputMaybe<Scalars['ID']['input']>;
  photoId?: InputMaybe<Scalars['ID']['input']>;
  textPostId?: InputMaybe<Scalars['ID']['input']>;
};

export type Span = {
  __typename?: 'Span';
  length: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  style: FontStyle;
};

export enum Species {
  CAT = 'CAT',
  DOG = 'DOG',
  OTHER = 'OTHER',
}

export type StatChanged = {
  __typename?: 'StatChanged';
  newValue: Scalars['Int']['output'];
  oldValue: Scalars['Int']['output'];
  stat: DeviceStat;
};

export type StatDeltaDeviceEvent = DeviceEventBase & {
  __typename?: 'StatDeltaDeviceEvent';
  date: Scalars['DateTime']['output'];
  deltaValue: Scalars['Float']['output'];
  duration: Scalars['Float']['output'];
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  moduleId: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  statName: Scalars['String']['output'];
};

export type Statistics = {
  __typename?: 'Statistics';
  last24HrsTotalActivityReports: Scalars['Int']['output'];
  last24HrsTotalLocationReports: Scalars['Int']['output'];
  totalActivatedDevices: Scalars['Int']['output'];
  totalActivatedUsers: Scalars['Int']['output'];
  totalDevices: Scalars['Int']['output'];
  totalUsers: Scalars['Int']['output'];
  uptime: Scalars['Float']['output'];
};

export type StepActivity = {
  __typename?: 'StepActivity';
  date: Scalars['DateTime']['output'];
  stepCount: Scalars['Int']['output'];
};

export type StepActivityInfo = {
  __typename?: 'StepActivityInfo';
  currentStepGoalStreak: Scalars['Int']['output'];
  currentStreakText: Scalars['String']['output'];
  longestStepGoalStreak: Scalars['Int']['output'];
  streakBonusSummary: PetStreakBonusSummary;
};

export type StepCountDistribution = {
  __typename?: 'StepCountDistribution';
  averageStepCount: Scalars['Int']['output'];
  /** The size (in steps) of an individual bucket */
  bucketSize: Scalars['Int']['output'];
  buckets: Array<StepCountDistributionBucket>;
};

export type StepCountDistributionBucket = {
  __typename?: 'StepCountDistributionBucket';
  /** A single value (in steps) somewhere in the middle of this bucket. */
  midpointSteps: Scalars['Int']['output'];
  /** The percentage of dogs in this bucket (0-1). */
  percentage: Scalars['Float']['output'];
  /** The percentile as an integer between 1-100 (e.g. 95 = top 95th percentile). */
  percentile: Scalars['Int']['output'];
  /** The lower bound of steps contained in this bucket. */
  steps: Scalars['Int']['output'];
};

export type StepFeed = {
  __typename?: 'StepFeed';
  /** Cursors are base64 encoded ISO date strings. */
  nextCursor?: Maybe<Scalars['String']['output']>;
  period: ActivityRestStrainPeriod;
  prevCursor?: Maybe<Scalars['String']['output']>;
  stepSummary: StepSummary;
};

/**
 * Describes steps and associated metadata (streak counts, walks, etc.) across
 * the specified ActivityRestStrainPeriod.
 */
export type StepSummary = {
  __typename?: 'StepSummary';
  /** Maximum steps over the period (e.g. maximum steps/month for YEAR). */
  maxSteps: Scalars['Int']['output'];
  metStepGoal?: Maybe<Scalars['Boolean']['output']>;
  period: ActivityRestStrainPeriod;
  /** Midnight at the beginning of the period */
  start: Scalars['DateTime']['output'];
  /**
   * One value for each sub-period:
   *   * Day: 24 values, one per hour
   *   * Week: 7 values, one per day
   *   * Month: 28-31 values, one per day
   *   * Year: 12 values, one per month
   */
  stepData: Array<StepSummaryData>;
  /** Only applicable (and only populated) for DAY period. */
  stepGoal?: Maybe<Scalars['Int']['output']>;
  strainLevel?: Maybe<StrainLevel>;
  /** Strain score for the specified period. */
  strainScore?: Maybe<Scalars['Float']['output']>;
  streakDays?: Maybe<Scalars['Int']['output']>;
  streakExtended?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  totalNumberOfWalks: Scalars['Int']['output'];
  /** Summed over the entire period */
  totalSteps: Scalars['Int']['output'];
  totalWalkDistanceMeters: Scalars['Float']['output'];
};

export type StepSummaryData = {
  __typename?: 'StepSummaryData';
  availability: ActivityRestStrainPeriodDataAvailability;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  metStepGoal?: Maybe<Scalars['Boolean']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  strainLevel?: Maybe<StrainLevel>;
  strainScore?: Maybe<Scalars['Float']['output']>;
};

export type StoreCartItemInput = {
  addonLineItems?: InputMaybe<Array<CartLineItemInput>>;
  cartItemId: Scalars['String']['input'];
  giftCardDelivery?: InputMaybe<GiftCardDeliveryInput>;
  lineItem: CartLineItemInput;
  quantity: Scalars['Int']['input'];
  subscriptionLineItem?: InputMaybe<CartLineItemInput>;
};

/**
 * Strain scores are a range from 0 to 30.
 * Descriptive levels are defined as:
 * LOW       [0 - 7.5)    (0-25%)
 * MODERATE  [7.5 - 22.5) (25-75%)
 * HIGH      [22.5 - 30]  (75-100%)
 */
export enum StrainLevel {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  /** If the score cannot be calculated for given period (e.g. due to lack of data). */
  UNAVAILABLE = 'UNAVAILABLE',
}

export type StrainScore = {
  __typename?: 'StrainScore';
  date?: Maybe<Scalars['DateTime']['output']>;
  level?: Maybe<StrainScoreLevel>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type StrainScoreAverage = {
  __typename?: 'StrainScoreAverage';
  level?: Maybe<StrainScoreLevel>;
  score?: Maybe<Scalars['Float']['output']>;
};

/**
 * Strain scores: https://docs.google.com/document/d/16RiGCt9MSLb6lrpeBMFORZP4qsj5pQeEDswpZ-QHcNY/edit
 * Bucket values:
 * Light       1-10
 * Moderate   11-16
 * Strenuous  17-22
 * All Out    23-30+
 *
 * *** DEPRECATED: Use StrainLevel instead ***
 */
export enum StrainScoreLevel {
  ALL_OUT = 'ALL_OUT',
  LIGHT = 'LIGHT',
  MODERATE = 'MODERATE',
  STRENUOUS = 'STRENUOUS',
  /** If the score cannot be calculated for given period (e.g. due to lack of data). */
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum StrainScorePeriod {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type StrainScoreResult = {
  __typename?: 'StrainScoreResult';
  nextCursor?: Maybe<Scalars['String']['output']>;
  scores: Array<StrainScore>;
};

/**
 * A chip that may be unassigned or may be assigned to someone else.
 * The user is able to request a transfer.
 */
export type StrangerChip = BaseChip & {
  __typename?: 'StrangerChip';
  id: Scalars['ID']['output'];
  serialNumber: Scalars['String']['output'];
  shortId: Scalars['String']['output'];
  /**
   * An outstanding transfer related to the chip. This will only be returned if either
   * 1) The transfer is to the current user (canResolve will be true)
   * 2) The transfer was requested by the current user (canResolve will be false)
   */
  transfer?: Maybe<ChipTransfer>;
};

export type StrangerPet = BasePet & {
  __typename?: 'StrangerPet';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  ageRange: AgeRange;
  breed?: Maybe<Breed>;
  dayOfBirth?: Maybe<Scalars['Int']['output']>;
  discoverPostFeed: PetDiscoverFeed;
  /** Sum of comments and likes made by this pet. */
  engagementScore: Scalars['Int']['output'];
  fiHandle?: Maybe<Scalars['String']['output']>;
  followStatus: FollowStatus;
  followers: StrangerPetFeed;
  following: StrangerPetFeed;
  gender: Gender;
  /** @deprecated Use homeLocation instead */
  homeCityState?: Maybe<Scalars['String']['output']>;
  homeLocation?: Maybe<PetHomeLocation>;
  id: Scalars['ID']['output'];
  instagramHandle?: Maybe<Scalars['String']['output']>;
  isIncludedInRanking: Scalars['Boolean']['output'];
  isPurebred: Scalars['Boolean']['output'];
  monthOfBirth?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** True if the pet joined Fi within the last two weeks. */
  newToFi?: Maybe<Scalars['Boolean']['output']>;
  packFeed: PackFeed;
  petRanking?: Maybe<PackRanking>;
  photos: PhotoFeed;
  /** @deprecated Use pet.restFeed */
  restSummaryFeed: RestSummaryFeed;
  species?: Maybe<Species>;
  stepActivityInfo: StepActivityInfo;
  /** Total number of likes for this pet, across walks, photos, etc. */
  totalLikes: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
  yearOfBirth: Scalars['Int']['output'];
};

export type StrangerPetdiscoverPostFeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type StrangerPetfollowersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type StrangerPetfollowingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type StrangerPetpetRankingArgs = {
  limitBy: Array<PetRankFilter>;
};

export type StrangerPetrestSummaryFeedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  period: ActivitySummaryPeriod;
};

export type StrangerPetFeed = {
  __typename?: 'StrangerPetFeed';
  pageInfo: PageInfo;
  strangerPets: Array<StrangerPet>;
  totalCount: Scalars['Int']['output'];
};

export type StrangerUser = {
  __typename?: 'StrangerUser';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Currently just the profile picture from their first pet. */
  profilePicture?: Maybe<Image>;
};

export type StrangerWalk = {
  __typename?: 'StrangerWalk';
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  /** @deprecated Use liked */
  clapped: Scalars['Boolean']['output'];
  distance: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  liked: Scalars['Boolean']['output'];
  stepCount: Scalars['Int']['output'];
};

export type StreakDetail = {
  __typename?: 'StreakDetail';
  availability: ActivityRestStrainPeriodDataAvailability;
  date: Scalars['DateTime']['output'];
  metStepGoal?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Cursor that can be passed into pet.stepFeed to get get step data for this
   * day, i.e. “jump to date” functionality
   */
  stepFeedCursor: Scalars['String']['output'];
  stepGoal?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<Scalars['Int']['output']>;
  streakExtended?: Maybe<Scalars['Boolean']['output']>;
};

export type StreakHistoryFeed = {
  __typename?: 'StreakHistoryFeed';
  endDate: Scalars['DateTime']['output'];
  /**
   * prevCursor is a base64 encoded ISO date string.
   *
   * There is no nextCursor as this feed will always start 'now'
   * and only supports swiping back through time.
   */
  prevCursor?: Maybe<Scalars['String']['output']>;
  /** The first and last (inclusive) days that are available in streakDetails. */
  startDate: Scalars['DateTime']['output'];
  /** Streak history for each day of the month. */
  streakDetails: Array<StreakDetail>;
};

export type StringFeatureFlag = FeatureFlag & {
  __typename?: 'StringFeatureFlag';
  accessedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dynamicSettingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stringValue: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubmitReportsResult = {
  __typename?: 'SubmitReportsResult';
  /** @deprecated Always HIGH */
  desiredLocationAccuracy: ClientLocationAccuracy;
  moduleResponse?: Maybe<Scalars['String']['output']>;
  quickReportMode: QuickReportModeState;
  /** @deprecated Use `quickReportMode` instead */
  quickReportModeEnabled: Scalars['Boolean']['output'];
  shouldDisconnectFromModule: Scalars['Boolean']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to all new location reports */
  newLocationReport?: Maybe<LocationReport>;
  petUpdates: Array<PetUpdate>;
};

export type SubscriptionnewLocationReportArgs = {
  imei?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionpetUpdatesArgs = {
  changeTypes: Array<PetChangeType>;
  petId: Scalars['ID']['input'];
};

export enum SubscriptionAccessType {
  /** Indicates that a device has an active subscription and should have access to everything */
  FULL_ACCESS = 'FULL_ACCESS',
  /** An S3 module that has no active subscription and should not have access to any features */
  NO_ACCESS = 'NO_ACCESS',
  /** An S1 or S2 module that has no active subscription and should not have access to GPS or historical data */
  NO_GPS_ACCESS = 'NO_GPS_ACCESS',
}

export type SubscriptionCartInput = {
  cartItems: Array<ForModuleCartItemInput>;
  redeemedGiftCardCode?: InputMaybe<Scalars['String']['input']>;
  shippingCode?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionChange = {
  __typename?: 'SubscriptionChange';
  activateAt?: Maybe<Scalars['DateTime']['output']>;
  activated?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<PlanMini>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionOption = {
  __typename?: 'SubscriptionOption';
  activationFeeInCents?: Maybe<Scalars['Int']['output']>;
  attemptUpsellToPlanCode?: Maybe<Scalars['String']['output']>;
  billingCadence: BillingCadence;
  billingPeriodsPerTerm: Scalars['Int']['output'];
  buyItMembership: Scalars['Boolean']['output'];
  couponExtensionMonths?: Maybe<Scalars['Int']['output']>;
  daysBetweenShipments?: Maybe<Scalars['Int']['output']>;
  displayedRate: Scalars['String']['output'];
  displayedRateUnit: Scalars['String']['output'];
  featured: Scalars['Boolean']['output'];
  freeTrialDays?: Maybe<Scalars['Int']['output']>;
  fullRate?: Maybe<Scalars['String']['output']>;
  fullRateUnit?: Maybe<Scalars['String']['output']>;
  inAppManagementName: Scalars['String']['output'];
  monthlyUpgradeSavings?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  planSwitchAmountChargedInCents?: Maybe<Scalars['Int']['output']>;
  planSwitchCreditAppliedInCents?: Maybe<Scalars['Int']['output']>;
  priceInCents: Scalars['Int']['output'];
  recommendedForDogSize?: Maybe<SizeOption>;
  recommendedForDogWeightRangePounds?: Maybe<WeightRange>;
  renewalMonths: Scalars['Int']['output'];
  renewalYears?: Maybe<Scalars['Int']['output']>;
  savings?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  supportPlus: Scalars['Boolean']['output'];
  unavailableForTenders: Array<TenderType>;
  upsellOnly: Scalars['Boolean']['output'];
  valueLabel?: Maybe<Scalars['String']['output']>;
  weeksBetweenShipments?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPendingChange = {
  __typename?: 'SubscriptionPendingChange';
  subscriptionChange?: Maybe<SubscriptionChange>;
  subscriptionOption?: Maybe<SubscriptionOption>;
};

export type SubscriptionRenewalPreview = {
  __typename?: 'SubscriptionRenewalPreview';
  appliedDiscountInCents: Scalars['Int']['output'];
  subtotalInCents: Scalars['Int']['output'];
  taxInCents: Scalars['Int']['output'];
  totalInCents: Scalars['Int']['output'];
};

export type SubscriptionRenewalStatus = {
  __typename?: 'SubscriptionRenewalStatus';
  reason?: Maybe<Scalars['String']['output']>;
  shouldOfferSubscriptionRenewal: Scalars['Boolean']['output'];
};

export type SuggestedPacksResult = {
  __typename?: 'SuggestedPacksResult';
  packs: Array<Pack>;
};

export type SuggestedPet = {
  __typename?: 'SuggestedPet';
  strangerPet: StrangerPet;
  suggestionContextString?: Maybe<Scalars['String']['output']>;
  /** Used in Carousel suggestions. */
  suggestionFormatString?: Maybe<FormatString>;
};

export enum SuggestedPetCategoryType {
  CONTACTS = 'CONTACTS',
  ENGAGED_WITH = 'ENGAGED_WITH',
  FOLLOWS_YOU = 'FOLLOWS_YOU',
  NEARBY_HOME = 'NEARBY_HOME',
  NEARBY_PET = 'NEARBY_PET',
  SAME_AGE = 'SAME_AGE',
}

export type SuggestedPetFeed = {
  __typename?: 'SuggestedPetFeed';
  suggestedPets: Array<SuggestedPet>;
  type: SuggestedPetCategoryType;
};

export type SuggestedPetResult = {
  __typename?: 'SuggestedPetResult';
  suggestionCategories: Array<SuggestedPetFeed>;
};

export type SupplementShipmentDates = {
  __typename?: 'SupplementShipmentDates';
  displayDate: Scalars['DateTime']['output'];
  latestSkipDate: Scalars['DateTime']['output'];
  nextShipmentDate: Scalars['DateTime']['output'];
  previewResumingShipmentDate: Scalars['DateTime']['output'];
  previewSkippingShipmentDate: Scalars['DateTime']['output'];
  previewUpdate: Array<SupplementUpdatePreview>;
};

export type SupplementUpdatePreview = {
  __typename?: 'SupplementUpdatePreview';
  nextShipDate: Scalars['DateTime']['output'];
  sku: Scalars['String']['output'];
};

export type SyncOrdersInput = {
  externalOrderIds: Array<Scalars['ID']['input']>;
  orderSource: OrderSource;
};

export type SyncOrdersResults = {
  __typename?: 'SyncOrdersResults';
  created: Scalars['Int']['output'];
  errors: Scalars['Int']['output'];
  filtered: Scalars['Int']['output'];
  new: Scalars['Int']['output'];
};

export type TActivityReportEvent = TEvent & {
  __typename?: 'TActivityReportEvent';
  activityReport: ActivityReport;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type TAppLaunchEvent = TEvent & {
  __typename?: 'TAppLaunchEvent';
  applicationState?: Maybe<ApplicationState>;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type TCollapsedEvents = TEvent & {
  __typename?: 'TCollapsedEvents';
  allEvents: Array<TEvent>;
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** Expose first/last event separately so you don't need to select all of `allEvents`. */
  firstEvent: TEvent;
  id: Scalars['ID']['output'];
  /** Expose first/last event separately so you don't need to select all of `allEvents`. */
  lastEvent: TEvent;
};

export type TConnectionHistoryEvent = TEvent & {
  __typename?: 'TConnectionHistoryEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  state: ConnectionState;
};

export type TDeviceInfoEvent = TEvent & {
  __typename?: 'TDeviceInfoEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  deviceInfo: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
};

export type TDeviceRestartEvent = TEvent & {
  __typename?: 'TDeviceRestartEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newUptime: Scalars['Int']['output'];
  oldDate: Scalars['DateTime']['output'];
  oldUptime: Scalars['Int']['output'];
};

export type TEvent = {
  date: Scalars['DateTime']['output'];
  /** A simple string description of the event, useful for ad-hoc querying. */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum TEventType {
  ACTIVITY_REPORT = 'ACTIVITY_REPORT',
  APP_LAUNCH = 'APP_LAUNCH',
  CONNECTION_HISTORY = 'CONNECTION_HISTORY',
  /**
   * Includes some handy events derived from device-info, like device restarts
   * based on uptime.
   */
  DEVICE_EVENTS = 'DEVICE_EVENTS',
  DEVICE_INFO = 'DEVICE_INFO',
  FIRMWARE_UPDATE = 'FIRMWARE_UPDATE',
  LED_EVENT = 'LED_EVENT',
  LOCATION_REPORT = 'LOCATION_REPORT',
  LOST_DOG_MODE_TOGGLE = 'LOST_DOG_MODE_TOGGLE',
  MOBILITY_REPORT = 'MOBILITY_REPORT',
  MODULE_LOG = 'MODULE_LOG',
  NOTIFICATION_SEND = 'NOTIFICATION_SEND',
  OPERATION_PARAMS_CHANGE = 'OPERATION_PARAMS_CHANGE',
  STATS_CHANGED = 'STATS_CHANGED',
  WARNING = 'WARNING',
  WEBSOCKET_EVENTS = 'WEBSOCKET_EVENTS',
}

export type TFirmwareUpdateEvent = TEvent & {
  __typename?: 'TFirmwareUpdateEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newVersion: Scalars['String']['output'];
  oldVersion?: Maybe<Scalars['String']['output']>;
};

export type TGenericLogEvent = TEvent & {
  __typename?: 'TGenericLogEvent';
  attributes?: Maybe<Scalars['JSON']['output']>;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: LogLevel;
  message: Scalars['String']['output'];
};

export type TLedEvent = TEvent & {
  __typename?: 'TLedEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ledOn: Scalars['Boolean']['output'];
};

export type TLocationReportEvent = TEvent & {
  __typename?: 'TLocationReportEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationReport: LocationReport;
};

export type TLostDogModeToggleEvent = TEvent & {
  __typename?: 'TLostDogModeToggleEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ldmOn: Scalars['Boolean']['output'];
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type TMobilityReportEvent = TEvent & {
  __typename?: 'TMobilityReportEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mobilityReport: MobilityReport;
};

export type TModemRegistrationFailuresEvent = TEvent & {
  __typename?: 'TModemRegistrationFailuresEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  numFailures: Scalars['Int']['output'];
};

export type TModuleLogEvent = TEvent & {
  __typename?: 'TModuleLogEvent';
  attributes: Scalars['JSON']['output'];
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  hsmTransitionDetails?: Maybe<ModuleHSMTransitionDetails>;
  id: Scalars['ID']['output'];
  logType: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TNotificationSendEvent = TEvent & {
  __typename?: 'TNotificationSendEvent';
  channel: NotificationChannel;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  user: User;
};

export type TOperationParamsChangeEvent = TEvent & {
  __typename?: 'TOperationParamsChangeEvent';
  controlEntry?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newMode?: Maybe<Scalars['String']['output']>;
  newParams: Scalars['JSON']['output'];
  oldMode?: Maybe<Scalars['String']['output']>;
  oldParams: Scalars['JSON']['output'];
};

export type TStatsChangedEvent = TEvent & {
  __typename?: 'TStatsChangedEvent';
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newDeviceInfo: Scalars['JSON']['output'];
  oldDeviceInfo: Scalars['JSON']['output'];
  statsChanged: Array<StatChanged>;
};

export type TWebsocketEvent = TEvent & {
  __typename?: 'TWebsocketEvent';
  backhaul?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  protocol?: Maybe<Scalars['String']['output']>;
  session?: Maybe<Scalars['JSON']['output']>;
};

export type Temperament = {
  __typename?: 'Temperament';
  icon: Image;
  name: Scalars['String']['output'];
};

export enum TenderType {
  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAYPAL',
}

export type TextPost = {
  __typename?: 'TextPost';
  comments: CommentFeed;
  date: Scalars['DateTime']['output'];
  /** Admin-only */
  dbId: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  /** If the current user has liked this post. */
  liked: Scalars['Boolean']['output'];
  likedByPets: Array<StrangerPet>;
  /** Pet associated with this post, if any. */
  pet: StrangerPet;
  poster: StrangerUser;
  publicShareLink?: Maybe<Scalars['String']['output']>;
  style: TextPostStyle;
  text: Scalars['String']['output'];
};

export type TextPostModerationFeed = ModerationFeed & {
  __typename?: 'TextPostModerationFeed';
  items: Array<ModerationTextPostItem>;
  totalCount: Scalars['Int']['output'];
};

export type TextPostStyle = {
  __typename?: 'TextPostStyle';
  backgroundColorHex: Scalars['String']['output'];
  backgroundEndColorHex: Scalars['String']['output'];
  /** @deprecated Android is querying this field, but it is not used. */
  backgroundPhoto?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  textColorHex: Scalars['String']['output'];
};

export type ThirdPartyChipInput = {
  serialNumber: Scalars['String']['input'];
};

export type TimeBoundStepCount = {
  __typename?: 'TimeBoundStepCount';
  end: Scalars['DateTime']['output'];
  /** Activity level in percent (0-1). */
  percentActive: Scalars['Float']['output'];
  start: Scalars['DateTime']['output'];
  steps: Scalars['Int']['output'];
};

export enum TimelineAggregation {
  COLLAPSE_SIMILAR = 'COLLAPSE_SIMILAR',
}

/**
 * Rudimentary filters that will be applied where applicable.
 * These are purposely not very generic -- we're not implementing a full-fledged
 * query language.
 */
export type TimelineFieldFilter = {
  reportSourcesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reportSourcesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TimelineIntervalPagination = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start: Scalars['DateTime']['input'];
};

export type TimelineLimitBeforePagination = {
  /**
   * This before date is used with a LessThan comparison so that the last element of the previous page can be passed directly here
   * and not return duplicate records
   */
  before?: InputMaybe<Scalars['DateTime']['input']>;
  limit: Scalars['Int']['input'];
};

/** Exactly one of the members must be set. */
export type TimelinePagination = {
  interval?: InputMaybe<TimelineIntervalPagination>;
  limitBefore?: InputMaybe<TimelineLimitBeforePagination>;
};

export type TimelineQueryClause = {
  /** BETA! Still trying to figure out the best level to apply aggregation on. */
  aggregation?: InputMaybe<TimelineAggregation>;
  fieldFilter?: InputMaybe<TimelineFieldFilter>;
  /** Event types we are interested in */
  types: Array<TEventType>;
};

export type TimelineQueryInput = {
  /** Return results matching ANY of these clauses. */
  clauses: Array<TimelineQueryClause>;
  /** Causes some data nodes to emit example events */
  debugEvents?: InputMaybe<Scalars['Boolean']['input']>;
  pagination: TimelinePagination;
};

export type TimelineQueryResult = {
  __typename?: 'TimelineQueryResult';
  events: Array<TEvent>;
};

export type TokenValidity = InvalidToken | ValidToken;

export type Travel = Activity & {
  __typename?: 'Travel';
  address?: Maybe<Scalars['String']['output']>;
  areaName?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  dbId: Scalars['Int']['output'];
  debugMetadata: Scalars['JSON']['output'];
  /** Distance in meters traveled */
  distance: Scalars['Float']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Use isLikable */
  isClappable: Scalars['Boolean']['output'];
  isLikable: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  mapUrl: Scalars['String']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  positions: Array<Position>;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type TravelmapUrlArgs = {
  height: Scalars['Int']['input'];
  minimumPadding?: InputMaybe<PaddingInput>;
  scale?: InputMaybe<Scalars['Int']['input']>;
  style?: InputMaybe<MapStyle>;
  width: Scalars['Int']['input'];
};

export type TrueBooleanSetting = BooleanSetting & {
  __typename?: 'TrueBooleanSetting';
  /**
   * DO NOT USE: This is required since GraphQL doesn't support empty types.
   * Use the __typename to determine the flag's boolean value.
   */
  emptyType?: Maybe<Scalars['Boolean']['output']>;
  platformOverrides: Array<BooleanSettingPlatformOverride>;
};

export type TwoLinePromotionBanner = InAppPromotionBanner & {
  __typename?: 'TwoLinePromotionBanner';
  backgroundColorHex: Scalars['String']['output'];
  ctaUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  line1Text: FormatString;
  line1TextColorHex: Scalars['String']['output'];
  line2Text: FormatString;
  line2TextColorHex: Scalars['String']['output'];
};

export type UnavailableDailyRestSummary = DailyRestSummary & {
  __typename?: 'UnavailableDailyRestSummary';
  date: Scalars['DateTime']['output'];
};

export type UnavailableOvernightRestSummary = OvernightRestSummary & {
  __typename?: 'UnavailableOvernightRestSummary';
  date: Scalars['DateTime']['output'];
  inProgress: Scalars['Boolean']['output'];
};

export type UnavailableRestSummaryData = {
  __typename?: 'UnavailableRestSummaryData';
  /** This field is always null, it's added because GraphQL doesn't support empty types. */
  unavailable?: Maybe<Scalars['Boolean']['output']>;
};

export type UncertaintyInfo = {
  __typename?: 'UncertaintyInfo';
  areaName?: Maybe<Scalars['String']['output']>;
  circle: Circle;
  updatedAt: Scalars['DateTime']['output'];
};

export type UnknownConnectivity = ConnectionState & {
  __typename?: 'UnknownConnectivity';
  date: Scalars['DateTime']['output'];
  /** Always null, added so that fragments can have something to select. */
  unknownConnectivity?: Maybe<Scalars['Boolean']['output']>;
};

export type UnmatchedPath = {
  __typename?: 'UnmatchedPath';
  locations: Array<Location>;
};

export type UpdateChargingBaseInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<PositionInput>;
};

export type UpdateChipConditionInput = {
  chipCondition: ChipCondition;
  chipId: Scalars['ID']['input'];
};

export type UpdateChipContactInfoInput = {
  chipId: Scalars['ID']['input'];
  contactInfo: ChipContactInfoInput;
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateChipContactInfoResult = {
  __typename?: 'UpdateChipContactInfoResult';
  contactInfoResponse: ChipContactInfoResponse;
  /**
   * True if the pet ownership was transferred as part of this operation. The current
   * user may no longer own the pet associated with the chip.
   */
  petOwnershipWasTransferred: Scalars['Boolean']['output'];
};

export type UpdateDeviceOperationParamsInput = {
  gnssMaxAcceptableRadiusM?: InputMaybe<Scalars['Float']['input']>;
  gnssMinAcceptableSatellites?: InputMaybe<Scalars['Int']['input']>;
  ledEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<OperationParamsMode>;
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  reboot?: InputMaybe<Scalars['Boolean']['input']>;
  wifiDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDogParkNotificationSettingsInput = {
  dogParkId: Scalars['ID']['input'];
  /**
   * Update preferences for the specified user instead of the currently logged-in
   * user (admin only).
   */
  forUserId?: InputMaybe<Scalars['ID']['input']>;
  settingInput: DogParkNotificationSettingsInput;
};

export type UpdateDynamicEnumMemberInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateFiNewsNotificationInput = {
  featureImageUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirmwareChannelInput = {
  channelName: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  releaseClass?: InputMaybe<FirmwareReleaseClass>;
  type?: InputMaybe<FirmwareUpdateType>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirmwareInput = {
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  releaseClass?: InputMaybe<FirmwareReleaseClass>;
};

export type UpdateFrequencyOption = {
  __typename?: 'UpdateFrequencyOption';
  batteryDescription: Scalars['String']['output'];
  updateInterval: Scalars['Int']['output'];
};

export type UpdateInPersonEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type UpdateInPersonEventInventoryInput = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type UpdateMobileAppBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['HexColorCode']['input']>;
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  leadingImage?: InputMaybe<MobileAppBannerImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMobileAppBottomSheetInput = {
  /** If buttons are being updated, all buttons must be provided, updating individual buttons is not supported */
  buttons: Array<CreateMobileAppBottomSheetButtonInput>;
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  image?: InputMaybe<CreateMobileAppBottomSheetImageInput>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateMobileAppDiscoverItemInput = {
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMobileAppSplashScreenInput = {
  contentTargeting?: InputMaybe<MobileAppContentTargetingInput>;
  id: Scalars['ID']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  /** If pages are being updated, all pages must be provided, updating individual pages is not supported */
  pages?: InputMaybe<Array<CreateMobileAppSplashScreenPageInput>>;
};

export type UpdateOrderShippingAddressInput = {
  id: Scalars['ID']['input'];
  shippingAddress: OrderShippingAddressInput;
};

export type UpdateOrderShippingOptionInput = {
  id: Scalars['ID']['input'];
  shippingOption: ShippingOption;
};

export type UpdatePetAlertPreferencesInput = {
  alertsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  changeSettings?: InputMaybe<Array<PetNotificationSettingInput>>;
  /**
   * Update preferences for the specified user instead of the currently logged-in
   * user (admin only).
   */
  forUserId?: InputMaybe<Scalars['ID']['input']>;
  notificationMode?: InputMaybe<PetNotificationMode>;
  /** A value of 0 means these notifications should be turned off. */
  numMinutesForOfflineNotification?: InputMaybe<Scalars['Int']['input']>;
  petId: Scalars['ID']['input'];
};

export type UpdatePetCityStateInput = {
  city: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

export type UpdatePetInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  breedId?: InputMaybe<Scalars['ID']['input']>;
  dayOfBirth?: InputMaybe<Scalars['Int']['input']>;
  fiHandle?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['ID']['input'];
  isIncludedInRanking?: InputMaybe<Scalars['Boolean']['input']>;
  isPurebred?: InputMaybe<Scalars['Boolean']['input']>;
  monthOfBirth?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  species?: InputMaybe<Species>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  yearOfBirth?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type UpdatePlaceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** Should only be set for polygon safe zones. */
  boundary?: InputMaybe<Scalars['JSON']['input']>;
  coordinates?: InputMaybe<Array<PositionInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<PositionInput>;
  /** Should only be set for circle safe zones. */
  radius?: InputMaybe<Scalars['Float']['input']>;
  subjectId: Scalars['ID']['input'];
  /** Circle or polygon safe zone. */
  type?: InputMaybe<PlaceType>;
};

export type UpdatePromotionInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  internalName?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  variants?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateResellerContactInfoInput = {
  contactInfo: ChipContactInfoInput;
  resellerId: Scalars['ID']['input'];
};

export type UpdateShelterluvConfigInput = {
  chipResellerId: Scalars['ID']['input'];
  shelterluvConfiguration: ShelterluvConfigurationInput;
};

export type UpdateSupplementSubscriptionInput = {
  newSku: Scalars['String']['input'];
  recurlySubscriptionId: Scalars['ID']['input'];
};

export type UpdateSupplementSubscriptionResult = {
  __typename?: 'UpdateSupplementSubscriptionResult';
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateUserCommunicationPreferencesInput = {
  pushEnabled: Scalars['Boolean']['input'];
  smsEnabled: Scalars['Boolean']['input'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  subjectId: Scalars['ID']['input'];
};

export type UpdateWifiNetworkInput = {
  householdId: Scalars['ID']['input'];
  position: PositionInput;
  ssid: Scalars['String']['input'];
};

export type UpgradeMembershipInput = {
  couponCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  moduleId: Scalars['ID']['input'];
  sku: Scalars['ID']['input'];
};

export type UploadContactInput = {
  contacts: Array<ContactInfo>;
};

export type User = {
  __typename?: 'User';
  automaticallyActivateDevices: Scalars['Boolean']['output'];
  billingAccount?: Maybe<BillingAccount>;
  /** In-app subscription management (aka in-app cancellation) */
  canManageSupplementSubscriptions?: Maybe<Scalars['Boolean']['output']>;
  /** Chip reseller associated with this user, if any. */
  chipReseller?: Maybe<ChipReseller>;
  /** @deprecated Use chips instead */
  chipTransfersToUser: Array<ChipTransfer>;
  chips: Array<BaseChip>;
  dbId: Scalars['Int']['output'];
  deactivated: Scalars['Boolean']['output'];
  discoverFeed: DiscoverFeed;
  ecommerceExperimentEnrollments: Array<EcommerceExperimentEnrollment>;
  /**
   * A URL that users can use to update their payment information. This calls the
   * recurly API so only request when needed.
   */
  editBillingInfoUrl?: Maybe<Scalars['String']['output']>;
  /** Refers to user's primary email */
  email: Scalars['String']['output'];
  emails: Array<UserEmail>;
  fiFeed: FiFeed;
  fiNewsNotificationsEnabled: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  /**
   * Access is restricted to admins. To alter client behavior based on
   * a user's groups, create a new feature flag.
   */
  groups: Array<UserGroup>;
  handsets: Array<Handset>;
  /** Returns true if the user has the multidog discount coupon applied in Recurly. */
  hasMultidogDiscount: Scalars['Boolean']['output'];
  hasPurchasedSupplementSubscription?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use userHouseholds instead */
  households: Array<Household>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  latestLocation?: Maybe<Location>;
  note?: Maybe<Scalars['String']['output']>;
  notificationFeed: NotificationFeed;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Returns phone support details for this user. */
  phoneSupport: PhoneSupport;
  referrals: Referrals;
  settings: UserSettings;
  socialBanned: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
  userHouseholds: Array<UserHousehold>;
};

export type UserchipTransfersToUserArgs = {
  query?: InputMaybe<ChipTransferQueryInput>;
};

export type UserdiscoverFeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
  pulledToRefresh?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserfiFeedArgs = {
  includeTravel?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
  seekParameters?: InputMaybe<SeekParameters>;
};

export type UsergroupsArgs = {
  excludePseudo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserhandsetsArgs = {
  status?: InputMaybe<Array<HandsetStatus>>;
};

export type UsernotificationFeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pagingInstruction?: InputMaybe<PagingInstruction>;
};

export type UserAccountDeletionInput = {
  userId: Scalars['ID']['input'];
};

/** Info for a user's contacts uploaded from their phone. */
export type UserContactInfo = {
  __typename?: 'UserContactInfo';
  emails?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserEmail = {
  __typename?: 'UserEmail';
  email: Scalars['String']['output'];
  isPrimary: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
};

export type UserFeedbackActivityPlayFeedbackSubmitInput = {
  feedback: Scalars['String']['input'];
  playEventId: Scalars['ID']['input'];
};

export type UserFeedbackActivityRestFeedbackSubmitInput = {
  feedback: Scalars['String']['input'];
  restEventId: Scalars['ID']['input'];
};

export type UserFeedbackActivityTravelFeedbackSubmitInput = {
  feedback: Scalars['String']['input'];
  travelEventId: Scalars['ID']['input'];
};

export type UserFeedbackActivityWalkFeedbackSubmitInput = {
  feedback: Scalars['String']['input'];
  walkEventId: Scalars['ID']['input'];
};

export type UserFeedbackEscapeAlertFeedbackSubmitInput = {
  escapeAlertNotificationId: Scalars['ID']['input'];
  feedback: Scalars['String']['input'];
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserFeedbackLostDogFeedbackSubmitInput = {
  feedback: Scalars['String']['input'];
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserFeedbackLostDogModeDidHelpInput = {
  didHelp: Scalars['Boolean']['input'];
  petId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Similar to household but augmented with user specific data */
export type UserHousehold = {
  __typename?: 'UserHousehold';
  /** Whether this is the first user household that was created for this user. */
  firstCreated: Scalars['Boolean']['output'];
  household: Household;
  householdRole: UserHouseholdRole;
};

export enum UserHouseholdRole {
  CARETAKER = 'CARETAKER',
  OWNER = 'OWNER',
  PROVISIONAL_CARETAKER = 'PROVISIONAL_CARETAKER',
}

export type UserIdsBooleanSetting = BooleanSetting & {
  __typename?: 'UserIdsBooleanSetting';
  platformOverrides: Array<BooleanSettingPlatformOverride>;
  userIds: Array<Scalars['Int']['output']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  acceptedDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * This is admin-only for now because we don't expose the User type for other users,
   * and invites can be accessed at a household level where you may receive another user's
   * invite.
   */
  adminInviter: User;
  createdAt: Scalars['DateTime']['output'];
  dbId: Scalars['Int']['output'];
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  householdInvite?: Maybe<HouseholdInvite>;
  /** The invite code. */
  id: Scalars['String']['output'];
  inviteCode: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  revokedDate?: Maybe<Scalars['DateTime']['output']>;
  state: InviteState;
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  maxNumMinutesForOfflineNotification: Scalars['Int']['output'];
  minNumMinutesForOfflineNotification: Scalars['Int']['output'];
  showPetAlertSettings: Scalars['Boolean']['output'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  activityRankingsEnabled: Scalars['Boolean']['output'];
  appUpdateRequired: Scalars['Boolean']['output'];
  autoSetBaseLocationEnabled: Scalars['Boolean']['output'];
  diagnosticModeEnabled: Scalars['Boolean']['output'];
  dynamicSettings: Scalars['JSON']['output'];
  helpCenterEnabled: Scalars['Boolean']['output'];
  householdInvitesEnabled: Scalars['Boolean']['output'];
  locationReportThrottleDuration: Scalars['Float']['output'];
  lostDogPhoneLineEnabled: Scalars['Boolean']['output'];
  minimumLogLevel: LogLevel;
  minimumTimeIntervalBetweenLocationReports: Scalars['Float']['output'];
  moduleActivationRequiresModuleBePowered: Scalars['Boolean']['output'];
  notificationSettings: UserNotificationSettings;
  pushEnabled: Scalars['Boolean']['output'];
  referralMenuBannerSettings: ReferralMenuBannerSettings;
  referralsEnabled: Scalars['Boolean']['output'];
  rescueOrganizationAccess: Scalars['Boolean']['output'];
  series2UpsellString: FormatString;
  shouldAlwaysScanForBases: Scalars['Boolean']['output'];
  shouldBatchLocationReports: Scalars['Boolean']['output'];
  shouldPromptAppReview: Scalars['Boolean']['output'];
  showDebugMenu: Scalars['Boolean']['output'];
  showSeries2Upsell: Scalars['Boolean']['output'];
  smsEnabled: Scalars['Boolean']['output'];
  systemMetricLoggingEnabled: Scalars['Boolean']['output'];
  useFiBleProxy: Scalars['Boolean']['output'];
  userImpersonationEnabled: Scalars['Boolean']['output'];
  walkSharingEnabled: Scalars['Boolean']['output'];
  whenInUseLocationPermissionAllowed: Scalars['Boolean']['output'];
};

export type ValidToken = {
  __typename?: 'ValidToken';
  token: Scalars['String']['output'];
};

export type ValidateAddressResponse = {
  __typename?: 'ValidateAddressResponse';
  matchedAddress?: Maybe<Address>;
  status?: Maybe<ValidateAddressResponseStatus>;
};

export enum ValidateAddressResponseStatus {
  CORRECTED = 'CORRECTED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
}

export type Veterinary = {
  __typename?: 'Veterinary';
  address?: Maybe<VeterinaryAddress>;
  dbId: Scalars['Int']['output'];
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type VeterinaryAddress = {
  __typename?: 'VeterinaryAddress';
  city: Scalars['String']['output'];
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type VideoLink = {
  __typename?: 'VideoLink';
  aspectRatio: AspectRatio;
  type: VideoType;
  url: Scalars['String']['output'];
};

export enum VideoOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export type VideoPost = {
  __typename?: 'VideoPost';
  aspectRatio: AspectRatio;
  caption?: Maybe<Scalars['String']['output']>;
  comments: CommentFeed;
  date: Scalars['DateTime']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includesAudio: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  /** If the current user has liked this post. */
  liked: Scalars['Boolean']['output'];
  likedByPets: Array<StrangerPet>;
  /** Pet associated with this post */
  pet: StrangerPet;
  poster: StrangerUser;
  /** Returns the requested playback format, may return null if the format is not available due to video processing not being complete. */
  preferredPlaybackLink?: Maybe<VideoLink>;
  /** A link to a web page containing this post that can be shared publicly if item is publicly shareable */
  publicShareLink?: Maybe<Scalars['String']['output']>;
  status: VideoPostStatus;
  thumbnailImage?: Maybe<Image>;
};

export type VideoPostpreferredPlaybackLinkArgs = {
  format: VideoType;
};

export enum VideoPostStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
}

export enum VideoType {
  DASH = 'DASH',
  HLS = 'HLS',
  MP4 = 'MP4',
}

export type VirtualPack = BasePack & {
  __typename?: 'VirtualPack';
  actingPetCanJoin: Scalars['Boolean']['output'];
  actingPetCanLeave: Scalars['Boolean']['output'];
  actingPetCannotJoinReason?: Maybe<Scalars['String']['output']>;
  actingPetDidManuallyJoin: Scalars['Boolean']['output'];
  actingPetIsMember: Scalars['Boolean']['output'];
  actingPetMembershipReason: Scalars['String']['output'];
  ageFiltersWithEnoughRankedPets: Array<PackAgeFilter>;
  avatar: PackAvatar;
  category: PackCategory;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * A 6-digit hex color code, including # sign, and letters lowercase (eg.
   * `#ff00b1`) used to highlight this pack in the app.
   */
  highlightColorHex?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  petRanking: PackRanking;
  photo?: Maybe<Photo>;
  totalRankedPets: Scalars['Int']['output'];
  /**
   * A 64-bit integer representing the total steps for all pets in this pack for
   * the latest ranking period returned as a String.
   */
  totalRankedSteps: Scalars['String']['output'];
};

export type VirtualPackpetRankingArgs = {
  packAgeFilter?: InputMaybe<PackAgeFilter>;
  petId: Scalars['ID']['input'];
};

export type Walk = Activity & {
  __typename?: 'Walk';
  address?: Maybe<Scalars['String']['output']>;
  animatedShareableUrl: AnimatedShareableUrls;
  areaName?: Maybe<Scalars['String']['output']>;
  cityState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use likeCount */
  clapCount: Scalars['Int']['output'];
  dbId: Scalars['Int']['output'];
  debugMetadata: Scalars['JSON']['output'];
  /** Distance in meters traveled */
  distance: Scalars['Float']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAccurateWalk: Scalars['Boolean']['output'];
  /** @deprecated Use isLikable */
  isClappable: Scalars['Boolean']['output'];
  isLikable: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  mapPath: MapPath;
  mapUrl: Scalars['String']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  obfuscated: Scalars['Boolean']['output'];
  /** Stored path positions */
  path: Array<Position>;
  pathProcessingDataForDebug: PathProcessingDataForDebug;
  /** raw positions */
  positions: Array<Location>;
  presentUser?: Maybe<User>;
  presentUserString?: Maybe<Scalars['String']['output']>;
  routeMatcherName?: Maybe<Scalars['String']['output']>;
  sharedToExternalApps: Array<Maybe<AppIntegrationId>>;
  start: Scalars['DateTime']['output'];
  totalSteps: Scalars['Int']['output'];
};

export type WalkanimatedShareableUrlArgs = {
  format: AnimationFormat;
};

export type WalkmapUrlArgs = {
  height: Scalars['Int']['input'];
  minimumPadding?: InputMaybe<PaddingInput>;
  scale?: InputMaybe<Scalars['Int']['input']>;
  style?: InputMaybe<MapStyle>;
  width: Scalars['Int']['input'];
};

export type WalkCard = Card & {
  __typename?: 'WalkCard';
  date: Scalars['DateTime']['output'];
  icon?: Maybe<Image>;
  id: Scalars['ID']['output'];
  message: FormatString;
  title?: Maybe<Scalars['String']['output']>;
  walk: Walk;
};

export type WalkSegmentsGeoJSONZipInput = {
  /** Must be a walk activity. */
  activityId: Scalars['ID']['input'];
  /** To be included in downloaded file. */
  description?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseInventoryLevel = {
  __typename?: 'WarehouseInventoryLevel';
  allowFulfillment: Scalars['Boolean']['output'];
  fulfillmentWarehouse: FulfillmentWarehouse;
  manualAdjustment: Scalars['Int']['output'];
  outOfStockThreshold: Scalars['Int']['output'];
  quantityInStock: Scalars['Int']['output'];
  quantityPending: Scalars['Int']['output'];
  quantityReserved: Scalars['Int']['output'];
};

export type WeightRange = {
  __typename?: 'WeightRange';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

export type WifiCredentialInput = {
  isHidden: Scalars['Boolean']['input'];
  psk?: InputMaybe<Scalars['String']['input']>;
  ssid: Scalars['String']['input'];
};

export type WifiNetwork = {
  __typename?: 'WifiNetwork';
  position: Position;
  ssid: Scalars['String']['output'];
};

export type WifiNetworks = {
  __typename?: 'WifiNetworks';
  credentialPackHash?: Maybe<Scalars['String']['output']>;
  maximumNetworkCount: Scalars['Int']['output'];
  networks: Array<WifiNetwork>;
};

export type WifiScanResult = {
  __typename?: 'WifiScanResult';
  authMode?: Maybe<Scalars['String']['output']>;
  rssi?: Maybe<Scalars['Int']['output']>;
  ssid?: Maybe<Scalars['String']['output']>;
};

export type ZoomVideoSdkToken = {
  __typename?: 'ZoomVideoSdkToken';
  jwt: Scalars['String']['output'];
  sessionName: Scalars['String']['output'];
  userIdentity: Scalars['String']['output'];
};

export enum supplementCancellationReason {
  DOG_DOES_NOT_LIKE_IT = 'DOG_DOES_NOT_LIKE_IT',
  OTHER = 'OTHER',
  SUBSCRIBED_BY_ACCIDENT = 'SUBSCRIBED_BY_ACCIDENT',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
}

export enum zendeskCancellationReason {
  BATTERY_DRAINS_TOO_QUICKLY = 'BATTERY_DRAINS_TOO_QUICKLY',
  BILLING_ERROR = 'BILLING_ERROR',
  COLLAR_DOES_NOT_WORK_PROPERLY = 'COLLAR_DOES_NOT_WORK_PROPERLY',
  COLLAR_IS_PHYSICALLY_BROKEN = 'COLLAR_IS_PHYSICALLY_BROKEN',
  DOG_DOES_NOT_RUN_AWAY = 'DOG_DOES_NOT_RUN_AWAY',
  DOG_WILL_NOT_WEAR_COLLAR = 'DOG_WILL_NOT_WEAR_COLLAR',
  LOCATION_REPORTS_INCORRECT = 'LOCATION_REPORTS_INCORRECT',
  OTHER = 'OTHER',
  TOOK_TOO_LONG_TO_NOTIFY = 'TOOK_TOO_LONG_TO_NOTIFY',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
}

export type chipDetailsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type chipDetails = {
  __typename?: 'Query';
  chip?:
    | {
        __typename: 'AssignedChip';
        id: string;
        shortId: string;
        serialNumber: string;
        pet?: {
          __typename?: 'StrangerPet';
          id: string;
          name: string;
          gender: Gender;
          species?: Species | null;
          isPurebred: boolean;
          yearOfBirth: number;
          monthOfBirth?: number | null;
          dayOfBirth?: number | null;
          weight: number;
          additionalInfo?: string | null;
          breed?: { __typename?: 'Breed'; id: string; name: string } | null;
          photos: {
            __typename?: 'PhotoFeed';
            first?: {
              __typename?: 'Photo';
              id: string;
              image: { __typename?: 'Image'; fullSize?: string | null };
            } | null;
          };
        } | null;
        latestContactHistoryEntry?: {
          __typename?: 'ChipContactHistoryEntry';
          source: ChipContactHistoryEntrySource;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        } | null;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      }
    | {
        __typename: 'StrangerChip';
        id: string;
        shortId: string;
        serialNumber: string;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      }
    | null;
};

export type strangerPetDetailsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type strangerPetDetails = {
  __typename?: 'Query';
  strangerPet?: {
    __typename?: 'StrangerPet';
    id: string;
    name: string;
    gender: Gender;
    species?: Species | null;
    isPurebred: boolean;
    yearOfBirth: number;
    monthOfBirth?: number | null;
    dayOfBirth?: number | null;
    weight: number;
    additionalInfo?: string | null;
    breed?: { __typename?: 'Breed'; id: string; name: string } | null;
    photos: {
      __typename?: 'PhotoFeed';
      first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
    };
  } | null;
};

export type searchResellerChipsVariables = Exact<{
  input: ChipQueryInput;
}>;

export type searchResellerChips = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: string;
    chipReseller?: {
      __typename?: 'ChipReseller';
      id: string;
      searchChips: {
        __typename?: 'ResellerChipFeed';
        chips: Array<{
          __typename?: 'ResellerChip';
          status: ChipStatusType;
          id: string;
          shortId: string;
          serialNumber: string;
          pet?: { __typename?: 'StrangerPet'; id: string; name: string } | null;
          latestContactHistoryEntry?: {
            __typename?: 'ChipContactHistoryEntry';
            source: ChipContactHistoryEntrySource;
            contactInfo: { __typename?: 'ChipContactInfo'; firstName: string; lastName?: string | null };
          } | null;
        }>;
      };
    } | null;
  };
};

export type viewingChipsVariables = Exact<{
  statusType: ChipStatusType;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type viewingChips = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: string;
    chipReseller?: {
      __typename?: 'ChipReseller';
      id: string;
      chips: {
        __typename?: 'ResellerChipFeed';
        chips: Array<{
          __typename?: 'ResellerChip';
          id: string;
          shortId: string;
          serialNumber: string;
          pet?: { __typename?: 'StrangerPet'; id: string; name: string } | null;
          latestContactHistoryEntry?: {
            __typename?: 'ChipContactHistoryEntry';
            source: ChipContactHistoryEntrySource;
            contactInfo: { __typename?: 'ChipContactInfo'; firstName: string; lastName?: string | null };
          } | null;
        }>;
      };
    } | null;
  };
};

export type manageHomeVariables = Exact<{ [key: string]: never }>;

export type manageHome = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: string;
    userHouseholds: Array<{
      __typename?: 'UserHousehold';
      household: {
        __typename?: 'Household';
        pets: Array<{
          __typename?: 'Pet';
          id: string;
          name: string;
          gender: Gender;
          species?: Species | null;
          isPurebred: boolean;
          yearOfBirth: number;
          monthOfBirth?: number | null;
          dayOfBirth?: number | null;
          weight: number;
          additionalInfo?: string | null;
          chip?: { __typename?: 'AssignedChip'; id: string; shortId: string; serialNumber: string } | null;
          pendingChip?: { __typename?: 'StrangerChip'; id: string; shortId: string } | null;
          breed?: { __typename?: 'Breed'; id: string; name: string } | null;
          photos: {
            __typename?: 'PhotoFeed';
            first?: {
              __typename?: 'Photo';
              id: string;
              image: { __typename?: 'Image'; fullSize?: string | null };
            } | null;
          };
        }>;
      };
    }>;
    chips: Array<
      | {
          __typename: 'AssignedChip';
          id: string;
          shortId: string;
          serialNumber: string;
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          latestContactHistoryEntry?: {
            __typename?: 'ChipContactHistoryEntry';
            source: ChipContactHistoryEntrySource;
            contactInfo: {
              __typename?: 'ChipContactInfo';
              firstName: string;
              lastName?: string | null;
              secondaryFirstName?: string | null;
              secondaryLastName?: string | null;
              primaryPhone?: string | null;
              secondaryPhone?: string | null;
              primaryEmail: string;
              secondaryEmail?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              zipcode?: string | null;
              country: string;
            };
          } | null;
          transfer?: {
            __typename?: 'ChipTransfer';
            id: string;
            origin: ChipTransferOrigin;
            status: ChipTransferStatus;
            canResolve: boolean;
            contactInfo: {
              __typename?: 'ChipContactInfo';
              firstName: string;
              lastName?: string | null;
              secondaryFirstName?: string | null;
              secondaryLastName?: string | null;
              primaryPhone?: string | null;
              secondaryPhone?: string | null;
              primaryEmail: string;
              secondaryEmail?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              zipcode?: string | null;
              country: string;
            };
            pet?: {
              __typename?: 'StrangerPet';
              id: string;
              name: string;
              gender: Gender;
              species?: Species | null;
              isPurebred: boolean;
              yearOfBirth: number;
              monthOfBirth?: number | null;
              dayOfBirth?: number | null;
              weight: number;
              additionalInfo?: string | null;
              breed?: { __typename?: 'Breed'; id: string; name: string } | null;
              photos: {
                __typename?: 'PhotoFeed';
                first?: {
                  __typename?: 'Photo';
                  id: string;
                  image: { __typename?: 'Image'; fullSize?: string | null };
                } | null;
              };
            } | null;
            chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
          } | null;
        }
      | {
          __typename: 'StrangerChip';
          id: string;
          shortId: string;
          serialNumber: string;
          transfer?: {
            __typename?: 'ChipTransfer';
            id: string;
            origin: ChipTransferOrigin;
            status: ChipTransferStatus;
            canResolve: boolean;
            contactInfo: {
              __typename?: 'ChipContactInfo';
              firstName: string;
              lastName?: string | null;
              secondaryFirstName?: string | null;
              secondaryLastName?: string | null;
              primaryPhone?: string | null;
              secondaryPhone?: string | null;
              primaryEmail: string;
              secondaryEmail?: string | null;
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              zipcode?: string | null;
              country: string;
            };
            pet?: {
              __typename?: 'StrangerPet';
              id: string;
              name: string;
              gender: Gender;
              species?: Species | null;
              isPurebred: boolean;
              yearOfBirth: number;
              monthOfBirth?: number | null;
              dayOfBirth?: number | null;
              weight: number;
              additionalInfo?: string | null;
              breed?: { __typename?: 'Breed'; id: string; name: string } | null;
              photos: {
                __typename?: 'PhotoFeed';
                first?: {
                  __typename?: 'Photo';
                  id: string;
                  image: { __typename?: 'Image'; fullSize?: string | null };
                } | null;
              };
            } | null;
            chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
          } | null;
        }
    >;
  };
};

export type validateChipForResellerVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type validateChipForReseller = {
  __typename?: 'Query';
  chip?: { __typename?: 'ResellerChip'; id: string; shortId: string; serialNumber: string } | null;
};

export type validateChipForOwnerVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type validateChipForOwner = {
  __typename?: 'Query';
  chip?:
    | { __typename?: 'AssignedChip'; id: string; shortId: string; serialNumber: string }
    | { __typename?: 'StrangerChip'; id: string; shortId: string; serialNumber: string }
    | null;
};

export type registerThirdPartyChipVariables = Exact<{
  input: ThirdPartyChipInput;
}>;

export type registerThirdPartyChip = {
  __typename?: 'Mutation';
  chip: { __typename?: 'ResellerChip'; id: string; shortId: string; serialNumber: string };
};

export type allBreedsVariables = Exact<{ [key: string]: never }>;

export type allBreeds = { __typename?: 'Query'; allBreeds: Array<{ __typename?: 'Breed'; id: string; name: string }> };

export type breed = { __typename?: 'Breed'; id: string; name: string };

export type NANO_chipRegistrationAddEmailVariables = Exact<{
  input: ChipRegistrationAddEmailInput;
}>;

export type NANO_chipRegistrationAddEmail = {
  __typename?: 'Mutation';
  chipRegistrationAddEmail:
    | {
        __typename: 'AssignedChip';
        id: string;
        shortId: string;
        serialNumber: string;
        pet?: {
          __typename?: 'StrangerPet';
          id: string;
          name: string;
          gender: Gender;
          species?: Species | null;
          isPurebred: boolean;
          yearOfBirth: number;
          monthOfBirth?: number | null;
          dayOfBirth?: number | null;
          weight: number;
          additionalInfo?: string | null;
          breed?: { __typename?: 'Breed'; id: string; name: string } | null;
          photos: {
            __typename?: 'PhotoFeed';
            first?: {
              __typename?: 'Photo';
              id: string;
              image: { __typename?: 'Image'; fullSize?: string | null };
            } | null;
          };
        } | null;
        latestContactHistoryEntry?: {
          __typename?: 'ChipContactHistoryEntry';
          source: ChipContactHistoryEntrySource;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        } | null;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      }
    | {
        __typename: 'StrangerChip';
        id: string;
        shortId: string;
        serialNumber: string;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      };
};

export type NANO_chipRegistrationAddNewPetVariables = Exact<{
  input: ChipRegistrationAddNewPetInput;
}>;

export type NANO_chipRegistrationAddNewPet = {
  __typename?: 'Mutation';
  chipRegistrationAddNewPet:
    | {
        __typename: 'AssignedChip';
        id: string;
        shortId: string;
        serialNumber: string;
        pet?: {
          __typename?: 'StrangerPet';
          id: string;
          name: string;
          gender: Gender;
          species?: Species | null;
          isPurebred: boolean;
          yearOfBirth: number;
          monthOfBirth?: number | null;
          dayOfBirth?: number | null;
          weight: number;
          additionalInfo?: string | null;
          breed?: { __typename?: 'Breed'; id: string; name: string } | null;
          photos: {
            __typename?: 'PhotoFeed';
            first?: {
              __typename?: 'Photo';
              id: string;
              image: { __typename?: 'Image'; fullSize?: string | null };
            } | null;
          };
        } | null;
        latestContactHistoryEntry?: {
          __typename?: 'ChipContactHistoryEntry';
          source: ChipContactHistoryEntrySource;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        } | null;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      }
    | {
        __typename: 'StrangerChip';
        id: string;
        shortId: string;
        serialNumber: string;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      };
};

export type NANO_chipRegistrationAddExistingPetVariables = Exact<{
  input: ChipRegistrationAddExistingPetInput;
}>;

export type NANO_chipRegistrationAddExistingPet = {
  __typename?: 'Mutation';
  chipRegistrationAddExistingPet:
    | {
        __typename: 'AssignedChip';
        id: string;
        shortId: string;
        serialNumber: string;
        pet?: {
          __typename?: 'StrangerPet';
          id: string;
          name: string;
          gender: Gender;
          species?: Species | null;
          isPurebred: boolean;
          yearOfBirth: number;
          monthOfBirth?: number | null;
          dayOfBirth?: number | null;
          weight: number;
          additionalInfo?: string | null;
          breed?: { __typename?: 'Breed'; id: string; name: string } | null;
          photos: {
            __typename?: 'PhotoFeed';
            first?: {
              __typename?: 'Photo';
              id: string;
              image: { __typename?: 'Image'; fullSize?: string | null };
            } | null;
          };
        } | null;
        latestContactHistoryEntry?: {
          __typename?: 'ChipContactHistoryEntry';
          source: ChipContactHistoryEntrySource;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        } | null;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      }
    | {
        __typename: 'StrangerChip';
        id: string;
        shortId: string;
        serialNumber: string;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      };
};

export type NANO_chipRegistrationUpdateContactVariables = Exact<{
  input: ChipRegistrationUpdateContactInput;
}>;

export type NANO_chipRegistrationUpdateContact = {
  __typename?: 'Mutation';
  chipRegistrationUpdateContact:
    | {
        __typename: 'AssignedChip';
        id: string;
        shortId: string;
        serialNumber: string;
        pet?: {
          __typename?: 'StrangerPet';
          id: string;
          name: string;
          gender: Gender;
          species?: Species | null;
          isPurebred: boolean;
          yearOfBirth: number;
          monthOfBirth?: number | null;
          dayOfBirth?: number | null;
          weight: number;
          additionalInfo?: string | null;
          breed?: { __typename?: 'Breed'; id: string; name: string } | null;
          photos: {
            __typename?: 'PhotoFeed';
            first?: {
              __typename?: 'Photo';
              id: string;
              image: { __typename?: 'Image'; fullSize?: string | null };
            } | null;
          };
        } | null;
        latestContactHistoryEntry?: {
          __typename?: 'ChipContactHistoryEntry';
          source: ChipContactHistoryEntrySource;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        } | null;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      }
    | {
        __typename: 'StrangerChip';
        id: string;
        shortId: string;
        serialNumber: string;
        transfer?: {
          __typename?: 'ChipTransfer';
          id: string;
          origin: ChipTransferOrigin;
          status: ChipTransferStatus;
          canResolve: boolean;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
          pet?: {
            __typename?: 'StrangerPet';
            id: string;
            name: string;
            gender: Gender;
            species?: Species | null;
            isPurebred: boolean;
            yearOfBirth: number;
            monthOfBirth?: number | null;
            dayOfBirth?: number | null;
            weight: number;
            additionalInfo?: string | null;
            breed?: { __typename?: 'Breed'; id: string; name: string } | null;
            photos: {
              __typename?: 'PhotoFeed';
              first?: {
                __typename?: 'Photo';
                id: string;
                image: { __typename?: 'Image'; fullSize?: string | null };
              } | null;
            };
          } | null;
          chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
        } | null;
      };
};

export type NANO_assignNewPetToChipVariables = Exact<{
  input: AssignNewPetToChipInput;
}>;

export type NANO_assignNewPetToChip = {
  __typename?: 'Mutation';
  assignNewPetToChip: {
    __typename?: 'ResellerChip';
    id: string;
    shortId: string;
    serialNumber: string;
    cartonId?: string | null;
    status: ChipStatusType;
    pet?: {
      __typename?: 'StrangerPet';
      id: string;
      name: string;
      gender: Gender;
      species?: Species | null;
      isPurebred: boolean;
      yearOfBirth: number;
      monthOfBirth?: number | null;
      dayOfBirth?: number | null;
      weight: number;
      additionalInfo?: string | null;
      breed?: { __typename?: 'Breed'; id: string; name: string } | null;
      photos: {
        __typename?: 'PhotoFeed';
        first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
      };
    } | null;
    transfer?: {
      __typename?: 'ChipTransfer';
      id: string;
      origin: ChipTransferOrigin;
      status: ChipTransferStatus;
      canResolve: boolean;
      pet?: {
        __typename?: 'StrangerPet';
        id: string;
        name: string;
        gender: Gender;
        species?: Species | null;
        isPurebred: boolean;
        yearOfBirth: number;
        monthOfBirth?: number | null;
        dayOfBirth?: number | null;
        weight: number;
        additionalInfo?: string | null;
        breed?: { __typename?: 'Breed'; id: string; name: string } | null;
        photos: {
          __typename?: 'PhotoFeed';
          first?: {
            __typename?: 'Photo';
            id: string;
            image: { __typename?: 'Image'; fullSize?: string | null };
          } | null;
        };
      } | null;
      contactInfo: {
        __typename?: 'ChipContactInfo';
        firstName: string;
        lastName?: string | null;
        secondaryFirstName?: string | null;
        secondaryLastName?: string | null;
        primaryPhone?: string | null;
        secondaryPhone?: string | null;
        primaryEmail: string;
        secondaryEmail?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        country: string;
      };
      chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
    } | null;
    latestContactHistoryEntry?: {
      __typename?: 'ChipContactHistoryEntry';
      source: ChipContactHistoryEntrySource;
      contactInfo: {
        __typename?: 'ChipContactInfo';
        firstName: string;
        lastName?: string | null;
        secondaryFirstName?: string | null;
        secondaryLastName?: string | null;
        primaryPhone?: string | null;
        secondaryPhone?: string | null;
        primaryEmail: string;
        secondaryEmail?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        country: string;
      };
    } | null;
  };
};

export type NANO_assignNewOwnerToChipVariables = Exact<{
  input: AssignNewOwnerToChipInput;
}>;

export type NANO_assignNewOwnerToChip = {
  __typename?: 'Mutation';
  assignNewOwnerToChip: {
    __typename?: 'ResellerChip';
    id: string;
    shortId: string;
    serialNumber: string;
    cartonId?: string | null;
    status: ChipStatusType;
    pet?: {
      __typename?: 'StrangerPet';
      id: string;
      name: string;
      gender: Gender;
      species?: Species | null;
      isPurebred: boolean;
      yearOfBirth: number;
      monthOfBirth?: number | null;
      dayOfBirth?: number | null;
      weight: number;
      additionalInfo?: string | null;
      breed?: { __typename?: 'Breed'; id: string; name: string } | null;
      photos: {
        __typename?: 'PhotoFeed';
        first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
      };
    } | null;
    transfer?: {
      __typename?: 'ChipTransfer';
      id: string;
      origin: ChipTransferOrigin;
      status: ChipTransferStatus;
      canResolve: boolean;
      pet?: {
        __typename?: 'StrangerPet';
        id: string;
        name: string;
        gender: Gender;
        species?: Species | null;
        isPurebred: boolean;
        yearOfBirth: number;
        monthOfBirth?: number | null;
        dayOfBirth?: number | null;
        weight: number;
        additionalInfo?: string | null;
        breed?: { __typename?: 'Breed'; id: string; name: string } | null;
        photos: {
          __typename?: 'PhotoFeed';
          first?: {
            __typename?: 'Photo';
            id: string;
            image: { __typename?: 'Image'; fullSize?: string | null };
          } | null;
        };
      } | null;
      contactInfo: {
        __typename?: 'ChipContactInfo';
        firstName: string;
        lastName?: string | null;
        secondaryFirstName?: string | null;
        secondaryLastName?: string | null;
        primaryPhone?: string | null;
        secondaryPhone?: string | null;
        primaryEmail: string;
        secondaryEmail?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        country: string;
      };
      chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
    } | null;
    latestContactHistoryEntry?: {
      __typename?: 'ChipContactHistoryEntry';
      source: ChipContactHistoryEntrySource;
      contactInfo: {
        __typename?: 'ChipContactInfo';
        firstName: string;
        lastName?: string | null;
        secondaryFirstName?: string | null;
        secondaryLastName?: string | null;
        primaryPhone?: string | null;
        secondaryPhone?: string | null;
        primaryEmail: string;
        secondaryEmail?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        country: string;
      };
    } | null;
  };
};

export type resellerChipDetailsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type resellerChipDetails = {
  __typename?: 'Query';
  resellerChip?: {
    __typename?: 'ResellerChip';
    id: string;
    shortId: string;
    serialNumber: string;
    cartonId?: string | null;
    status: ChipStatusType;
    pet?: {
      __typename?: 'StrangerPet';
      id: string;
      name: string;
      gender: Gender;
      species?: Species | null;
      isPurebred: boolean;
      yearOfBirth: number;
      monthOfBirth?: number | null;
      dayOfBirth?: number | null;
      weight: number;
      additionalInfo?: string | null;
      breed?: { __typename?: 'Breed'; id: string; name: string } | null;
      photos: {
        __typename?: 'PhotoFeed';
        first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
      };
    } | null;
    transfer?: {
      __typename?: 'ChipTransfer';
      id: string;
      origin: ChipTransferOrigin;
      status: ChipTransferStatus;
      canResolve: boolean;
      pet?: {
        __typename?: 'StrangerPet';
        id: string;
        name: string;
        gender: Gender;
        species?: Species | null;
        isPurebred: boolean;
        yearOfBirth: number;
        monthOfBirth?: number | null;
        dayOfBirth?: number | null;
        weight: number;
        additionalInfo?: string | null;
        breed?: { __typename?: 'Breed'; id: string; name: string } | null;
        photos: {
          __typename?: 'PhotoFeed';
          first?: {
            __typename?: 'Photo';
            id: string;
            image: { __typename?: 'Image'; fullSize?: string | null };
          } | null;
        };
      } | null;
      contactInfo: {
        __typename?: 'ChipContactInfo';
        firstName: string;
        lastName?: string | null;
        secondaryFirstName?: string | null;
        secondaryLastName?: string | null;
        primaryPhone?: string | null;
        secondaryPhone?: string | null;
        primaryEmail: string;
        secondaryEmail?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        country: string;
      };
      chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
    } | null;
    latestContactHistoryEntry?: {
      __typename?: 'ChipContactHistoryEntry';
      source: ChipContactHistoryEntrySource;
      contactInfo: {
        __typename?: 'ChipContactInfo';
        firstName: string;
        lastName?: string | null;
        secondaryFirstName?: string | null;
        secondaryLastName?: string | null;
        primaryPhone?: string | null;
        secondaryPhone?: string | null;
        primaryEmail: string;
        secondaryEmail?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        country: string;
      };
    } | null;
  } | null;
  currentUser: {
    __typename?: 'User';
    id: string;
    email: string;
    userHouseholds: Array<{
      __typename?: 'UserHousehold';
      householdRole: UserHouseholdRole;
      household: { __typename?: 'Household'; pets: Array<{ __typename?: 'Pet'; id: string }> };
    }>;
  };
};

export type resellerRegisterThirdPartyChipVariables = Exact<{
  input: ThirdPartyChipInput;
}>;

export type resellerRegisterThirdPartyChip = {
  __typename?: 'Mutation';
  registerThirdPartyChip: { __typename?: 'ResellerChip'; id: string; serialNumber: string };
};

export type updateChipContactInfoVariables = Exact<{
  input: UpdateChipContactInfoInput;
}>;

export type updateChipContactInfo = {
  __typename?: 'Mutation';
  updateChipContactInfo: {
    __typename?: 'UpdateChipContactInfoResult';
    petOwnershipWasTransferred: boolean;
    contactInfoResponse:
      | {
          __typename: 'ChipContactHistoryEntry';
          id: string;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        }
      | {
          __typename: 'ChipTransfer';
          id: string;
          contactInfo: {
            __typename?: 'ChipContactInfo';
            firstName: string;
            lastName?: string | null;
            secondaryFirstName?: string | null;
            secondaryLastName?: string | null;
            primaryPhone?: string | null;
            secondaryPhone?: string | null;
            primaryEmail: string;
            secondaryEmail?: string | null;
            line1?: string | null;
            line2?: string | null;
            city?: string | null;
            state?: string | null;
            zipcode?: string | null;
            country: string;
          };
        };
  };
};

export type createPetVariables = Exact<{
  input: CreatePetInput;
}>;

export type createPet = { __typename?: 'Mutation'; createPet: { __typename?: 'Pet'; id: string } };

export type setPetPrimaryPhotoVariables = Exact<{
  input: SetPetPrimaryPhotoInput;
}>;

export type setPetPrimaryPhoto = {
  __typename?: 'Mutation';
  setPetPrimaryPhoto: {
    __typename?: 'Pet';
    id: string;
    photos: {
      __typename?: 'PhotoFeed';
      first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
    };
  };
};

export type removePhotoVariables = Exact<{
  photoId: Scalars['ID']['input'];
}>;

export type removePhoto = { __typename?: 'Mutation'; removePhoto?: boolean | null };

export type resolveChipTransferVariables = Exact<{
  input: ResolveChipTransferInput;
}>;

export type resolveChipTransfer = {
  __typename?: 'Mutation';
  resolveChipTransfer: { __typename?: 'ChipTransfer'; id: string };
};

export type updatePetVariables = Exact<{
  input: UpdatePetInput;
}>;

export type updatePet = { __typename?: 'Mutation'; updatePet: { __typename?: 'Pet'; id: string } };

export type associateChipWithPetVariables = Exact<{
  input: AssociateChipWithPetInput;
}>;

export type associateChipWithPet = { __typename?: 'Mutation'; associateChipWithPet?: boolean | null };

export type updateChipConditionVariables = Exact<{
  chipId: Scalars['ID']['input'];
  chipCondition: ChipCondition;
}>;

export type updateChipCondition = {
  __typename?: 'Mutation';
  updateChipCondition: { __typename?: 'ResellerChip'; id: string; shortId: string; serialNumber: string };
};

export type miniChip = {
  __typename?: 'ResellerChip';
  id: string;
  shortId: string;
  serialNumber: string;
  pet?: { __typename?: 'StrangerPet'; id: string; name: string } | null;
  latestContactHistoryEntry?: {
    __typename?: 'ChipContactHistoryEntry';
    source: ChipContactHistoryEntrySource;
    contactInfo: { __typename?: 'ChipContactInfo'; firstName: string; lastName?: string | null };
  } | null;
};

export type resellerChip = {
  __typename?: 'ResellerChip';
  id: string;
  shortId: string;
  serialNumber: string;
  cartonId?: string | null;
  status: ChipStatusType;
  pet?: {
    __typename?: 'StrangerPet';
    id: string;
    name: string;
    gender: Gender;
    species?: Species | null;
    isPurebred: boolean;
    yearOfBirth: number;
    monthOfBirth?: number | null;
    dayOfBirth?: number | null;
    weight: number;
    additionalInfo?: string | null;
    breed?: { __typename?: 'Breed'; id: string; name: string } | null;
    photos: {
      __typename?: 'PhotoFeed';
      first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
    };
  } | null;
  transfer?: {
    __typename?: 'ChipTransfer';
    id: string;
    origin: ChipTransferOrigin;
    status: ChipTransferStatus;
    canResolve: boolean;
    pet?: {
      __typename?: 'StrangerPet';
      id: string;
      name: string;
      gender: Gender;
      species?: Species | null;
      isPurebred: boolean;
      yearOfBirth: number;
      monthOfBirth?: number | null;
      dayOfBirth?: number | null;
      weight: number;
      additionalInfo?: string | null;
      breed?: { __typename?: 'Breed'; id: string; name: string } | null;
      photos: {
        __typename?: 'PhotoFeed';
        first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
      };
    } | null;
    contactInfo: {
      __typename?: 'ChipContactInfo';
      firstName: string;
      lastName?: string | null;
      secondaryFirstName?: string | null;
      secondaryLastName?: string | null;
      primaryPhone?: string | null;
      secondaryPhone?: string | null;
      primaryEmail: string;
      secondaryEmail?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country: string;
    };
    chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
  } | null;
  latestContactHistoryEntry?: {
    __typename?: 'ChipContactHistoryEntry';
    source: ChipContactHistoryEntrySource;
    contactInfo: {
      __typename?: 'ChipContactInfo';
      firstName: string;
      lastName?: string | null;
      secondaryFirstName?: string | null;
      secondaryLastName?: string | null;
      primaryPhone?: string | null;
      secondaryPhone?: string | null;
      primaryEmail: string;
      secondaryEmail?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country: string;
    };
  } | null;
};

type baseChip_AssignedChip = {
  __typename: 'AssignedChip';
  id: string;
  shortId: string;
  serialNumber: string;
  pet?: {
    __typename?: 'StrangerPet';
    id: string;
    name: string;
    gender: Gender;
    species?: Species | null;
    isPurebred: boolean;
    yearOfBirth: number;
    monthOfBirth?: number | null;
    dayOfBirth?: number | null;
    weight: number;
    additionalInfo?: string | null;
    breed?: { __typename?: 'Breed'; id: string; name: string } | null;
    photos: {
      __typename?: 'PhotoFeed';
      first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
    };
  } | null;
  latestContactHistoryEntry?: {
    __typename?: 'ChipContactHistoryEntry';
    source: ChipContactHistoryEntrySource;
    contactInfo: {
      __typename?: 'ChipContactInfo';
      firstName: string;
      lastName?: string | null;
      secondaryFirstName?: string | null;
      secondaryLastName?: string | null;
      primaryPhone?: string | null;
      secondaryPhone?: string | null;
      primaryEmail: string;
      secondaryEmail?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country: string;
    };
  } | null;
  transfer?: {
    __typename?: 'ChipTransfer';
    id: string;
    origin: ChipTransferOrigin;
    status: ChipTransferStatus;
    canResolve: boolean;
    contactInfo: {
      __typename?: 'ChipContactInfo';
      firstName: string;
      lastName?: string | null;
      secondaryFirstName?: string | null;
      secondaryLastName?: string | null;
      primaryPhone?: string | null;
      secondaryPhone?: string | null;
      primaryEmail: string;
      secondaryEmail?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country: string;
    };
    pet?: {
      __typename?: 'StrangerPet';
      id: string;
      name: string;
      gender: Gender;
      species?: Species | null;
      isPurebred: boolean;
      yearOfBirth: number;
      monthOfBirth?: number | null;
      dayOfBirth?: number | null;
      weight: number;
      additionalInfo?: string | null;
      breed?: { __typename?: 'Breed'; id: string; name: string } | null;
      photos: {
        __typename?: 'PhotoFeed';
        first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
      };
    } | null;
    chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
  } | null;
};

type baseChip_StrangerChip = {
  __typename: 'StrangerChip';
  id: string;
  shortId: string;
  serialNumber: string;
  transfer?: {
    __typename?: 'ChipTransfer';
    id: string;
    origin: ChipTransferOrigin;
    status: ChipTransferStatus;
    canResolve: boolean;
    contactInfo: {
      __typename?: 'ChipContactInfo';
      firstName: string;
      lastName?: string | null;
      secondaryFirstName?: string | null;
      secondaryLastName?: string | null;
      primaryPhone?: string | null;
      secondaryPhone?: string | null;
      primaryEmail: string;
      secondaryEmail?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country: string;
    };
    pet?: {
      __typename?: 'StrangerPet';
      id: string;
      name: string;
      gender: Gender;
      species?: Species | null;
      isPurebred: boolean;
      yearOfBirth: number;
      monthOfBirth?: number | null;
      dayOfBirth?: number | null;
      weight: number;
      additionalInfo?: string | null;
      breed?: { __typename?: 'Breed'; id: string; name: string } | null;
      photos: {
        __typename?: 'PhotoFeed';
        first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
      };
    } | null;
    chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
  } | null;
};

export type baseChip = baseChip_AssignedChip | baseChip_StrangerChip;

export type transfer = {
  __typename?: 'ChipTransfer';
  id: string;
  origin: ChipTransferOrigin;
  status: ChipTransferStatus;
  canResolve: boolean;
  contactInfo: {
    __typename?: 'ChipContactInfo';
    firstName: string;
    lastName?: string | null;
    secondaryFirstName?: string | null;
    secondaryLastName?: string | null;
    primaryPhone?: string | null;
    secondaryPhone?: string | null;
    primaryEmail: string;
    secondaryEmail?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    zipcode?: string | null;
    country: string;
  };
  pet?: {
    __typename?: 'StrangerPet';
    id: string;
    name: string;
    gender: Gender;
    species?: Species | null;
    isPurebred: boolean;
    yearOfBirth: number;
    monthOfBirth?: number | null;
    dayOfBirth?: number | null;
    weight: number;
    additionalInfo?: string | null;
    breed?: { __typename?: 'Breed'; id: string; name: string } | null;
    photos: {
      __typename?: 'PhotoFeed';
      first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
    };
  } | null;
  chip: { __typename?: 'StrangerChip'; shortId: string; serialNumber: string };
};

export type contactInfo = {
  __typename?: 'ChipContactInfo';
  firstName: string;
  lastName?: string | null;
  secondaryFirstName?: string | null;
  secondaryLastName?: string | null;
  primaryPhone?: string | null;
  secondaryPhone?: string | null;
  primaryEmail: string;
  secondaryEmail?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  country: string;
};

export type photo = { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } };

type basePet_Pet = {
  __typename?: 'Pet';
  id: string;
  name: string;
  gender: Gender;
  species?: Species | null;
  isPurebred: boolean;
  yearOfBirth: number;
  monthOfBirth?: number | null;
  dayOfBirth?: number | null;
  weight: number;
  additionalInfo?: string | null;
  breed?: { __typename?: 'Breed'; id: string; name: string } | null;
  photos: {
    __typename?: 'PhotoFeed';
    first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
  };
};

type basePet_StrangerPet = {
  __typename?: 'StrangerPet';
  id: string;
  name: string;
  gender: Gender;
  species?: Species | null;
  isPurebred: boolean;
  yearOfBirth: number;
  monthOfBirth?: number | null;
  dayOfBirth?: number | null;
  weight: number;
  additionalInfo?: string | null;
  breed?: { __typename?: 'Breed'; id: string; name: string } | null;
  photos: {
    __typename?: 'PhotoFeed';
    first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
  };
};

export type basePet = basePet_Pet | basePet_StrangerPet;

export type userPet = {
  __typename?: 'Pet';
  id: string;
  name: string;
  gender: Gender;
  species?: Species | null;
  isPurebred: boolean;
  yearOfBirth: number;
  monthOfBirth?: number | null;
  dayOfBirth?: number | null;
  weight: number;
  additionalInfo?: string | null;
  chip?: { __typename?: 'AssignedChip'; id: string; shortId: string; serialNumber: string } | null;
  pendingChip?: { __typename?: 'StrangerChip'; id: string; shortId: string } | null;
  breed?: { __typename?: 'Breed'; id: string; name: string } | null;
  photos: {
    __typename?: 'PhotoFeed';
    first?: { __typename?: 'Photo'; id: string; image: { __typename?: 'Image'; fullSize?: string | null } } | null;
  };
};

export type userChipResellerVariables = Exact<{ [key: string]: never }>;

export type userChipReseller = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    chipReseller?: { __typename?: 'ChipReseller'; id: string; status: ResellerStatus } | null;
  };
};

export type registerChipResellerVariables = Exact<{
  input: RegisterChipResellerInput;
}>;

export type registerChipReseller = {
  __typename?: 'Mutation';
  registerChipReseller: { __typename?: 'ChipReseller'; id: string };
};
