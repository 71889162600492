import {
  ChipSendMessagePayload,
  ChipSearchResultResponse,
  ChipRequestInfoUpdatePayload,
} from '@barkinglabs/nano-shared';
import { apiHost } from './constants';
import axios from 'axios';

export async function search(query: string, token: string) {
  const url = `${apiHost}/api/chips/search`;
  const response = await axios.get<ChipSearchResultResponse>(url, {
    params: {
      query,
      token,
    },
  });
  return response.data;
}

export async function sendMessage(payload: ChipSendMessagePayload) {
  const url = `${apiHost}/api/chips/sendmessage`;
  await axios.post(url, payload);
}

export async function requestInfoUpdate(payload: ChipRequestInfoUpdatePayload) {
  const url = `${apiHost}/api/chips/requestinfoupdate`;
  await axios.post(url, payload);
}

interface ChipDetails {
  serialNumber: string;
  shortId: string;
  isAssigned: boolean;
}

export async function getChipDetails(chipId: string): Promise<ChipDetails> {
  const url = `${apiHost}/api/chips/fichipstatus`;
  const response = await axios.get<ChipDetails>(url, { params: { chipId } });
  return response.data;
}
