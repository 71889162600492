import React from 'react';
import Button from '../../../../components/Button/Button';

import styles from './ModalActions.module.scss';

interface ModalAction {
  text: string;
  onClick(): void;
  primary?: boolean;
  disabled?: boolean;
}

export interface ModalActionsProps {
  actions: ModalAction[];
}

export default function ModalActions({ actions }: ModalActionsProps) {
  return (
    <div className={styles.main}>
      {actions.map((action, i) => (
        <Button
          onClick={action.onClick}
          key={i}
          label={action.text}
          primary={action.primary}
          disabled={action.disabled}
        />
      ))}
    </div>
  );
}
