import { useMutation, MutationTuple } from '@apollo/client';
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';

import SelectField from '../../components/form/SelectField';
import { useSelector } from 'react-redux';
import { AppState, store } from '../../lib/store';
import { signup, logout } from '../../lib/authentication';
import { registerChipResellerQuery } from './ApplicationForm.graphql';
import { gqlTypes } from '../../types/gqlTypes';
import { phoneRegex, saneEmail, isOptionalUrl } from '../../lib/utils';
import FormField from '../../components/form/FormField';
import SingleTextFieldFormGroup from '../../components/form/SingleTextFieldFormGroup';
import StateSelectField from '../../components/form/StateSelectField';
import ErrorMessages from '../../components/form/ErrorMessages';

import styles from './ApplicationForm.module.scss';

interface FormValues {
  resellerType: gqlTypes.ResellerType | null;
  petInterest: gqlTypes.ResellerPetInterest | null;
  name: string;
  website: string | undefined;
  estimatedMonthlyChipRegistrations: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  line1: string;
  line2: string | undefined;
  city: string;
  zipcode: string;
  state: string | null;
  password: string | undefined;
}

const initialFormValues: FormValues = {
  resellerType: null,
  petInterest: null,
  name: '',
  website: '',
  estimatedMonthlyChipRegistrations: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  line1: '',
  line2: undefined,
  city: '',
  zipcode: '',
  state: null,
  password: '',
};

// The address entered during the application process is also used as the shipping address for Nano boxes.
// Our 3PL has a max length of 40 characters for address fields.
const ADDRESS_LINE_MAX_LENGTH = 40;

const validationSchema = yup.object({
  resellerType: yup
    .mixed<gqlTypes.ResellerType>()
    .required(`Please select whether you are a vet, rescue, or breeder organization`),
  petInterest: yup
    .mixed<gqlTypes.ResellerPetInterest>()
    .required(`Plese select which pets you are planning to microchip`),
  name: yup.string().required(`Please enter a name`),
  website: yup
    .string()
    .test('is_url', 'Please enter a valid website', isOptionalUrl)
    .required('Please enter a website'),
  estimatedMonthlyChipRegistrations: yup
    .string()
    .test('required', `Please enter an estimated amount`, (x) => x !== '')
    .required('Please enter an estimated amount'),
  firstName: yup.string().required(`Please enter your first name`),
  lastName: yup.string().required(`Please enter your last name`),
  email: yup
    .string()
    .email(`Please enter a valid email address`)
    .test('sane_email', `Please enter a valid email address`, saneEmail)
    .required(`Please enter an email address`),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, `Please enter a valid phone number`)
    .required(`Please enter a phone number`),
  line1: yup.string().required(`Please enter an address`).max(ADDRESS_LINE_MAX_LENGTH),
  line2: yup.string().max(ADDRESS_LINE_MAX_LENGTH),
  city: yup.string().required(`Please enter your city`).max(ADDRESS_LINE_MAX_LENGTH),
  zipcode: yup.string().required(`Please enter your zip code`),
  state: yup
    .string()
    .required(`Please select a state`)
    .nullable()
    .test('required', `Please enter a state`, (x) => x !== null),
  password: yup.string(),
});

async function submitApplication(
  values: FormValues,
  mutation: MutationTuple<gqlTypes.registerChipReseller, gqlTypes.registerChipResellerVariables>[0],
) {
  const loggedIn = !!store.getState().session;
  if (!loggedIn) {
    // Create an account
    const password = values.password;
    if (!password) {
      throw new Error(`Please enter a password.`);
    }
    await signup(values.email, password, values.name, '');
  }

  await mutation({
    variables: {
      input: {
        name: values.name,
        type: values.resellerType!,
        petInterest: values.petInterest!,
        contactInfo: {
          firstName: values.firstName,
          lastName: values.lastName,
          primaryEmail: values.email,
          primaryPhone: values.phoneNumber,
          website: values.website,
          estimatedMonthlyChipRegistrations: values.estimatedMonthlyChipRegistrations,
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          zipcode: values.zipcode,
          state: values.state!,
          country: `US`,
        },
      },
    },
  });
  // TODO: Should we also create a billing account, etc?
}

export interface ApplicationFormProps {
  onSuccess(): void;
}

export default function ApplicationForm({ onSuccess }: ApplicationFormProps) {
  const session = useSelector((state: AppState) => state.session);
  let initialValues = initialFormValues;
  if (session) {
    initialValues = { ...initialValues, email: session.email };
  }
  const isLoggedIn = !!session;

  const doLogout = () => logout();

  const [mutation] = useMutation<gqlTypes.registerChipReseller, gqlTypes.registerChipResellerVariables>(
    registerChipResellerQuery,
  );

  return (
    <div className={styles.main}>
      <h3>Apply for the Fi Nano Program</h3>
      {session && (
        <div className={styles.loginContainer}>
          Logged in as {session.email}.{' '}
          <span className="link" onClick={doLogout}>
            Log out.
          </span>
        </div>
      )}
      <Formik<FormValues>
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            await submitApplication(values, mutation);
            onSuccess();
          } catch (err) {
            setStatus({ errorMessage: (err as any).message });
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, status, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="form" autoComplete="on">
            <div className="form-group">
              <div className={classNames('form-field form-field--labelled', styles.vetRescueField)}>
                <div>
                  <div>Are you a Vet, Rescue, or a Breeder?</div>
                  <SelectField
                    name="resellerType"
                    options={[
                      {
                        label: 'Vet',
                        value: gqlTypes.ResellerType.VET,
                      },
                      {
                        label: 'Rescue',
                        value: gqlTypes.ResellerType.RESCUE,
                      },
                      {
                        label: 'Breeder',
                        value: gqlTypes.ResellerType.BREEDER,
                      },
                    ]}
                  />
                </div>
                <ErrorMessage name="resellerType" component="div" className="form-field__error" />
              </div>
            </div>

            <div className="form-group">
              <div className={classNames('form-field form-field--labelled', styles.petInterestField)}>
                <div>
                  <div>What pets will you microchip with the Fi Nano?</div>
                  <SelectField
                    name="petInterest"
                    options={[
                      {
                        label: 'Dogs only',
                        value: gqlTypes.ResellerPetInterest.DOGS,
                      },
                      {
                        label: 'Dogs & Cats',
                        value: gqlTypes.ResellerPetInterest.DOGS_AND_CATS,
                      },
                      {
                        label: 'Only non dogs & cats',
                        value: gqlTypes.ResellerPetInterest.OTHER,
                      },
                    ]}
                  />
                </div>
                <ErrorMessage name="petInterest" component="div" className="form-field__error" />
              </div>
            </div>

            <SingleTextFieldFormGroup name="name" placeholder="Name of establishment" />

            <SingleTextFieldFormGroup name="website" placeholder="Website or Facebook page of establishment" />

            <SingleTextFieldFormGroup
              name="estimatedMonthlyChipRegistrations"
              placeholder="How many chips do you register per month?"
            />

            <div className="form-group">
              <div className="form-field">
                <Field type="text" name="firstName" placeholder="First name" autoComplete="given-name" />
              </div>
              <div className="form-field">
                <Field type="text" name="lastName" placeholder="Last name" autoComplete="family-name" />
              </div>
            </div>

            <ErrorMessages fields={['firstName', 'lastName']} />

            <div className="form-group">
              <FormField
                disabled={isLoggedIn}
                type="email"
                name="email"
                placeholder={'Email address'}
                autoComplete="email"
              />
            </div>

            {!isLoggedIn && (
              <SingleTextFieldFormGroup
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
              />
            )}

            <SingleTextFieldFormGroup name="phoneNumber" type="tel" placeholder="Phone number" autoComplete="tel" />

            <SingleTextFieldFormGroup name="line1" placeholder="Street address" autoComplete="street-address" />

            <SingleTextFieldFormGroup
              name="line2"
              placeholder="Apt/suite/other (optional)"
              autoComplete="address-line-2"
            />

            <div className="form-group">
              <div className="form-field">
                <Field type="text" name="city" placeholder="City" autoComplete="address-level2" />
              </div>
              <div className="form-field">
                <Field type="text" name="zipcode" placeholder="Zip" autoComplete="postal-code" />
              </div>
              <div className={classNames('form-field', styles.stateField)}>
                <StateSelectField name="state" />
              </div>
            </div>

            <ErrorMessages fields={['city', 'zipcode', 'state']} />

            <div className="form-group form-group--action">
              <div className="form-field">
                <button type="submit" disabled={isSubmitting} className="btn-yellow">
                  Submit
                </button>
                {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
