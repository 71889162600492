import React, { useRef } from 'react';
import Popup from 'reactjs-popup';
import ModalActions from './ModalActions/ModalActions';
import { PopupActions } from 'reactjs-popup/dist/types';

import styles from './ModalConfirm.module.scss';

export interface ModalConfirmProps {
  message: React.ReactNode;
  open: boolean;
  acceptText?: string;
  rejectText?: string;
  onAccept?(): void;
  onReject?(): void;
}

export default function ModalConfirm({
  open,
  message,
  acceptText = 'OK',
  rejectText = 'Cancel',
  onAccept,
  onReject,
}: ModalConfirmProps) {
  const ref = useRef<PopupActions>(null);
  return (
    <Popup
      open={open}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      modal
      contentStyle={{ padding: '0', fontSize: '26px' }}
      ref={ref}
    >
      <div>
        <div className={styles.message}>{message}</div>
        <ModalActions
          actions={[
            {
              text: acceptText,
              primary: true,
              onClick() {
                if (onAccept) {
                  onAccept();
                }
              },
            },
            {
              text: rejectText,
              onClick() {
                ref.current?.close();
                if (onReject) {
                  onReject();
                }
              },
            },
          ]}
        />
      </div>
    </Popup>
  );
}
