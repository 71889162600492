import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import Home from './pages/Home/Home';
import Navbar from './components/pagelayout/Navbar';
import Search from './pages/Search/Search';
import Apply from './pages/Apply/Apply';
import Login from './pages/Login/Login';
import Transfer from './pages/Transfer/Transfer';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { store, AppState } from './lib/store';
import client from './lib/apollo/client';
import VerifySearch from './pages/VerifySearch/VerifySearch';
import AppViews from './appview/routing/AppViews';
import analytics from './lib/analytics';
import { createSessionFromExistingCredentials } from './lib/authentication';
import DesktopVetViews from './appview/routing/DesktopVetViews';
import Impersonate from './pages/Impersonate/Impersonate';
import ImpersonationWarning from './components/utils/ImpersonationWarning/ImpersonationWarning';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import DesktopManageViews from './appview/routing/DesktopManageViews';
import Register from './pages/Register/Register';
import Signup from './pages/Signup/Signup';
import RegisterChipV2 from './appview/RegisterChipV2/RegisterChipV2';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { getFiGrowthBook } from './appview/lib/growthbook';

function useCreateSession() {
  const [initialized, setInitialized] = useState(false);
  const alreadyHasSession = useSelector((state: AppState) => !!state.session);
  const [frozenAlreadyHasSession] = useState(() => alreadyHasSession);
  useEffect(() => {
    async function tryCreateSession() {
      if (!frozenAlreadyHasSession) {
        try {
          await createSessionFromExistingCredentials();
        } catch (err) {
          console.error(err);
        }
      }
      setInitialized(true);
    }
    tryCreateSession();
  }, [frozenAlreadyHasSession]);
  return initialized;
}

function useAnalytics() {
  const location = useLocation();
  useEffect(() => {
    analytics.page();
  }, [location.pathname]);
}

function App() {
  const sessionMaybeCreated = useCreateSession();
  const location = useLocation();

  useAnalytics();

  // Scroll to top on nav
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Switch>
        <Route path="/appview">{sessionMaybeCreated && <AppViews />}</Route>
        <Route path="/">
          <Navbar />
          <ImpersonationWarning />
          <Route exact path="/" component={Home} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/verifysearch" component={VerifySearch} />
          <Route exact path="/apply" component={Apply} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/impersonate" component={Impersonate} />
          <Route exact path="/passwordReset" component={PasswordReset} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/register/*" component={RegisterChipV2} />
          <Route path="/transfer" component={Transfer} />
          <Route path="/vet">
            <DesktopVetViews />
          </Route>
          <Route path="/manage">
            <DesktopManageViews />
          </Route>
        </Route>
      </Switch>
    </div>
  );
}

function AppContainer() {
  useEffect(() => {
    // Set the attributes of the request/user
    getFiGrowthBook().setAttributes();

    // Load features from the GrowthBook API and initialize the SDK
    getFiGrowthBook().loadFeatures();
  }, []);

  return (
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <GrowthBookProvider growthbook={getFiGrowthBook().growthBook}>
          <Router>
            <App />
          </Router>
        </GrowthBookProvider>
      </ApolloProvider>
    </ReduxProvider>
  );
}

export default AppContainer;
