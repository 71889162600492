import React from 'react';
import { ErrorMessage } from 'formik';

export interface ErrorMessagesProps {
  fields: string[];
}

export default function ErrorMessages({ fields }: ErrorMessagesProps) {
  return (
    <>
      {fields.map((field) => (
        <ErrorMessage key={field} name={field} component="div" className="form-field__error" />
      ))}
    </>
  );
}
